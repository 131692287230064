import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import {
  getConversionStatusId,
  getConversionFlag,
} from 'Common/utilities/loanFileHelper';

export function statusListBuilderForUI(status, loanId) {
  return (
    status && {
      ...status,
      BrokerBrandingID: 0,
      CategoryName: status.isApplication ? 'Application' : 'Opportunity',
      PipelineCategoryID: status.PipelineCategoryID,
      PipelineStatus: status.OrgPipelineStatusName || status.StatusName,
      PipelineStatusID: status.PipelineStatusId,
      OrgPipelineStatusID: status.OrgPipelineStatusId,
      IsConversionStatus: getConversionFlag(status),
      ConversionStatusId: getConversionStatusId(status),
      isNotProceeded:
        status.LoanStatusId === LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH,
      loanId,
    }
  );
}
