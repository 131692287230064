import { getLogger } from '@loanmarket/logger-core';
import { EDITOR_OPTIONS } from 'Common/config/wysiwigEditor';
import { COLOR } from 'Common/constants/colors';
import { isValidEmailAddress } from 'Common/utilities/string';
import { GAME_PLAN_SECTIONS } from 'Common/constants/gamePlan';
import { toastError, toastSuccess, toastWarning } from 'Common/utilities/alert';

export default class GamePlanSharedDataService {
  constructor(
    $uibModal,
    configService,
    currentUserService,
    contactModelService,
    gamePlanGeneratorService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.configService = configService;
    this.currentUserService = currentUserService;
    this.contactModelService = contactModelService;
    this.gamePlanGeneratorService = gamePlanGeneratorService;
    this.editableList = {};

    this.logger = getLogger('GamePlanSharedDataService');
    this.setupData();
  }

  setupData() {
    this.gamePlanSections =
      GAME_PLAN_SECTIONS[this.currentUserService.countryCode];
    this.applicants = [];
    this.borrowers = [];
    this.guarantors = [];
    this.activeSection = 'Broker message';
    this.brokerMessage = '';
    this.emailMessage = ``;
    this.emailMessageApplicant = ``;
    this.emailMessageGuarantor = ``;
    this.isApplicantActive = true;
    this.customDefaultMessage = '';
    this.adviserTheme = {
      sidebarBgColor: COLOR.CERULEAN,
    };
    this.isApplicantActive = true;
    this.adviserInfo = {};
    this.editorOptions = EDITOR_OPTIONS;
    this.isValidSendEmail = false;
    this.isLoadingAdviserInfo = false;
    this.isSendingGamePlanOn = false;
    this.isSettingsDefault = false;
    this.isSectionSettingsDefault = false;
    this.setDefaultDataSections();
    this.isOnSaveDefaultEdited = true;
    this.isSavingSideBar = false;
    this.isSavingMainContent = false;
    this.isSettingsOnDefault = false;
    this.isSectionSettingsOnDefault = false;
    this.isSectionOnSaveDefaultEdited = true;
    this.isEditEmailMessage = false;
    this.brokerMessageCount = 0;
    this.brokerMessageCountMax = 1500;
    this.isBrokerMessageCountMax = false;
    this.isEditEmailMessage = false;
    this.saveButtonText = {
      isReset: 'Save as Default',
      isSaved: 'Saved as Default',
    };

    if (!this.configService || !this.configService.feature) {
      return;
    }
    const { generateGamePlan, sendingGamePlan } = this.configService.feature;
    this.isSendingGamePlanOn = generateGamePlan && sendingGamePlan;
    this.messageSalutation = `Hi`;
    this.oldMergedNames = ``;
    this.commonNamePlaceholder = `{First Name}`;
  }

  initialize(familyId) {
    return this.getFamilyAdviserInfo(familyId);
  }

  displayWarningCount() {
    if (this.isBrokerMessageCountMax) {
      toastWarning(
        `You've hit the maximum broker message length. Try making it shorter!`,
        `Oops!`,
      );
    }
  }

  setDefaults(defaultSettings) {
    this.defaults = defaultSettings;
  }

  setDefaultDataSections() {
    this.sectionsDefaults = this.gamePlanSections
      .filter((value) => value.isToggleable)
      .map((data) => {
        return Object.assign({}, { ...data }, { isIncluded: true });
      });

    this.sections = this.gamePlanSections.map((section) => {
      return {
        ...section,
        isActive: false,
        isIncluded: true,
      };
    });
  }

  onSendEsign({ props }) {
    return this.$uibModal.open({
      template: `<send-game-plan-to-esign
                modal-instance="vm.modalInstance"
                is-send-Cqp="vm.props.isSendCqp"
                is-send-Cpa="vm.props.isSendCpa"
                loan-id="vm.props.loanId"
                >
              </send-game-plan-to-esign>`,
      size: 'md',
      keyboard: false,
      backdrop: 'static',
      windowClass: 'send-gameplan-to-esign',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props: () => props,
      },
    });
  }

  getFamilyAdviserInfo(familyId) {
    this.isLoadingAdviserInfo = true;
    return this.contactModelService
      .getFamilyAdviserInfo(familyId)
      .then((response) => {
        this.adviserInfo = response;
        this.isLoadingAdviserInfo = false;
        this.setDefaultMessage();
        return this.getFamilyAdviserTheme(familyId);
      })
      .catch(() => {
        this.isLoadingAdviserInfo = false;
      });
  }

  getFamilyAdviserTheme(familyId) {
    return this.contactModelService
      .getFamilyAdviserTheme(familyId)
      .then((response) => {
        this.adviserTheme = response;
      });
  }

  setSelectedApplicants(applicants, isApplicant) {
    this.applicants = applicants;
    if (isApplicant) {
      this.borrowers = applicants;
    }
    if (!isApplicant) {
      this.guarantors = applicants;
    }
    this.setEmailValidation();

    if (isApplicant) {
      this.emailMessage = this.emailMessageApplicant;
    }
    if (!isApplicant) {
      this.emailMessage = this.emailMessageGuarantor;
    }
    this.updateEmailName();
  }

  setActiveSection(section) {
    this.activeSection = section;
  }

  setSections(sections) {
    this.sections = sections;
  }

  setEmailValidation() {
    const validEmails =
      this.applicants.length &&
      this.applicants.filter(
        (applicant) =>
          applicant.isSelected && isValidEmailAddress(applicant.emailAddress),
      );
    this.isValidSendEmail = validEmails.length === this.applicants.length;
  }

  getDisplayTitle() {
    const { marketingDisplayTitle, profileDisplayTitle } =
      this.adviserInfo || {};
    const defaultDisplayTitle = this.currentUserService.isAU
      ? 'Mortgage Broker'
      : 'Mortgage Adviser';
    return (
      (this.configService.feature.usersProfileBio
        ? profileDisplayTitle
        : marketingDisplayTitle) || defaultDisplayTitle
    );
  }

  replaceCurrentNames({
    currentName,
    mergedName,
    isEditEmailMessage,
    theMessage,
  }) {
    const newMessage = theMessage.replace(currentName, ` ${mergedName},`);
    if (isEditEmailMessage) {
      this.emailMessage = newMessage;
    } else {
      this.brokerMessage = newMessage;
    }
    return newMessage;
  }

  populateGreeting({ mergedName, theMessage, isEditEmailMessage }) {
    const isSalutationPresent = theMessage.indexOf(this.messageSalutation);
    const CHARCOUNT = 2;

    // eslint-disable-next-line unicorn/prefer-string-slice
    const currentName = theMessage.substring(
      isSalutationPresent + CHARCOUNT,
      theMessage.indexOf(`</p>`),
    );

    if (isSalutationPresent > -1) {
      if (!currentName) {
        return this.replaceFirstParagraphName({
          theMessage,
          mergedName,
          isEditEmailMessage,
        });
      }
      return this.replaceCurrentNames({
        currentName,
        mergedName,
        isEditEmailMessage,
        theMessage,
      });
    } else {
      return this.addFirstParagraphNameGreeting({
        theMessage,
        mergedName,
        isEditEmailMessage,
      });
    }
  }

  updateNameInEmailMessage({ mergedName, isEditEmailMessage }) {
    let theMessage = isEditEmailMessage
      ? this.emailMessage
      : this.brokerMessage;

    theMessage = this.oldMergedNames
      ? theMessage.replace(this.oldMergedNames, mergedName)
      : theMessage;
    theMessage = theMessage.replace(this.commonNamePlaceholder, mergedName);

    if (isEditEmailMessage) {
      this.emailMessage = theMessage;
    } else {
      this.brokerMessage = theMessage;
    }

    const isNameFound = theMessage.includes(mergedName);
    if (!isNameFound) {
      theMessage = this.populateGreeting({
        mergedName,
        theMessage,
        isEditEmailMessage,
      });
    }

    return theMessage;
  }

  addFirstParagraphNameGreeting({
    theMessage,
    mergedName,
    isEditEmailMessage,
  }) {
    const newMessage = `<p>Hi ${mergedName},</p>${theMessage}`;
    if (isEditEmailMessage) {
      this.emailMessage = newMessage;
    } else {
      this.brokerMessage = newMessage;
    }
    return newMessage;
  }

  replaceFirstParagraphName({ theMessage, mergedName, isEditEmailMessage }) {
    const CHARCOUNT = 3;
    const firstParagraph = theMessage.slice(
      theMessage.indexOf(`<p>`) + CHARCOUNT,
      theMessage.indexOf(`</p>`),
    );

    const newMessage = theMessage.replace(firstParagraph, `Hi ${mergedName},`);
    if (isEditEmailMessage) {
      this.emailMessage = newMessage;
    } else {
      this.brokerMessage = newMessage;
    }
    return newMessage;
  }

  updateEmailNameParam({ recipients = [], isEditEmailMessage }) {
    const mergedName = `${this.getApplicantNames(recipients)}`;
    return this.updateNameInEmailMessage({ mergedName, isEditEmailMessage });
  }

  updateEmailName() {
    const mergedName = `${this.getApplicantNames()}`;
    this.updateNameInEmailMessage({
      mergedName,
      isEditEmailMessage: this.isEditEmailMessage,
    });
  }

  defaultEmailMessage() {
    return `
    <p>Hi ${this.commonNamePlaceholder},</p>
    <p>It was great to chat and learn about what you're looking to do.</p>`;
  }

  defaultMessage() {
    const lenderSentence = this.currentUserService.isNZ ? 'over' : 'at least';
    return `
    <p>Hi ${this.commonNamePlaceholder},</p>

    <p> Thank you for this opportunity to help you achieve your goals - 
      I'm excited to go on this journey with you! </p>

    <p> <strong>What you can expect from me</strong>. I'm your personal negotiator with ${lenderSentence} 20 lenders.
      My focus is on finding and presenting you with options that meet your current needs,
      keeping the process simple, and importantly, helping you reach your financial goals. </p>

    <p> <strong>Your personalised Game Plan</strong> features a range of competitive options for your new loan
      (based on your current financial situation). It outlines:
    </p>

    <ul>
      <li>what we have discussed; the information provided by you on your requirements, objectives and goals</li>
      <li>what I recommend; and why I believe these recommendations are in your best interests</li>
      <li>what it will cost you; we are transparent so any fees, commissions, interest or charges relating to the products and services are laid out for you to see</li>
    </ul>

    <p> <strong>What's next? </strong>
      Take some time to review these options and understand what it means to proceed.
      I'm here to answer all your questions. Once you're ready to proceed,
      I'll prepare your loan application ready for your review, approval,
      and signature. I'll then submit your application and let you know the moment it's approved by the lender. </p>

    <p> I'm just a phone call or email away for any questions you may have. </p>

    <p>${this.adviserInfo.preferredFullName}</p>
    <p>${this.adviserInfo.mobile}</p>
    `;
  }

  setDefaultMessage() {
    this.brokerMessage = this.defaultMessage();
    this.emailMessage = this.defaultEmailMessage();
    this.emailMessageApplicant = this.defaultEmailMessage();
    this.emailMessageGuarantor = this.defaultEmailMessage();
    this.updateEmailName();
  }

  setBrokerMessage(message) {
    if (!message) {
      this.setDefaultMessage();
      return;
    }
    this.brokerMessage = message;
  }

  formatMergedNames({ applicant, index, accum }) {
    const preferredName =
      applicant.PreferredName || applicant.FirstName || applicant.name;
    let name;
    if (index === this.applicants.length - 1) {
      name = `${accum} and ${preferredName}`;
    } else if (index === this.applicants.length - 2) {
      name = `${accum}${preferredName}`;
    } else {
      name = `${accum}${preferredName}, `;
    }
    return name;
  }

  getApplicantNames(recipients = []) {
    const userArray = recipients.length ? recipients : this.applicants;
    if (!userArray.length) {
      return this.commonNamePlaceholder;
    }

    if (userArray.length === 1) {
      const { PreferredName, FirstName, name } = userArray[0];
      return PreferredName || FirstName || name;
    }

    return userArray.reduce(
      (accum, applicant, index) =>
        this.formatMergedNames({
          applicant,
          index,
          accum,
        }),
      '',
    );
  }

  getSectionsPayload(sections) {
    const sectionList = sections || this.sections;
    if (!sectionList) {
      return {};
    }

    return sectionList.reduce((accum, section) => {
      accum[section.apiKey] = section.isIncluded;
      return accum;
    }, {});
  }

  getPayload({
    isApplicant,
    sections,
    emailRecipients = null,
    emailMessage = null,
    brokerMessage = null,
  }) {
    return {
      Emails:
        emailRecipients ||
        this.applicants.map((applicant) => applicant.emailAddress),
      EmailMessage: emailMessage || this.emailMessage,
      BrokerMessage: brokerMessage || this.brokerMessage,
      IsApplicant: isApplicant,
      Sections: this.getSectionsPayload(sections),
    };
  }

  checkMessageIfDefault(currentSettings) {
    const defaultMessage = this.defaultMessage().replace(/\s/g, '');
    const defaultSectionSetting = true;
    const { message, sections } = currentSettings;

    this.isSettingsDefault = false;
    this.isSectionSettingsDefault = false;

    if (message && defaultMessage !== message.replace(/\s/g, '')) {
      this.isSettingsDefault = true;
    }

    if (!sections || !sections.length) {
      return;
    }

    sections.map((value) => {
      if (value.isIncluded !== defaultSectionSetting) {
        this.isSectionSettingsDefault = true;
      }
      return this.isSectionSettingsDefault;
    });
  }

  getSectionValue(apiKey, sectionList) {
    const sections = sectionList || this.sections;
    if (!sections) {
      return;
    }

    return sections.find((value) => value.apiKey === apiKey);
  }

  setLoaders(saveResponse, isSidebar) {
    if (isSidebar) {
      this.isSectionSettingsOnDefault = saveResponse.isReset;
      this.isSavingSideBar = false;
      this.isSectionOnSaveDefaultEdited = true;
    } else {
      this.isSettingsOnDefault = saveResponse.isReset;
      this.isSavingMainContent = false;
      this.isOnSaveDefaultEdited = true;
    }
  }

  setNewDefaults(sectionList, isSidebar) {
    return sectionList.map((data) => {
      const isEditable = isSidebar ? data.isToggleable : true;
      if (isEditable) {
        return Object.assign(
          {},
          { ...this.newDefaults },
          this.getSectionValue(data.apiKey, sectionList),
        );
      }

      return data;
    });
  }

  initializeNewDefaults(sectionList, message) {
    const newDefaults = {
      sections: this.setNewDefaults(sectionList),
      message,
    };

    this.setDefaults(newDefaults);
  }

  setNamePlaceholderToMessage(isSidebar) {
    const theMessage = isSidebar ? this.defaults.message : this.brokerMessage;
    const theClientName = this.getApplicantNames();
    const isNameFound = theMessage.includes(theClientName);
    const replaceMessage = theMessage.replace(
      this.messageSalutation,
      `${this.messageSalutation} ${this.commonNamePlaceholder}`,
    );
    return isNameFound
      ? theMessage.replace(theClientName, this.commonNamePlaceholder)
      : replaceMessage;
  }

  setSettingContent(isSidebar, isSection, isReset) {
    if (isSection) {
      return isSidebar ? this.sections : this.defaults.sections;
    }

    const message = this.setNamePlaceholderToMessage(isSidebar);
    return isReset && !isSection ? '' : message;
  }

  setEditableObject(data, sectionList) {
    return Object.assign(
      {},
      { ...this.editableList },
      {
        [data.apiKey]: this.getSectionValue(data.apiKey, sectionList)
          .isIncluded,
      },
    );
  }

  setPayLoad(isSidebar, isReset) {
    const sectionList = this.setSettingContent(isSidebar, true, isReset);
    const message = this.setSettingContent(isSidebar, false, isReset);

    sectionList.map((data) => {
      const isToggable = isSidebar ? data.isToggleable : true;
      if (isToggable) {
        this.editableList = this.setEditableObject(data, sectionList);
        return this.editableList;
      }
      return this.editableList;
    });

    this.initializeNewDefaults(sectionList, message);

    return {
      ...this.editableList,
      message,
    };
  }

  setSavedCurrentSettings(isSidebar, isReset) {
    const sectionList = this.setSettingContent(isSidebar, true, isReset);

    return {
      message: this.setSettingContent(isSidebar, false, isReset),
      sections: sectionList,
    };
  }

  saveDefaults(loanAdviserId, isSidebar, isReset) {
    if (!this.brokerMessage && !this.sections) {
      return;
    }

    const currentSettings = this.setSavedCurrentSettings(isSidebar, !isReset);

    const payload = this.setPayLoad(isSidebar, !isReset);
    this.logger.info(
      `Updating adviser Game Plan settings, LoanAdviserId: ${loanAdviserId}, MessageCharacterCount: ${payload?.message?.length}, IsSidebar: ${isSidebar}`,
    );

    return this.gamePlanGeneratorService
      .saveDefault({
        loanAdviserId,
        payload,
      })
      .then((response) => {
        if (response || response.succeeded) {
          this.checkMessageIfDefault(currentSettings);
          const saveTypeMessage = !isReset ? 'reverted' : 'saved';
          toastSuccess(`Defaults ${saveTypeMessage} successfully!`);
          return;
        }
        toastError('Something went wrong!');
      });
  }

  setSaveButtonTextOnReset(response) {
    if (response.isReset) {
      return this.saveButtonText.isSaved;
    }
    return this.saveButtonText.isReset;
  }
}
