import template from './productFinderLoanFacility.html';
import controller from './productFinderLoanFacilityCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    feesList: '<',
    isPersonalLoan: '<',
    loanTermList: '<',
    loanDetailsSet: '<',
    loanAppSharedData: '<',
    loanAppFormWarning: '<',
    loanFacilityHasProducts: '<',
    productDiscountRate: '<',
    onOpenAddLoanStructureModal: '&',
    onDeleteFacility: '&',
    onChooseProductsModal: '&',
    onSetTBA: '&',
    onDeleteFavoriteProduct: '&',
    onSetFacilityFavoriteProduct: '&',
    onChooseProductButtonText: '&',
    onFeeChanges: '&',
    onToggleFee: '&',
  },
};
