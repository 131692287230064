import angular from 'angular';
import phoneNumber from './phoneNumber';
import layout from './layout';
import mailTemplates from './mailTemplates';
import matchCommissionModal from './matchCommissionModal';
import applicationWorkbench from './workbench';
import sendEmail from './sendEmail';
import sendMail from './sendMail';
import smsTemplates from './smsTemplates';
import customerCare from './customerCare';
import customerCareLockedView from './customerCareLockedView';
import usersSubscription from './usersSubscription';
import usersProfile from './usersProfile';
import fileUpload from './fileUpload';
import saveProfilerToContact from './saveProfilerToContact';
import imagePreviewThumb from './imagePreviewThumb';
import preloaderDot from './preloaderDot';
import checkBoxFilter from './checkboxFilter';
import selectGdriveFolderModal from './selectGdriveFolders';
import assetFinanceVehicleModal from './assetFinanceVehicleModal';
import usersReferral from './usersReferral';
import lmiLenderMortgage from './lmiLenderMortgage';
import lmiPredominant from './lmiPredominant';
import lmiTable from './lmiTable';
import lmiGoalType from './lmiGoalType';
import betaTag from './betaTag';
import newLoanApplicationModal from './createNewLoanApplicationModal';
import currencyConvertion from './currencyConvertion';
import currencyInputDropdown from './currencyInputDropdown';
import lenderLogo from './lenderLogo';
import lenderSelection from './lenderSelection';
import imgFlag from './imgFlag';
import usersTemplates from './usersTemplates';
import workbenchRequirementTemplatesModal from './workbenchRequirementTemplatesModal';
import smsTemplatesSharingPrivacy from './smsTemplatesSharingPrivacy';
import smsTemplatesManagementModal from './smsTemplatesManagementModal';
import emailTemplates from './emailTemplates';
import emailTemplatesSharingPrivacy from './emailTemplatesSharingPrivacy';
import emailManagementModal from './emailManagementModal';
import eSignUploadSignatureModal from './eSignUploadSignatureModal';
import lmiAirtableProposedLending from './lmiAirtableProposedLending';
import productAndLenderRationale from './productAndLenderRationale';
import sendReportsToEsign from './sendReportsToEsign';
import sendGamePlanToEsign from './sendGamePlanToEsign';
import markCompletedPreApprovalModal from './markCompletedPreApprovalModal';
import interceptBankConnect from './interceptBankConnect';
import contactSplitModal from './contactSplitModal';
import eSignSupportingDocuments from './eSignSupportingDocuments';
import taxInvoiceModal from './taxInvoiceModal';
import esignDownloadEvidenceSummary from './esignDownloadEvidenceSummary';
import workbenchRequirementsForm from './workbenchRequirementsForm';
import loansSummary from './loansSummary';
import selectContactType from './selectContactType';
import toggleNotes from './toggleNotes';
import connectIncomeEmployment from './connectIncomeEmployment';
import ratingStar from './ratingStar';
import livingExpense from './livingExpense';
import complianceReviewRating from './complianceReviewRating';
import loanSubmissionEmailContent from './loanSubmissionEmailContent';
import editLoanSubmissionEmailModal from './editLoanSubmissionEmailModal';
import insurancesSaveToContact from './insurancesSaveToContact';
import documentTypeIcon from './documentTypeIcon';
import complianceReviewRatingModal from './complianceReviewRatingModal';
import brokerAllocationTool from './brokerAllocationTool';
import breadCrumb from './breadCrumb';
import pipelineLabelColorPicker from './pipelineLabelColorPicker';
import announcementModal from './announcementModal';
import onboardingTracker from './onboardingTracker';
import onboardingNavigation from './onboardingNavigation';
import onboardingLinkNavigation from './onboardingLinkNavigation';
import onboardingContent from './onboardingContent';
import onboardingUsersQuestionniare from './onboardingUsersQuestionniare';
import onboardingCompletePreview from './onboardingCompletePreview';
import existingAddressInput from './existingAddressInput';
import employmentModal from './employmentModal';
import linearThumb from './linearThumb';
import dob from './dobDayMonthYear';
import officeAddress from './officeAddress';
import customCrmMultiSelect from './customCrmMultiSelect';
import customInputPercentage from './customInputPercentage';
import selectRadio from './selectRadio';
import creditChecksList from './creditChecksList';
import crmCalendar from './crmCalendar';
import hemCalculator from './hemCalculator';
import searcheableContactSelector from './searcheableContactSelector';
import addressSelector from './addressSelector';
import setCreditCheckModal from './setCreditCheckModal';
import printSummaryModal from './printSummaryModal';
import subscriptionAutoMarketingModal from './subscriptionAutoMarketingModal';
import subscriptionModal from './subscriptionModal';
import expenseGridModal from './expenseGridModal';
import subscriptionCancelNowModal from './subscriptionCancelNowModal';
import subscriptionCancelModal from './subscriptionCancelModal';
import subscriptionPreloader from './subscriptionPreloader';
import subscriptionSuccess from './subscriptionSuccess';
import loanAmountForAliModal from './loanAmountForAliModal';
import generateCqpOptions from './generateCqpOptions';
import taskFormModal from './taskFormModal';
import taskTemplated from './taskTemplated';
import assetFinanceProductFinder from './assetFinanceProductFinder';
import assetFinanceModal from './assetFinanceModal';
import showDocumentListOnHover from './showDocumentListOnHover';
import addTrust from './addTrust';
import addressModal from './addressModal';
import addressModalField from './addressModalField';
import addressField from './addressField';
import lendingOpportunity from './lendingOpportunity';
import lmiSecurities from './lmiSecurities';
import isFeelingStuck from './isFeelingStuck';
import creditCardsIcon from './creditCardsIcon';
import usersEmailSignature from './usersEmailSignature';
import searchInputField from './searchInputField';
import readonlyField from './readonlyField';
import gradientBanner from './gradientBanner';
import benefitTypeDescriptionModal from './benefitTypeDescriptionModal';
import cannyEnhancementsNz from './cannyEnhancementsNz';
import opportunity from './opportunity';
import allocatedAdviserTooltip from './allocatedAdviserTooltip';
import adviserSelect from './adviserSelect';
import opportunityModal from './opportunityModal';
import pipeline from './pipeline';
import nameWithIcon from './nameWithIcon';
import crmTableView from './crmTableView';
import bouncingTooltip from './bouncingTooltip';
import commissionEnquiry from './commissionEnquiry';
import datePicker from './datePicker';
import textAreaHeightAuto from './textAreaHeightAuto';
import currencyFormatting from './currencyFormatting';
import clientPreliminaryAssesment from './clientPreliminaryAssesment';
import commissionSummaryWidget from './commissionSummaryWidget';
import numberSpinner from './numberSpinner';
import quantityDisplay from './quantityDisplay';
import indonesianActionsPanel from './indonesianActionsPanel';
import crmConfirmation from './crmConfirmation';
import crmNotify from './crmNotify';
import insurerComparisonModal from './insurerComparisonModal';
import insurerPremiumComparison from './insurerPremiumComparison';
import insurerComparisonGraph from './insurerComparisonGraph';
import insuranceQuoteModsWidget from './insuranceQuoteModsWidget';
import insurerBrochures from './insurerBrochures';
import insuranceNotesTemplateSelector from './insuranceNotesTemplateSelector';
import insuranceNotesTemplates from './insuranceNotesTemplates';
import insuranceNoteTemplatesModal from './insuranceNoteTemplatesModal';
import emailRecipientsField from './emailRecipientsField';
import emailCcField from './emailCcField';
import emailSubjectField from './emailSubjectField';
import surveyModal from './surveyModal';
import emailBodyField from './emailBodyField';
import emailAttachmentsField from './emailAttachmentsField';
import emailEditor from './emailEditor';
import insuranceSendReportForm from './insuranceSendReportForm';
import insuranceProfilerSendReportModal from './insuranceProfilerSendReportModal';
import calendarRangePicker from './calendarRangePicker';
import referralAlert from './referralAlert';
import assistantAdviserSelect from './assistantAdviserSelect';
import loanAppDiscountsRate from './loanAppDiscountsRate';
import loanDependant from './loanDependant';
import discountRateInput from './discountRateInput';
import noLenderImage from './noLenderImage';
import imageCircle from './imageCircle';
import labelToggler from './labelToggler';
import profileImages from './profileImages';
import quickProductFinderDetailModal from './quickProductFinderDetailModal';
import featureManagement from './featureManagement';
import prettyImg from './prettyImg';
import corporateTeam from './corporateTeam';
import corporateTeamModal from './corporateTeamModal';
import insuranceBenefitWidget from './insuranceBenefitWidget';
import downloadHtmlToImgMenu from './downloadHtmlToImgMenu';
import referrerComparison from './referrerComparison';
import referrerConnect from './referrerConnect';
import moneySelectField from './moneySelectField';
import pipelineLabels from './pipelineLabels';
import inputPercentage from './inputPercentage';
import customDropdown from './customDropdown';
import insuranceApplicationsPipeline from './insuranceApplicationsPipeline';
import viewComfortDropdown from './viewComfortDropdown';
import viewModeToggle from './viewModeToggle';
import pipelineStats from './pipelineStats';
import pipelineItemLabels from './pipelineItemLabels';
import pipelineLabelForm from './pipelineLabelForm';
import pipelineLabelSelector from './pipelineLabelSelector';
import categoryFilters from './categoryFilters';
import providerInformationList from './providerInformationList';
import providerInformationsContainers from './providerInformationsContainers';
import providerInfo from './providerInfo';
import providerInfoToolsDownloads from './providerInfoToolsDownloads';
import contactInfoLink from './contactInfoLink';
import providerQuickForms from './providerQuickForms';
import contactPoints from './contactPoints';
import providerKeyContacts from './providerDetailsKeyContacts';
import keyContactForm from './providerDetailsKeyContacts/addKeyContactForm';
import providerUploadDocuments from './providerUploadDocuments';
import newLoanStructure from './newLoanStructure';
import linkedPercentageField from './linkedPercentageField';
import assetFinanceLoanFacility from './assetFinanceLoanFacility';
import releaseNotes from './releaseNotes';
import monthDayIcon from './monthDayIcon';
import mergeFieldOption from './mergeFieldOption';
import documentSharing from './documentSharing';
import sendTaxInvoiceModal from './sendTaxInvoiceModal';
import objectivesQuestion from './objectivesQuestion';
import activityIcon from './activityIcon';
import lmPdfHeader from './lmPdfHeader';
import multiLoanCpaModal from './multiLoanCpaModal';
import multiLoanCqpModal from './multiLoanCqpModal';
import ovalTag from './ovalTag';
import relatedPartySupplier from './relatedPartySupplier';
import addAssetSupplierModal from './addAssetSupplierModal';
import assetSupplierFields from './assetSupplierFields';
import assetInsuranceDetails from './assetInsuranceDetails';
import noFinancials from './noFinancials';
import timeline from './timeline';
import applicantAddRelatedParty from './applicantAddRelatedParty';
import identificationModal from './identificationModal';
import calendarMini from './calendarMini';
import onlineFactFindModal from './onlineFactFindModal';
import usersLeadManagement from './usersLeadManagement';
import googleMapShapes from './googleMapShapes';
import mycrmSlider from './mycrmSlider';
import leadAllocationCount from './leadAllocationCount';
import assetFinanceQuickProductFinder from './assetFinanceQuickProductFinder';
import insuranceCreateApplication from './insuranceCreateApplicationsPipeline';
import communicationBox from './communicationBox';
import smsEditor from './smsEditor';
import workbenchLogs from './workbenchLogs';
import workbenchTask from './workbenchTask';
import addressHistoryModal from './addressHistoryModal';
import contactAddresses from './contactAddresses';
import assetFinanceDetails from './assetFinanceDetails';
import companyOwners from './companyOwners';
import jasperReport from './jasperReport';
import totalLendingRequired from './totalLendingRequired';
import factFindSidebar from './factFindSidebar';
import insuranceAllocatedAdviser from './insuranceAllocatedAdviser';
import achievementField from './achievementField';
import noteModal from './noteModal';
import readMore from './readMore';
import clientEmailMobileInput from './clientEmailMobileInput';
import onboardingRelatedDocument from './onboardingRelatedDocument';
import emailIconField from './emailIconField';
import phoneIconField from './phoneIconField';
import mcButton from './mcButton';
import cessationModal from './cessationModal';
import lmiCalculator from './lmiCalculator';
import loanInformationImportantDates from './loanInformationImportantDates';
import importantDatesPopover from './importantDatesPopover';
import exitCompliance from './exitCompliance';
import infoConfirmBanner from './infoConfirmBanner';
import onboardingAdditionalParties from './onboardingAdditionalParties';
import onboardingNewParty from './onboardingNewParty';
import onboardingAddedParty from './onboardingAddedParty';
import videoModalForYoutube from './videoModalForYoutube';
import creditCheckHistory from './creditCheckHistory';
import onboardingDetails from './onboardingDetails';
import relatedEntities from './relatedEntities';
import soleTraderInfo from './soleTraderInfo';
import onboardingAdditionalStaff from './onboardingAdditionalStaff';
import onboardingAsic from './onboardingAsic';
import onboardingEntityDetails from './onboardingEntityDetails';
import oneLineText from './oneLineText';
import loanAppLendingFee from './loanAppLendingFee';
import onboardingIndividualDetails from './onboardingIndividualDetails';
import onboardingBranding from './onboardingBranding';
import colorSelector from './colorSelector';
import onboardingQuestionnaire from './onboardingQuestionnaire';
import changeColor from './changeColor';
import cqpCpaSidebar from './cqpCpaSidebar';
import loanStatusSelector from './loanStatusSelector';
import postSettlementAddressModal from './postSettlementAddressModal';
import eSignDocument from './eSignDocument';
import propertyTypeSelection from './propertyTypeSelection';
import existingAssetList from './existingAssetList';
import nzbnPartyTypeFields from './nzbnPartyTypeFields';
import onboardingAuthorityAndConsent from './onboardingAuthorityAndConsent';
import leadsToApplicationModal from './leadsToApplicationModal';
import liabilityDates from './liabilityDates';
import npsSurvey from './npsSurvey';
import futureGoals from './futureGoals';
import mycrmAllocation from './mycrmAllocation';
import assistantSelect from './assistantSelect';
import submitEnhancements from './submitEnhancements';
import sendingStyledContainer from './sendingStyledContainer';
import navBreadCrumb from './navBreadCrumb';
import mycrmForms from './mycrmForms';
import mycrmPopover from './mycrmPopover';
import inputCurrency from './inputCurrency';
import addNoteLauncher from './addNoteLauncher';
import frame from './frame';
import renderDynamicLink from './renderDynamicLink';
import referralKiwiSelect from './referralKiwiSelect';
import emailLenderCheck from './emailLenderCheck';
import generalMobileSearch from './generalMobileSearch';
import usersMyLeadGenerator from './usersMyLeadGenerator';
import countrySelectorInput from './countrySelectorInput';
import referralKiwiSelectError from './referralKiwiSelectError';
import goalSetterSidebar from './goalSetterSidebar';
import sendSms from './sendSms';
import threeDotMenu from './threeDotMenu';
import mycrmHome from './mycrmHome';
import noteContent from './noteContent';
import contactDetailsPreview from './contactDetailsPreview';
import tableList from './tableList';
import floatingHelp from './floatingHelp';
import mycrmInput from './mycrmInput';
import tasksList from './tasksList';
import taskContent from './taskContent';
import editableText from './editableText';
import dobInputInlineSave from './dobInputInlineSave';
import fullWidthDashedButton from './fullWidthDashedButton';
import mycrmDropdown from './mycrmDropdown';
import mycrmConfirmation from './mycrmConfirmation';
import clientSearch from './clientSearch';
import referralExistingClientSearch from './referralExistingClientSearch';
import mycrmTable from './mycrmTable';
import badge from './badge';
import skeleton from './skeleton';
import mycrmGender from './mycrmGender';
import handoverPackSidebar from './handoverPackSidebar';

export default angular.module('app.components', [
  layout,
  mailTemplates,
  matchCommissionModal,
  applicationWorkbench,
  sendEmail,
  sendMail,
  smsTemplates,
  customerCare,
  customerCareLockedView,
  usersSubscription,
  usersProfile,
  fileUpload,
  saveProfilerToContact,
  imagePreviewThumb,
  preloaderDot,
  checkBoxFilter,
  selectGdriveFolderModal,
  assetFinanceVehicleModal,
  usersReferral,
  lmiLenderMortgage,
  lmiPredominant,
  lmiTable,
  lmiGoalType,
  betaTag,
  currencyConvertion,
  currencyInputDropdown,
  lenderLogo,
  lenderSelection,
  imgFlag,
  newLoanApplicationModal,
  usersTemplates,
  workbenchRequirementTemplatesModal,
  smsTemplatesSharingPrivacy,
  smsTemplatesManagementModal,
  emailTemplates,
  emailTemplatesSharingPrivacy,
  emailManagementModal,
  eSignUploadSignatureModal,
  productAndLenderRationale,
  lmiAirtableProposedLending,
  sendReportsToEsign,
  sendGamePlanToEsign,
  markCompletedPreApprovalModal,
  interceptBankConnect,
  contactSplitModal,
  eSignSupportingDocuments,
  taxInvoiceModal,
  esignDownloadEvidenceSummary,
  workbenchRequirementsForm,
  loansSummary,
  selectContactType,
  toggleNotes,
  connectIncomeEmployment,
  ratingStar,
  livingExpense,
  complianceReviewRating,
  loanSubmissionEmailContent,
  editLoanSubmissionEmailModal,
  insurancesSaveToContact,
  documentTypeIcon,
  complianceReviewRatingModal,
  brokerAllocationTool,
  breadCrumb,
  pipelineLabelColorPicker,
  announcementModal,
  onboardingTracker,
  onboardingNavigation,
  onboardingLinkNavigation,
  onboardingContent,
  onboardingUsersQuestionniare,
  onboardingCompletePreview,
  existingAddressInput,
  phoneNumber,
  employmentModal,
  linearThumb,
  dob,
  officeAddress,
  customCrmMultiSelect,
  customInputPercentage,
  selectRadio,
  creditChecksList,
  crmCalendar,
  hemCalculator,
  searcheableContactSelector,
  addressSelector,
  setCreditCheckModal,
  printSummaryModal,
  subscriptionModal,
  expenseGridModal,
  subscriptionAutoMarketingModal,
  subscriptionCancelNowModal,
  subscriptionCancelModal,
  subscriptionPreloader,
  subscriptionSuccess,
  loanAmountForAliModal,
  generateCqpOptions,
  taskFormModal,
  taskTemplated,
  assetFinanceProductFinder,
  assetFinanceModal,
  showDocumentListOnHover,
  addTrust,
  addressModal,
  addressModalField,
  addressField,
  lendingOpportunity,
  lmiSecurities,
  isFeelingStuck,
  creditCardsIcon,
  usersEmailSignature,
  searchInputField,
  readonlyField,
  gradientBanner,
  benefitTypeDescriptionModal,
  cannyEnhancementsNz,
  opportunity,
  allocatedAdviserTooltip,
  adviserSelect,
  opportunityModal,
  pipeline,
  nameWithIcon,
  crmTableView,
  bouncingTooltip,
  commissionEnquiry,
  datePicker,
  textAreaHeightAuto,
  currencyFormatting,
  clientPreliminaryAssesment,
  commissionSummaryWidget,
  numberSpinner,
  quantityDisplay,
  indonesianActionsPanel,
  crmConfirmation,
  crmNotify,
  insurerComparisonModal,
  insurerPremiumComparison,
  insurerComparisonGraph,
  insuranceQuoteModsWidget,
  insurerBrochures,
  insuranceNotesTemplateSelector,
  insuranceNotesTemplates,
  insuranceNoteTemplatesModal,
  emailRecipientsField,
  emailCcField,
  emailSubjectField,
  emailBodyField,
  surveyModal,
  emailAttachmentsField,
  emailEditor,
  insuranceSendReportForm,
  insuranceProfilerSendReportModal,
  calendarRangePicker,
  referralAlert,
  assistantAdviserSelect,
  loanAppDiscountsRate,
  loanDependant,
  discountRateInput,
  noLenderImage,
  imageCircle,
  labelToggler,
  profileImages,
  quickProductFinderDetailModal,
  featureManagement,
  prettyImg,
  corporateTeam,
  corporateTeamModal,
  insuranceBenefitWidget,
  downloadHtmlToImgMenu,
  referrerComparison,
  referrerConnect,
  moneySelectField,
  pipelineLabels,
  inputPercentage,
  customDropdown,
  insuranceApplicationsPipeline,
  viewComfortDropdown,
  viewModeToggle,
  pipelineStats,
  pipelineItemLabels,
  pipelineLabelForm,
  pipelineLabelSelector,
  categoryFilters,
  providerInformationList,
  providerInformationsContainers,
  providerInfo,
  providerInfoToolsDownloads,
  contactInfoLink,
  providerQuickForms,
  contactPoints,
  providerKeyContacts,
  keyContactForm,
  providerUploadDocuments,
  newLoanStructure,
  linkedPercentageField,
  assetFinanceLoanFacility,
  releaseNotes,
  monthDayIcon,
  mergeFieldOption,
  documentSharing,
  sendTaxInvoiceModal,
  objectivesQuestion,
  activityIcon,
  lmPdfHeader,
  multiLoanCpaModal,
  multiLoanCqpModal,
  ovalTag,
  relatedPartySupplier,
  addAssetSupplierModal,
  assetSupplierFields,
  assetInsuranceDetails,
  noFinancials,
  timeline,
  applicantAddRelatedParty,
  identificationModal,
  calendarMini,
  onlineFactFindModal,
  usersLeadManagement,
  googleMapShapes,
  mycrmSlider,
  leadAllocationCount,
  assetFinanceQuickProductFinder,
  insuranceCreateApplication,
  communicationBox,
  smsEditor,
  workbenchLogs,
  workbenchTask,
  addressHistoryModal,
  contactAddresses,
  assetFinanceDetails,
  companyOwners,
  jasperReport,
  totalLendingRequired,
  factFindSidebar,
  insuranceAllocatedAdviser,
  achievementField,
  noteModal,
  readMore,
  clientEmailMobileInput,
  onboardingRelatedDocument,
  emailIconField,
  phoneIconField,
  mcButton,
  cessationModal,
  lmiCalculator,
  loanInformationImportantDates,
  importantDatesPopover,
  exitCompliance,
  infoConfirmBanner,
  onboardingAdditionalParties,
  onboardingNewParty,
  onboardingAddedParty,
  videoModalForYoutube,
  creditCheckHistory,
  onboardingDetails,
  relatedEntities,
  soleTraderInfo,
  onboardingAdditionalStaff,
  onboardingAsic,
  onboardingEntityDetails,
  oneLineText,
  loanAppLendingFee,
  onboardingIndividualDetails,
  onboardingBranding,
  colorSelector,
  onboardingQuestionnaire,
  changeColor,
  cqpCpaSidebar,
  loanStatusSelector,
  postSettlementAddressModal,
  eSignDocument,
  propertyTypeSelection,
  existingAssetList,
  nzbnPartyTypeFields,
  onboardingAuthorityAndConsent,
  liabilityDates,
  leadsToApplicationModal,
  npsSurvey,
  futureGoals,
  mycrmAllocation,
  assistantSelect,
  submitEnhancements,
  sendingStyledContainer,
  navBreadCrumb,
  mycrmForms,
  mycrmPopover,
  inputCurrency,
  addNoteLauncher,
  frame,
  renderDynamicLink,
  referralKiwiSelect,
  emailLenderCheck,
  generalMobileSearch,
  usersMyLeadGenerator,
  countrySelectorInput,
  referralKiwiSelectError,
  goalSetterSidebar,
  sendSms,
  threeDotMenu,
  mycrmHome,
  noteContent,
  contactDetailsPreview,
  tableList,
  floatingHelp,
  mycrmInput,
  tasksList,
  taskContent,
  editableText,
  dobInputInlineSave,
  fullWidthDashedButton,
  mycrmDropdown,
  mycrmConfirmation,
  clientSearch,
  referralExistingClientSearch,
  mycrmTable,
  badge,
  skeleton,
  mycrmGender,
  handoverPackSidebar,
]).name;
