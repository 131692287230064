import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import { DEFAULT_NOTES_SIZE } from 'Common/constants/pageTable';
import {
  DUE_DATE_TYPES,
  MAX_TABLE_ROWS,
  TASKS_COLUMNS,
} from 'Common/constants/tasks';
import {
  D_MMM_YYYY_FORMAT,
  D_MMM_YY_HH_MM_FORMAT,
} from 'Common/constants/dateConfigs';
import { CLIENT_DEFAULT_TAB } from 'Common/constants/contactTab';
import { formatDate, formatUTCDate } from 'Common/utilities/date';
import {
  getTaskStatusChip,
  getTaskDataForGTM,
  getDateFilterTimestamp,
  getLenderLogoHtml,
} from 'Common/utilities/tasks';
import {
  toastSaveDelete,
  toastError,
  showActionConfirmation,
} from 'Common/utilities/alert';
import { triggerGA } from 'Common/utilities/googleAnalytics';

export default class TasksTableDisplayCtrl {
  constructor(
    $state,
    tasksService,
    contactsNotesService,
    dealViewTaskService,
    currentUserService,
    taskDetailService,
  ) {
    'ngInject';

    this.$state = $state;
    this.tasksService = tasksService;
    this.contactsNotesService = contactsNotesService;
    this.dealViewTaskService = dealViewTaskService;
    this.currentUserService = currentUserService;
    this.taskDetailService = taskDetailService;

    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.task = {
      sortColumn: 'DueDate',
      sortOrder: 'asc',
      isLoading: true,
      currentPage: 1,
      itemsPerPage: DEFAULT_NOTES_SIZE,
      isTableLoading: false,
    };
    this.initialDataLoaded = false;
    this.taskFilters = {};
    this.MAX_TABLE_ROWS = MAX_TABLE_ROWS;
    this.tableColumns = this.onlyShowColumns;
    this.createdDateFormat = this.isDealTask
      ? D_MMM_YY_HH_MM_FORMAT
      : D_MMM_YYYY_FORMAT;

    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
  }

  getTaskListParams() {
    const assignedToFilter = get(this.taskFilters, 'assignedTo', []);
    const adviserFilter = get(this.taskFilters, 'adviser', []);
    const {
      taskStatus,
      createdBy,
      lenderId,
      clientFamilyId,
    } = this.taskFilters;
    const dueDate = getDateFilterTimestamp(this.taskFilters.dueDate);
    const createdDate = getDateFilterTimestamp(this.taskFilters.createdDate);
    const status = taskStatus.join();

    return {
      pageNumber: this.task.currentPage,
      pageSize: this.task.itemsPerPage,
      sortColumn: this.task.sortColumn,
      sortType: this.task.sortOrder.toUpperCase(),
      familyId: this.familyId ?? clientFamilyId,
      dateFilter:
        status === DUE_DATE_TYPES.COMPLETED ? DUE_DATE_TYPES.ALL : status,
      assignedAdviser: assignedToFilter.join(),
      clientAdviserID: this.isDealTask
        ? this.currentUserService.familyId
        : adviserFilter.join(),
      createdBy,
      taskSearch: this.searchString,
      isShowCompleted: status === DUE_DATE_TYPES.COMPLETED,
      dueDateFrom: dueDate.start,
      dueDateTo: dueDate.end,
      createdDateFrom: createdDate.start,
      createdDateTo: createdDate.end,
      loanId: this.loanId,
      lenderId,
    };
  }

  getInitialDetails() {
    this.contactsNotesService.getDisplayCount().then(({ data }) => {
      this.task.itemsPerPage = data || DEFAULT_NOTES_SIZE;

      this.getTasksList(true);
      this.initialDataLoaded = true;
    });
  }

  getTaskLinks(task) {
    return {
      [TASKS_COLUMNS.CLIENT_NAME.value]: this.isDealTask
        ? null
        : {
            toState: 'breadcrumbSimple',
            familyId: task.FamilyID,
            isEntity: !task.IsClient,
            tab: CLIENT_DEFAULT_TAB,
          },
      [TASKS_COLUMNS.DEAL_NAME.value]: {
        toState: 'opportunityCard',
        familyId: task.FamilyID,
        loanId: task.LoanId,
        loanScenarioId: task.LoanScenarioId,
      },
    };
  }

  getTasksList(pushToDataLayer) {
    const getParams = this.getTaskListParams();
    this.task.isTableLoading = true;
    this.task.isLoading = !this.tasksList?.length;

    return this.dealViewTaskService
      .getTasksList(getParams)
      .then((response) => {
        const tasks = get(response, 'data.TaskList', []);
        this.tasksList = tasks.map((task) => ({
          ...task,
          checked: task.IsCompleted,
          Status: getTaskStatusChip(task),
          DueDate: formatDate(D_MMM_YYYY_FORMAT)(task.ActivityDate),
          AssignedTo: task.AdviserName,
          ClientName: task.IsClient ? task.ClientName : task.ClientFirstName,
          Contact: task.ClientContact,
          CreatedBy: task.CreatedByUserName,
          CreatedDate: formatUTCDate(this.createdDateFormat)(task.CreatedDate),
          Lender: getLenderLogoHtml({
            countryCode: this.currentUserService.countryCode,
            lenderId: task.LenderId,
            lenderName: task.LenderName,
          }),
          class: task.IsOverDue ? 'overdue-task' : '',
          links: this.getTaskLinks(task),
        }));
        this.totalRecords = get(response, 'data.TotalRecords');

        this.pushDataToGtm(pushToDataLayer, getParams);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        this.task.isLoading = false;
        this.task.isTableLoading = false;
      });
  }

  pushDataToGtm(pushToDataLayer, getParams) {
    if (pushToDataLayer) {
      const data = getTaskDataForGTM({
        getParams,
        user: this.currentUserService,
        event: this.isDealTask ? 'get_deal_tasks' : 'get_tasks',
      });
      triggerGA(data);
    }
  }

  clearTasksListForLoading() {
    this.tasksList = this.tasksList?.length ? [{}] : [];
  }

  onTaskFilterChange({ data }) {
    this.clearTasksListForLoading();
    this.taskFilters = data;
    this.task.sortColumn = data?.sortColumn;
    this.task.sortOrder = data?.sortOrder;

    if (this.initialDataLoaded) {
      this.getTasksList(true);
    } else {
      this.getInitialDetails();
    }
  }

  onTableColumnsChange({ data }) {
    if (this.onlyShowColumns) {
      return;
    }

    const taskCol = { ...TASKS_COLUMNS.TASK, checked: true, isSortable: true };
    const checkedItems = data.filter((item) => item.checked);
    this.tableColumns = [taskCol, ...checkedItems];
  }

  onListSearch({ data }) {
    this.searchString = data;
    this.getTasksList(true);
  }

  onPageChange({ pageNumber, pageSize }) {
    this.clearTasksListForLoading();
    this.task.currentPage = pageNumber;
    this.task.itemsPerPage = pageSize;
    this.getTasksList(true);
  }

  onCompleteTask(item) {
    this.tasksService
      .taskSingleCompletion(item.ActivityID, item.FamilyID, item.checked)
      .then(() => {
        this.getTasksList();
      });
  }

  onSortTask({ sortColumn, sortOrder }) {
    this.clearTasksListForLoading();
    this.task.sortColumn = sortColumn;
    this.task.sortOrder = sortOrder;
    this.taskDetailService.handler.saveSortData({ sortColumn, sortOrder });
    this.getTasksList(true);
  }

  onSelectTask(item, column = {}) {
    if (!item) {
      return;
    }

    if (!item.links[column.value] || this.isDealTask) {
      return this.openTaskModal(item.ActivityID, true);
    }
  }

  onDeleteTask(item) {
    showActionConfirmation(
      'Are you sure?',
      'This record will be removed from your task list',
      () => {
        this.tasksService
          .taskDelete(item.FamilyID, item.ActivityID)
          .then(({ data }) => {
            if (data) {
              toastSaveDelete();
              this.getTasksList();
            } else {
              toastError();
            }
          }, toastError);
      },
      'Yes, remove it!',
    );
  }

  openTaskModal(taskId = 0, hasFilter = false, familyId = 0) {
    this.onOpenTaskModal({ data: { taskId, hasFilter, familyId } });
  }

  openTemplatedTaskModal(taskId = 0) {
    this.onOpenTemplatedTaskModal({ data: { taskId } });
  }

  processParentNotification(eventHandler) {
    if (eventHandler === 'cancelFamilyFilter') {
      this.familyId = 0;
      this.getTasksList();
    } else if (eventHandler === 'reloadTasksList') {
      this.getTasksList();
    }
  }
}
