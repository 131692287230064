export default class ExistingInsuranceCtrl {
  constructor(helloPackService, insuranceFactFindService, uiService) {
    'ngInject';

    this.uiService = uiService;
    this.helloPackService = helloPackService;
    this.insuranceFactFindService = insuranceFactFindService;
  }

  $onInit() {
    this.getIOFFLinks();
  }

  onInvite() {
    const props = {
      familyId: this.familyId,
      onlyFactFind: true,
      inviteInsuranceOnlineFactFind: true,
    };
    this.helloPackService.launchHelloPackModal(props).result.then(() => {
      this.getIOFFLinks();
    });
  }

  getIOFFLinks() {
    this.insuranceFactFindService.getIOFFLinks(this.familyId).then((data) => {
      this.links = data.filter((item) => item.urlLink);
    });
  }
}
