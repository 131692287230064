import dotProp from 'dot-prop-immutable';
import { KEY_CODE } from 'Common/constants/keyCode';
import {
  opportunityConvert,
  getNotProceededReasonList,
} from 'Common/utilities/opportunityConversion';
import { flatMap, uniqBy } from 'lodash';
import {
  toastSaveSuccess,
  toastSuccess,
  toastError,
} from 'Common/utilities/alert';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { getAssignedAdviserSubscription as utilGetAssignedAdviserSubscription } from 'Common/utilities/eSignSubscription';
import { parseToInt10 } from 'Common/utilities/parse';
import toastr from 'toastr';
import { moveLoanOpportunityItem } from 'Common/utilities/kanbanHelper';
import { isAlwaysConversionStatus } from 'Common/utilities/loanFileHelper';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import {
  CONVERSION_PIPELINE_STATUS,
  PIPELINE_STATUS,
} from 'Common/constants/pipelineStatus';
import { leadsToApplicationConvert } from 'Common/utilities/applicationConversion';
import { PIPELINE_TYPES_STR } from 'Common/constants/pipelineType';
import { setupTabProperties } from './util/opportunityCtrl';
import {
  APPLICATION_LOADING_TIME,
  DISPLAY_FACT_FIND,
  GAME_PLAN_GENERATE,
} from 'Components/opportunity/util/constants';

export const mergeLeadStatusMapping = (statusId, isFeatureOn = false) => {
  if (!isFeatureOn) {
    return statusId;
  }
  switch (statusId) {
    case PIPELINE_STATUS.CONTACT_MADE:
    case PIPELINE_STATUS.NEW_OPPORTUNITY:
      return PIPELINE_STATUS.APPOINTMENT;
    case PIPELINE_STATUS.REQUIREMENTS:
      return PIPELINE_STATUS.RESEARCHING;
    default:
      return statusId;
  }
};

const FACT_FIND = 'fact-find';

class OpportunityCtrl {
  constructor(
    $rootScope,
    outstandingRequirementService,
    opportunityLoanAppService,
    loanOpportunityService,
    loanFileStatusService,
    loanScenarioService,
    loanAppSharedData,
    currentUserService,
    pipelineSharedData,
    modalRenderService,
    clientInfoService,
    pipelineService,
    contactService,
    configService,
    $stateParams,
    eSignService,
    stateService,
    uiService,
    $uibModal,
    $window,
    toaster,
    $state,
    leadOpportunitiesService,
    loanScenarioModelService,
    opportunityNewModalService,
    $q,
    $scope,
    dashboardService,
    $timeout,
    helloPackService,
    taskDetailService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.outstandingRequirementService = outstandingRequirementService;
    this.opportunityLoanAppService = opportunityLoanAppService;
    this.loanOpportunityService = loanOpportunityService;
    this.loanFileStatusService = loanFileStatusService;
    this.currentUserService = currentUserService;
    this.loanScenarioService = loanScenarioService;
    this.loanAppSharedData = loanAppSharedData;
    this.pipelineSharedData = pipelineSharedData;
    this.modalRenderService = modalRenderService;
    this.clientInfoService = clientInfoService;
    this.pipelineService = pipelineService;
    this.contactService = contactService;
    this.configService = configService;
    this.$stateParams = $stateParams;
    this.eSignService = eSignService;
    this.stateService = stateService;
    this.isUseUibDropdown = true;
    this.uiService = uiService;
    this.$uibModal = $uibModal;
    this.$window = $window;
    this.toaster = toaster;
    this.$state = $state;
    this.leadOpportunitiesService = leadOpportunitiesService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.opportunityNewModalService = opportunityNewModalService;
    this.$q = $q;
    this.$scope = $scope;
    this.dashboardService = dashboardService;
    this.$timeout = $timeout;
    this.helloPackService = helloPackService;
    this.taskDetailService = taskDetailService;

    this.childComponentNotificationRegistration = this.childComponentNotificationRegistration.bind(
      this,
    );
  }

  $onInit() {
    this.checkSourceOpener();
    this.viewOfAFileTurnedOn = this.uiService.viewOfAfileTurnedOn;
    this.newPipeline = this.uiService.newPipeline;

    this.setTitle();
    this.opportunityStatusObj = {};
    this.familyId =
      parseToInt10(this.$state.params.sourceFamilyId) ||
      parseToInt10(this.$state.params.familyId) ||
      null;
    this.loanId = this.$stateParams.loanId || 0;
    this.opportunityId = this.$stateParams.opportunityId || 0;
    this.isBusiness = this.$stateParams.isBusiness || false;
    this.isTitleSavingState = false;
    this.isEditTitle = false;
    this.isShowOpportunityFactFind =
      this.configService.feature &&
      this.configService.feature.opportunityFactFind;
    this.loanAppEmailTemplateNZ =
      this.configService.feature &&
      this.configService.feature.loanAppEmailTemplateNZ;
    this.gamePlanGenerateEventTimeout = null;

    const { activeTab } = this.$stateParams;
    this.tabs = [
      'summary',
      'calculations',
      'scenarios',
      'communications',
      'notes',
      'activity',
      'documents',
      FACT_FIND,
    ];
    this.activeTab = this.tabs.indexOf(activeTab) || 0;

    if (!this.opportunityId || !this.loanId) {
      this.redirectFile();
      return;
    }

    setupTabProperties({
      vm: this,
    });

    this.processStatuses();

    this.outstandingRequirementService.get(this.loanId).then((data) => {
      this.requirementsList = data;
    });

    getNotProceededReasonList({
      isMergeLeadAndOpportunities: this.pipelineSharedData
        .isMergeLeadAndOpportunities,
      loanOpportunityService: this.loanOpportunityService,
    }).then((res) => {
      if (!res.data) {
        return;
      }
      const { data } = res;
      this.reasonList = data;
    });

    this.getDetails();
    this.getScenarioDetails();
    /* LoanAppOnOpportunities */
    const reloadDetails = this.getDetails.bind(this);
    this.opportunityLoanAppService.setCallback(
      'refreshOpportunityDetails',
      reloadDetails,
    );

    this.childComponentEventHandler = {
      mainEventHandler: null,
    };

    if (this.loanId) {
      this.dashboardService.storeRecentlyViewedLoan(this.opportunityId);
    }
  }

  $onDestroy() {
    if (this.gamePlanGenerateEventTimeout) {
      this.$timeout.cancel(this.gamePlanGenerateEventTimeout);
    }
  }

  triggerGamePlanGeneration() {
    const isApplicationAnActiveTab = this.$state.params.activeTab === FACT_FIND;
    if (!isApplicationAnActiveTab) {
      this.$scope.$broadcast(DISPLAY_FACT_FIND);
      this.gamePlanGenerateEventTimeout = this.$timeout(
        () => this.$scope.$broadcast(GAME_PLAN_GENERATE),
        APPLICATION_LOADING_TIME,
      );
    } else {
      this.$scope.$broadcast(GAME_PLAN_GENERATE);
    }
  }

  redirectFile() {
    this.$state.go('app.dashboard', {}, { location: 'replace' });
  }

  checkRoleTypes() {
    const { showLendingTools } = this.uiService;
    if (!showLendingTools) {
      this.statusList = this.statusList.filter(
        (status) => status.PipelineStatusID !== PIPELINE_STATUS.APPLICATION,
      );
    }
  }

  processStatuses() {
    this.getStatuses().then(() => {
      this.loanOpportunityService
        .getLoanOpportunityGeneral(this.opportunityId)
        .then((res) => {
          if (!res) {
            this.redirectFile();
            return;
          }
          this.opportunityGeneral = res;
          this.opportunityStatusObj = this.getOpportunityStatusObj(res);
          this.getAssignedAdviserSubscription(
            this.opportunityGeneral.adviserId,
          );
          if (this.viewOfAFileTurnedOn) {
            this.opportunityGeneral.pipelineStatusIdBak = this.opportunityStatusObj.PipelineStatusId;
          }
          this.checkRoleTypes();
        });
    });
  }

  getStatuses() {
    return this.loanFileStatusService
      .getLoanFileStatus(this.loanId)
      .then((statusList) => {
        this.statusList = statusList;
      });
  }

  getOpportunityStatusObj(res) {
    if (!this.statusList) {
      return {};
    }

    if (this.newPipeline && res.orgPipelineStatusId) {
      const currentStatus = this.statusList.find(
        (status) => status.OrgPipelineStatusID === res.orgPipelineStatusId,
      );
      if (currentStatus) {
        return currentStatus;
      }
    }

    const isFeatureOn = this.pipelineSharedData.isMergeLeadAndOpportunities;
    if (this.viewOfAFileTurnedOn) {
      const currentStatus = this.statusList.find(
        (status) => status.LoanStatusId === res.loanStatusId,
      );
      if (currentStatus) {
        return currentStatus;
      }
    }
    return this.statusList.find(
      (status) =>
        status.PipelineStatusID ===
        mergeLeadStatusMapping(res.pipelineStatusId, isFeatureOn),
    );
  }

  setTitle() {
    this.opportunityTitle = this.leadOpportunitiesService.getLabel(false);
  }

  childComponentNotificationRegistration(handler, handlerType) {
    this.childComponentEventHandler[handlerType] = handler;
  }

  refreshDocuments() {
    this.childComponentEventHandler.mainEventHandler &&
      this.childComponentEventHandler.mainEventHandler('refreshDocuments');
  }

  getAssignedAdviserSubscription(adviserId) {
    if (!adviserId) {
      return;
    }

    const { LoanApplicationDetails } = this.loanAppSharedData;

    this.loanAppSharedData.LoanApplicationDetails = {
      ...LoanApplicationDetails,
      AdviserId: adviserId,
      AdviserFamilyId: adviserId,
      CountryID: this.currentUserService.countryId,
    };

    utilGetAssignedAdviserSubscription(
      this.contactService,
      this.loanAppSharedData,
      this.configService,
      this.eSignService,
    )(adviserId);
  }

  checkDealWithClientApplicant(involvedPartyPerson = []) {
    const isValidInvolvedParties = !!involvedPartyPerson.find(
      (person) => person.IsApplicant || person.IsGuarantor,
    );
    this.helloPackService.handler.setDealHelloPackDisabled(
      !isValidInvolvedParties,
    );
  }

  getDetails() {
    this.taskDetailService.handler.reloadClients();
    this.loanOpportunityService
      .getLoanOpportunityDetails(this.opportunityId, true)
      // eslint-disable-next-line sonarjs/cognitive-complexity
      .then((res) => {
        const { data } = res;
        if (!res || !data) {
          return;
        }

        const opportunityDetails = data;

        const { Summary } = opportunityDetails;
        if (!Summary) {
          return;
        }
        const { LendingCategoryId } = Summary;
        this.isNotAssetFinance =
          LendingCategoryId !== LENDING_CATEGORY.ASSET_FINANCE;

        const { PrimaryPurpose } = Summary;
        if (!PrimaryPurpose) {
          const QUESTIONDATA = {
            PURPOSE: { QUESTIONID: 5, QUESTIONSECTIONID: 0 },
          };
          const DISPLAYDATA = { MULTIDROPDOWN: 6 };

          Summary.PrimaryPurpose = {
            QuestionSectionId: QUESTIONDATA.PURPOSE.QUESTIONSECTIONID,
            QuestionId: QUESTIONDATA.PURPOSE.QUESTIONID,
            DisplayOptionId: DISPLAYDATA.MULTIDROPDOWN,
            answerInt: [],
          };
        }

        const {
          ReferralCategoryId,
          ReferralItemId,
          ReferrerId,
          ReferrerOrgId,
          ReferrerName,
          ReferrerOrgName,
          ReferrerAgreementTypeId,
        } = Summary;
        Summary.EnquirySource = {
          ReferralCategoryId,
          ReferralItemId,
          ReferrerId,
          ReferrerOrgId,
          ReferrerName,
          ReferrerOrgName,
          ReferrerAgreementTypeId,
        };

        delete Summary.ReferralCategoryId;
        delete Summary.ReferralItemId;
        delete Summary.ReferrerId;
        delete Summary.ReferrerOrgId;

        const { InvolvedParties } = opportunityDetails;
        this.applicants = { ...InvolvedParties };
        InvolvedParties.InvolvedPartyPerson = flatMap(
          InvolvedParties.InvolvedPartyPerson,
        );
        InvolvedParties.PersonBorrowers = InvolvedParties.InvolvedPartyPerson.filter(
          (person) => !person.IsGuarantor,
        );
        InvolvedParties.RelatedParty = InvolvedParties.InvolvedPartyPerson.filter(
          (person) => person.IsGuarantor,
        );

        this.checkDealWithClientApplicant(InvolvedParties.InvolvedPartyPerson);
        if (this.familyId) {
          const sourceFamilyNames = [];
          const {
            InvolvedPartyPerson,
            InvolvedPartyEntity,
            PersonBorrowers,
            RelatedParty,
          } = InvolvedParties;

          const involvedPartyList = {
            InvolvedPartyEntity,
            PersonBorrowers,
            RelatedParty,
          };

          Object.keys(involvedPartyList).forEach((key) => {
            involvedPartyList[key] &&
              involvedPartyList[key].forEach((party) => {
                if (Number(party.FamilyId) === Number(this.familyId)) {
                  const fullName = party.OrganisationId
                    ? party.FamilyFullName
                    : `${party.FirstName} ${party.LastName}`;
                  sourceFamilyNames.push(fullName);
                }
              });
          });

          if (sourceFamilyNames.length) {
            this.sourceFamilyName = sourceFamilyNames.join(' & ');
          }
          // flat array of families
          const familyList = [];
          uniqBy(InvolvedPartyPerson, 'FamilyId').forEach((obj) => {
            if (!Object.keys(obj).length) {
              return;
            }
            const { FamilyFullName, FamilyId } = obj;
            familyList.push({ FamilyId, FamilyFullName, IsClient: true });
          });

          InvolvedPartyEntity.forEach((obj) => {
            if (!Object.keys(obj).length) {
              return;
            }
            const { FamilyFullName, FamilyId } = obj;
            familyList.push({ FamilyId, FamilyFullName, IsClient: false });
          });

          this.familyList = familyList;
        }

        this.opportunityDetails = opportunityDetails;
      })
      .then(() => this.embedLoanAppOnOpportunities());
  }

  getScenarioDetails() {
    this.loanScenarioService
      .scenarioDetailsGet(this.opportunityId)
      .then(({ data }) => {
        this.scenarioDetails = data;
      });
  }

  onTitleEdit({ data }) {
    this.opportunityGeneral.title = data;
    this.setGeneralInfo();
  }

  setGeneralInfo() {
    return this.loanOpportunityService
      .setLoanOpportunityGeneral(this.opportunityId, this.opportunityGeneral)
      .then((data) => {
        if (!data) {
          return;
        }

        this.opportunityGeneral = data;
        this.opportunityStatusObj = this.getOpportunityStatusObj(data);
        toastSaveSuccess(this.toaster);
      });
  }

  saveTitle(event) {
    if (event && event.keyCode === KEY_CODE.ESC) {
      this.isEditTitle = false;
      this.opportunityGeneral.title = this.backupTitle;
      return;
    }

    if (event && event.keyCode !== KEY_CODE.WIN_ENT) {
      return;
    }

    this.isTitleSavingState = true;
    this.setGeneralInfo()
      .then(() => {
        this.isTitleSavingState = false;
        this.isEditTitle = false;
      })
      .catch(() => {
        toastError();
        this.isTitleSavingState = false;
        this.isEditTitle = true;
      });
  }

  changeTitle() {
    this.isEditTitle = true;
    this.backupTitle = this.opportunityGeneral.title;
  }

  changeGeneralInfo(objValue, shouldEmit = false) {
    this.opportunityGeneral = Object.assign({}, this.opportunityGeneral, {
      ...objValue,
    });
    this.opportunityStatusObj = this.getOpportunityStatusObj(
      this.opportunityGeneral,
    );
    this.setGeneralInfo().then(() => {
      shouldEmit &&
        this.loanAppEmailTemplateNZ &&
        this.$rootScope.$emit('EDIT_ADVISER_EMAIL');
    });
    if (objValue.adviserId) {
      this.getAssignedAdviserSubscription(objValue.adviserId);
    }
  }

  setAssistantAdviser(assistantId) {
    this.loanScenarioService.assignAssistant(this.opportunityId, assistantId);
    const assistantFamilyId = assistantId && assistantId.assistantFamilyId;
    this.opportunityGeneral = {
      ...this.opportunityGeneral,
      assistantFamilyId,
      AssistantFamilyId: assistantFamilyId,
    };
    this.opportunityStatusObj = this.getOpportunityStatusObj(
      this.opportunityGeneral,
    );
    if (assistantId.assistantFamilyId) {
      toastSuccess('Assistant adviser is updated!', 'Success');
      this.loanAppEmailTemplateNZ &&
        this.$rootScope.$emit('EDIT_ADVISER_EMAIL');
    }
  }

  onFileStatusChange({ data }) {
    this.opportunityStatusObj = data;
    this.changeStatus();
  }

  convertCardToApplication(card, e, sourceCardTypes) {
    if (sourceCardTypes.isLeads) {
      const {
        pipelineCardsId: pipelineCardsID,
        clientFamilyId: familyId,
        adviserId,
      } = card;
      const { pipelineStatusId: conversionStatus } = e;

      const setLoanApp = {
        LoanScenarioId: 0,
        AdviserId: adviserId || 0,
        Title: `Loan Application`,
        StatusName: 'ongoing',
        FamilyId: familyId,
        AssignedLenderId: '0',
        IsCardMovement: true,
      };

      const convertData = {
        pipelineCardsID,
        conversionStatusID: conversionStatus,
        loanID: 0,
        pipelineStatusID: PIPELINE_STATUS.NEW_APPLICATION,
      };

      this.loanScenarioService
        .scenarioSet(setLoanApp)
        .then(({ data: loanAppId }) => {
          convertData.loanAppID = loanAppId;
          return this.pipelineService.putPipelineConvertAndLink(
            pipelineCardsID,
            convertData,
          );
        })
        .then(() => {
          const routerParams = { familyId, loanAppId: convertData.loanAppID };
          toastr.success('Lead successfully converted!', 'Success');
          this.$state.go('app.loanApplicationDetailsV3', routerParams);
        });
    } else if (sourceCardTypes.isOpportunity) {
      const { loanScenarioId } = card;
      const { pipelineStatusId: ConversionStatusId } = e;
      const conversionStatus = { ConversionStatusId, ReasonSubStatusId: 0 };

      if (this.pipelineSharedData.isMergeLeadAndOpportunities) {
        const data = {
          card: {
            loanId: card.loanId,
            conversionStatusId: ConversionStatusId,
            reasonSubStatusId: 0,
            loanScenarioId,
            fromPipelineStatusId: card.pipelineStatusId,
            pipelineCardsId: card.pipelineCardsId,
          },
          isConversion: true,
          loanOpportunityService: this.loanOpportunityService,
        };
        return moveLoanOpportunityItem(data).then(() => {
          this.refreshCardsAndLendingList();
          this.contactSharedDataService.reloadContactCalculations();
        });
      }
      this.loanOpportunityService
        .setConversion(loanScenarioId, conversionStatus)
        .then(() => {
          this.refreshCardsAndLendingList();
          this.contactSharedDataService.reloadContactCalculations();
        });
    }
  }

  convertCard(card, e, cardType) {
    const defer = this.$q.defer();
    if (!e || !card) {
      return;
    }

    const isLeads = cardType === PIPELINE_TYPES_STR.LEADS;
    const isApplication = cardType === PIPELINE_TYPES_STR.APPLICATION;
    const isOpportunity = cardType === PIPELINE_TYPES_STR.OPPORTUNITY;

    const isPipelineStatusApplication =
      e.pipelineStatusId === CONVERSION_PIPELINE_STATUS.TO_APPLICATION;
    const moveTitle = isPipelineStatusApplication ? 'Application' : '';

    const modalContent = {
      content: `This record will be moved to ${moveTitle}`,
    };
    const sourceCardTypes = { isLeads, isApplication, isOpportunity };
    this.displayModal(modalContent, defer, e, card, sourceCardTypes);
    return defer.promise;
  }

  displayModal(modalContent, defer, e, card, sourceCardTypes) {
    const confirmationPopup = this.modalRenderService.renderPipelineStatusMoveConfirmationModal(
      modalContent,
    );
    confirmationPopup.result.then((result) => {
      if (!result || !result.isOk) {
        return defer.resolve(result);
      }

      if (e.pipelineStatusId === CONVERSION_PIPELINE_STATUS.TO_APPLICATION) {
        this.convertCardToApplication(card, e, sourceCardTypes);
      }
    });
  }

  convertCardToApplicationHandler(card, event, cardType) {
    if (!event || !card) {
      return;
    }
    const isConvertingFromLeadToApplication =
      event.pipelineStatusId === CONVERSION_PIPELINE_STATUS.TO_APPLICATION;

    const { clientFamilyId, pipelineCardsId, adviserId } = card;
    return new Promise((resolve) => {
      if (!isConvertingFromLeadToApplication) {
        resolve(null);
        return;
      }
      resolve(
        this.loanScenarioModelService.getLoansForPipelineLinking(
          clientFamilyId,
        ),
      );
    }).then((loans) => {
      if (!loans || !loans.length) {
        return this.convertCard(card, event, cardType);
      }
      const leadData = {
        pipelineCardsId,
        clientFamilyId,
        adviserId,
      };
      return leadsToApplicationConvert({
        modalRenderService: this.modalRenderService,
        loans,
        leadData,
      });
    });
  }

  convertStatusForViewOfAFileSuccess() {
    if (
      this.opportunityStatusObj.LoanStatusId ===
      LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH
    ) {
      this.redirectFile();
      return;
    }
    this.statusList.map((status) => {
      status.IsConversionStatus = isAlwaysConversionStatus(status);
      return status;
    });
    this.opportunityGeneral.pipelineStatusIdBak = this.opportunityStatusObj.PipelineStatusId;
    toastSaveSuccess(this.toaster);
  }

  convertStatusForViewOfAFile(card = {}) {
    const previousStatus = this.statusList.find(
      (status) =>
        status.PipelineStatusId === this.opportunityGeneral.pipelineStatusIdBak,
    );
    return this.loanFileStatusService
      .setLoanFileStatusConversion({
        card,
        status: this.opportunityStatusObj,
        previousStatus,
      })
      .then((result = {}) => {
        if (!result.isOk) {
          this.revertOpportunityStatusId();
          return;
        }
        this.convertStatusForViewOfAFileSuccess();
      });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  changeStatus() {
    const pipelineStatusId =
      this.opportunityStatusObj && this.opportunityStatusObj.PipelineStatusID;
    const isConversionStatus =
      this.opportunityStatusObj && this.opportunityStatusObj.IsConversionStatus;
    this.opportunityGeneral.pipelineStatusId = pipelineStatusId;
    const card = {
      toPipelineStatusId: pipelineStatusId,
      fromPipelineStatusId: this.opportunityGeneral.pipelineStatusIdBak,
      loanId: this.loanId,
      loanScenarioId: this.opportunityId,
      pipelineCardsId: this.opportunityGeneral.pipelineCardsId,
      clientFamilyId: this.familyId,
      adviserId: this.opportunityGeneral.adviserId,
    };
    if (!isConversionStatus) {
      if (this.pipelineSharedData.isMergeLeadAndOpportunities) {
        const params = {
          card,
          isConversion: false,
          loanOpportunityService: this.loanOpportunityService,
        };

        return moveLoanOpportunityItem(params).then(() =>
          toastSaveSuccess(this.toaster),
        );
      }

      this.opportunityGeneral.pipelineStatusIdBak = pipelineStatusId;
      return this.loanOpportunityService
        .setLoanOpportunityStatus(this.loanId, pipelineStatusId)
        .then(() => {
          toastSaveSuccess(this.toaster);
        });
    }

    if (this.viewOfAFileTurnedOn) {
      return this.convertStatusForViewOfAFile(card);
    }

    if (
      this.viewOfAFileTurnedOn &&
      pipelineStatusId === PIPELINE_STATUS.APPLICATION
    ) {
      this.convertCardToApplicationHandler(
        card,
        { pipelineStatusId },
        'leads',
      ).then((result) => {
        if (!result.isOk) {
          this.revertOpportunityStatusId();
        }
      });
    } else {
      const payload = {
        loanId: this.loanId,
        loanScenarioId: this.opportunityId,
        conversionStatusId: pipelineStatusId,
        pipelineCardsId: this.opportunityGeneral.pipelineCardsId,
        fromPipelineStatusId: this.opportunityGeneral.pipelineStatusIdBak,
      };

      return opportunityConvert(
        this.pipelineSharedData,
        this.modalRenderService,
        this.loanOpportunityService,
        pipelineStatusId,
        this.opportunityId,
        this.reasonList,
        payload,
      ).then((res) => {
        if (res && res.isOk) {
          toastr.success('Card successfully converted!', 'Success');

          this.$state.go('app.loanApplicationDetailsV3', {
            familyId: this.familyId,
            loanAppId: this.opportunityId,
          });
        } else {
          this.revertOpportunityStatusId();
        }
      });
    }
  }

  revertOpportunityStatusId() {
    this.opportunityStatusObj = this.statusList.find(
      (status) =>
        status.PipelineStatusID === this.opportunityGeneral.pipelineStatusIdBak,
    );
    this.opportunityGeneral.pipelineStatusId = this.opportunityGeneral.pipelineStatusIdBak;
  }

  checkSourceOpener() {
    const isContactOpener =
      (this.$stateParams.isContactOpener &&
        !!parseInt(this.$stateParams.isContactOpener, 10)) ||
      false;
    this.isContactOpener = isContactOpener;
    this.isPipelineOpener = !isContactOpener;
  }

  addRequirementModal(requirement) {
    const { InvolvedParties } = this.opportunityDetails;
    const {
      InvolvedPartyEntity,
      PersonBorrowers,
      RelatedParty,
    } = InvolvedParties;

    const involvedPartyList = {
      InvolvedPartyEntity,
      PersonBorrowers,
      RelatedParty,
    };

    const clients = [];
    Object.keys(involvedPartyList).forEach((partyType) => {
      const flatClients = involvedPartyList[partyType].reduce(
        (accum, currentVal) => {
          const {
            FamilyId,
            FamilyFullName: FullName,
            PreferredName,
            FirstName,
            LastName,
            PersonId,
            OrganisationId,
          } = currentVal;
          const client = {
            FamilyId,
            FullName: PersonId
              ? `${PreferredName || FirstName} ${LastName}`.trim()
              : FullName,
            IsClient: !!parseInt(PersonId, 10),
            PersonId: PersonId || OrganisationId,
          };

          return accum.concat(client);
        },
        [],
      );

      clients.push(...flatClients);
    });

    this.outstandingRequirementService.modal(
      this.loanId,
      requirement,
      clients,
      this.requirementsList,
    );
  }

  completeRequirement(requirementObj) {
    this.outstandingRequirementService.complete(requirementObj, this.loanId);
  }

  redirectContact() {
    this.$state.go(
      `app.${this.isBusiness ? 'BusinessAccount' : 'contactsSingle'}`,
      { familyId: this.familyId, activeTab: 'lending' },
    );
  }

  refreshOpportunity(familyId) {
    if (!familyId) {
      return;
    }
    const params = {
      ...this.$state.params,
      sourceFamilyId: familyId,
      familyId,
      loanAppId: this.opportunityId,
    };
    this.familyId = familyId;
    const config = {
      reload: false,
      inherit: false,
      notify: false,
      location: 'replace',
    };
    this.stateService.transition(this.$state.current, params, config);
    this.isFactFindReady = !!(
      this.viewOfAFileTurnedOn &&
      this.familyId &&
      this.opportunityId
    );
  }

  embedLoanAppOnOpportunities() {
    this.isFactFindReady =
      this.viewOfAFileTurnedOn &&
      !!parseInt(this.$state.params.sourceFamilyId, 10) &&
      !!parseInt(this.opportunityId, 10);

    if (
      this.viewOfAFileTurnedOn &&
      !parseInt(this.$state.params.sourceFamilyId, 10)
    ) {
      const { InvolvedParties } = this.opportunityDetails;
      const familyId =
        (InvolvedParties.PersonBorrowers &&
          InvolvedParties.PersonBorrowers.length &&
          InvolvedParties.PersonBorrowers[0].FamilyId) ||
        (InvolvedParties.InvolvedPartyEntity &&
          InvolvedParties.InvolvedPartyEntity.length &&
          InvolvedParties.InvolvedPartyEntity[0].FamilyId);

      this.refreshOpportunity(familyId);
    }
  }

  onAdviserSelect(data) {
    const adviserId = dotProp.get(data, 'originalObject.FamilyId');
    if (!adviserId) {
      return;
    }
    this.setAssistantAdviser({ assistantFamilyId: 0 });
    this.changeGeneralInfo({ adviserId, AdviserId: adviserId }, true);
  }

  changeTab(activeTab) {
    this.$state.params.activeTab = activeTab;
    this.stateService.transition(this.$state.current, this.$state.params);
  }

  backToOpportunityPage() {
    this.$window.history.back();
  }
}

export default OpportunityCtrl;
