import _ from 'lodash';

class taskDetailService {
  constructor(
    $q,
    dashboardService,
    contactService,
    tasksService,
    toaster,
    contactModelService,
  ) {
    'ngInject';

    this.$q = $q;
    this.contactModelService = contactModelService;
    this.dashboardService = dashboardService;
    this.contactService = contactService;
    this.tasksService = tasksService;
    this.toaster = toaster;

    this.handler = {
      reloadClients: () => {},
      saveSortData: () => {},
    };
  }

  taskAdviserListGet(taskSet, taskId, loanWritersOnly) {
    let taskAdviserList = [];
    const defer = this.$q.defer();
    this.contactModelService
      .taskAdviserListGet(loanWritersOnly)
      .then((respond) => {
        taskAdviserList = respond.data;
        taskAdviserList = _.reject(respond.data, ['BrokerID', 0]);
        _.each(respond.data, (item) => {
          item.BrokerID = item.BrokerID.toString();
        });
        defer.resolve({ data: taskAdviserList });
        if (parseInt(taskId, 10) === 0) {
          this.dashboardService.getBrokerBasicInfo().then((response) => {
            taskSet.assignedClientID = String(response.data.BrokerId);
            taskSet.CreatedByUser = String(response.data.BrokerId);
          });
        }
      });
    return defer.promise;
  }

  getAllByFilter(taskInput, taskSet, taskId, familyId) {
    this.contactService.getAllByFilter('*').then((respond) => {
      _.each(respond.data.FamilyList, (value) => {
        if (parseInt(value.FamilyID, 10) === parseInt(familyId, 10)) {
          taskInput.familyValue = value.FamilyFullName;
          if (parseInt(taskId, 10) === 0) {
            taskSet.FamilyId = value.FamilyID;
          }
        }
      });
    });
  }

  taskDetailsGet(taskId) {
    let taskSet = [];
    const defer = this.$q.defer();
    this.tasksService.taskDetailsGet(taskId).then((respond) => {
      taskSet = respond.data;
      if (taskSet) {
        taskSet.DueDate = new Date(taskSet.DueDate);
        taskSet.CreatedByUser = String(taskSet.CreatedByUser);
        defer.resolve({ data: taskSet });
      }
    });
    return defer.promise;
  }

  selectFamily(id, fullname, taskInput, taskSet, mainObject) {
    this.familyDetailsList = [];
    taskInput.familyValue = fullname;
    taskSet.FamilyId = id;
    mainObject.isSearchEnable = false;
    return this.familyDetailsList;
  }

  closeFamilyList(taskInput, mainObject) {
    this.familyDetailsList = [];
    mainObject.isSearchEnable = false;
    return this.familyDetailsList;
  }

  editTemplateTask(task, mainObject) {
    this.taskDetails = [];
    task.isEditMode = true;
    task.DueDateFormat = new Date(task.DueDate);
    if (!mainObject.editInfoShown) {
      this.toaster.pop(
        'info',
        'Info',
        'Changing the date will update subsequent tasks.',
      );
      mainObject.editInfoShown = true;
    }

    return this.taskDetails;
  }
}

export default taskDetailService;
