import { pluralize } from 'Common/utilities/string';
import { TERM_KEYS, RATE_TYPE as RATE_TYPE_TEXT } from 'Common/constants/loan';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { ASSET_DEFAULT_FREQUENCY } from 'Common/constants/iFinance';
import { parseToInt10 } from 'Common/utilities/parse';
import get from 'lodash/get';
import set from 'lodash/set';
import find from 'lodash/find';
import forOwn from 'lodash/forOwn';
import { REPAYMENT_FREQUENCY } from 'Common/constants/repayment';
import {
  LOAN_STRUCTURE_TYPE,
  RATE_TYPE,
} from 'Common/constants/loanAppDiscount';
import { catchError } from 'Common/utilities/promise';
import { displayWarning } from 'Common/utilities/alert';
import { loanSplitSetBuilderForMyCRM } from 'Common/mappers/loanSplit';
import { validateDate, formatDate } from 'Common/utilities/date';
import { TO_MYCRM_DATE_FORMAT } from 'Common/constants/dateConfigs';

const MONTHS_IN_YEAR = 12;

const JOINT = {
  Percentage: 0,
  BorrowerID: 0,
  FirstName: 'Joint',
  LastName: '',
  PreferedName: 'Joint',
  ticked: false,
};

export function getFinanceCatIDsWithEmployer(financeCategories) {
  if (!financeCategories) {
    return [];
  }
  const financeCatWithEmployer = ['Novated Lease'];
  return financeCategories
    .filter((elem) => financeCatWithEmployer.includes(elem.name))
    .map((elem) => elem.value);
}

export function getLoanTermInMonths(termValue, showUnit = true) {
  const monthValue = termValue ? Math.round(termValue * MONTHS_IN_YEAR) : 0;
  return showUnit ? pluralize(monthValue, 'month') : monthValue;
}

export function getFrequencyText(term) {
  if (!term) {
    return '';
  }
  const yearEquivalent = term / MONTHS_IN_YEAR;
  const useYear = yearEquivalent % 1 === 0;
  const termNumber = useYear ? yearEquivalent : term;
  const termUnit = useYear ? 'year' : 'month';
  return `every ${pluralize(termNumber, termUnit)}`;
}

export function getValueLoanTerm(loanTerm) {
  return loanTerm / MONTHS_IN_YEAR;
}

export function isAssetFinance(lendingCategoryId) {
  return lendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE;
}

export function hasAssetFinance(data) {
  return !!(data && data.AssetFinance);
}

export function isRateDiscountValid(rateDiscount) {
  return !(rateDiscount && rateDiscount > 2);
}

export const getRepaymentFrequencyList = ({ thisCtrl }) => (apiService) => {
  apiService.frequency().then(({ data }) => {
    if (!data || !data.length) {
      return;
    }
    thisCtrl.repaymentFrequencyList = data;
    if (!hasAssetFinance(thisCtrl.loanStructure)) {
      thisCtrl.loanStructure.AssetFinance = {
        ...thisCtrl.loanStructure.AssetFinance,
        RepaymentFrequency: ASSET_DEFAULT_FREQUENCY,
      };
      return;
    }
    thisCtrl.selectFrequency(
      thisCtrl.loanStructure.AssetFinance.RepaymentFrequency,
    );
  });
};

export const shouldCloseLoanModal = ({
  loanStructureAction,
  isFromCustomerCare,
  brokerEventId,
}) => {
  const isEdit = loanStructureAction === 'edit';
  const shouldCloseModal = isEdit && isFromCustomerCare;
  return shouldCloseModal && !!brokerEventId;
};

export const cancelAction = ({ $scope }) => () => {
  if ($scope.isFromCustomerCare) {
    $scope.cancel();
  } else {
    $scope.editLoanStructure();
  }
};

export const showLoanStructureEdit = ({ $scope, loanStructureDetails }) => {
  const isStructureFromCC =
    parseToInt10(loanStructureDetails.LoanStructureId) ===
    $scope.params.loanStructureIdFromCC;
  const isEnableEdit = !!$scope.params.isFromCustomerCare && isStructureFromCC;
  isEnableEdit && $scope.loanStructureModal('edit', loanStructureDetails);
};

export const borrowersDetailsGet = ({ $scope, contactService }) => {
  contactService
    .borrowersDetailsGet($scope.params.familyId, $scope.params.loanId)
    .then(({ data }) => {
      $scope.borrowingEntity =
        get(data, 'length', 0) === 1 ? data : [JOINT].concat(data);
    });
};

export const getLoanTermMonthly = ({ $scope, loanStructureService }) => {
  loanStructureService.getLoanTermMonthly().then((data) => {
    $scope.termHolidayList = data;
  });
};

export const isInterestOnly = (loanStructureType) => {
  return loanStructureType === LOAN_STRUCTURE_TYPE.INTEREST_ONLY;
};

export const isFixed = (interestRateType) => {
  return interestRateType === RATE_TYPE.FIXED;
};

export const mapSelectedBorrowers = ({ borrowers = [], borrowingEntity }) => {
  const borrowersLength = get(borrowers, 'length', 0);
  if (!borrowersLength) {
    return [];
  }
  const firstBorrowerId = get(borrowers[0], 'BorrowerID', 0);
  const isJoint = borrowersLength > 1;
  return borrowingEntity.map((borrower) => {
    const { BorrowerID } = borrower;
    borrower.ticked = false;
    borrower.PreferedName = `${get(borrower, 'FirstName', '')} ${get(
      borrower,
      'LastName',
      '',
    )}`;
    if (isJoint) {
      !BorrowerID && (borrower.ticked = true);
    } else {
      borrower.BorrowerID === firstBorrowerId && (borrower.ticked = true);
    }
    return borrower;
  });
};

export const mapImportantDates = (loanStructureDetails) => {
  const { ImportantDates: importantDates } = loanStructureDetails;

  const mapped = {
    RepaymentHolidayDate: {},
    InterestOnlyDate: {},
  };

  forOwn(importantDates, (val, key) => {
    mapped[key] = mapTermForAPI(val);
  });

  return mapped;
};

export const mapTermForAPI = (term = {}) => {
  const dateFormatter = formatDate(TO_MYCRM_DATE_FORMAT);
  const dateStart = validateDate(term.StartDate);
  const dateEnd = validateDate(term.FinishDate);
  return {
    ...term,
    StartDate: dateStart ? dateFormatter(term.StartDate) : null,
    FinishDate: dateEnd ? dateFormatter(term.FinishDate) : null,
  };
};

export const mapTermObj = (data = {}) => {
  const {
    StartDate: dateStart,
    FinishDate: dateEnd,
    Term: termSelected,
    IsLink: isLinked,
  } = data;
  return {
    dateStart: validateDate(dateStart),
    dateEnd: validateDate(dateEnd),
    termSelected: termSelected || null,
    isLinked,
  };
};

export const getTotalLoanAmount = ({ IsCapitaliseLMI, Value, LMI }) => {
  return IsCapitaliseLMI ? (+Value || 0) + (+LMI || 0) : Value;
};

export const loanStructureDetailsMapper = ({ $scope, data }) => {
  const { LoanSplitsProductList } = $scope.params;
  const {
    Borrowers,
    ProductID,
    InterestRateType,
    LoanStructureType,
    InterestOnlyTerm,
    LoanInterestRate,
    ImportantDates,
    Product,
    RepaymentAmount,
    RepaymentFrequency,
  } = data;
  const notExistingProductID =
    LoanSplitsProductList &&
    !find(LoanSplitsProductList, {
      ProductID,
    });
  const selectedProduct = {
    ProductID: ProductID,
    ProductName: Product,
  };
  const { InterestOnlyDate, RepaymentHolidayDate, FixedRateDate } = {
    ...ImportantDates,
  };
  const borrowingEntityListSelected = $scope.isLoanSplit
    ? mapSelectedBorrowers({
        borrowers: Borrowers,
        borrowingEntity: $scope.borrowingEntity,
      })
    : [];
  const { loanId, completedEventTypeId } = $scope.params;
  return {
    ...data,
    RepaymentAmount: (+RepaymentAmount || 0).toFixed(2),
    borrowingEntity: $scope.borrowingEntity,
    borrowingEntityListSelected,
    validProductId: !!parseToInt10(ProductID),
    isInterestOnly: isInterestOnly(LoanStructureType),
    isFixed: isFixed(InterestRateType),
    LoanId: loanId,
    IsItFromCustomerCare: false,
    CompletedEventTypeId: completedEventTypeId,
    isProductUpdated: false,
    LoanInterestRate: +LoanInterestRate * 100,
    RepaymentFrequency: RepaymentFrequency.trim(),
    updateImportantDates: updateImportantDates({ loanSplit: data, $scope }),
    loanSplitsProductList: [
      ...(LoanSplitsProductList || []),
      ...(notExistingProductID ? [selectedProduct] : []),
    ],
    termTableDates: {
      fixedRate: mapTermObj(FixedRateDate),
      repaymentHoliday: mapTermObj(RepaymentHolidayDate),
      interestOnly: {
        ...mapTermObj(InterestOnlyDate),
        termSelected: InterestOnlyTerm || null,
      },
    },
  };
};

export const updateImportantDates = ({ loanSplit, $scope }) => (key, data) => {
  const importantDates = {
    ...loanSplit.ImportantDates,
  };

  const {
    dateStart: StartDate,
    dateEnd: FinishDate,
    termSelected: Term,
    isLinked: IsLink,
  } = data;

  loanSplit.ImportantDates = {
    ...importantDates,
    [key]: {
      StartDate,
      FinishDate,
      Term,
      IsLink,
    },
  };

  if (key === TERM_KEYS.INTEREST_ONLY) {
    loanSplit.InterestOnlyTerm = Term;
  } else if (key === TERM_KEYS.FIXED_RATE) {
    loanSplit.FixedRateBegin = StartDate;
    loanSplit.FixedRateExpiry = FinishDate;
    loanSplit.RateTerm = Term;
    loanSplit.IsLinkRateExpiry = IsLink;
  }
  return $scope.updateScopeImportantDate(loanSplit, key);
};

export const updateScopeImportantDate = ({ $scope }) => {
  $scope.updateScopeImportantDate = (loanSplit, key) => {
    const {
      LoanStructureId,
      ImportantDates,
      FixedRateBegin,
      FixedRateExpiry,
      RateTerm,
      IsLinkRateExpiry,
      InterestOnlyTerm,
    } = loanSplit;
    const index = $scope.LoanStructureListGet.findIndex(
      (split) => split.LoanStructureId === LoanStructureId,
    );
    $scope.LoanStructureListGet[index] = {
      ...$scope.LoanStructureListGet[index],
      ImportantDates,
      FixedRateBegin,
      FixedRateExpiry,
      RateTerm,
      IsLinkRateExpiry,
      InterestOnlyTerm,
    };
    const calculateRepayment = TERM_KEYS.REPAYMENT_HOLIDAY !== key;
    return $scope.setLoanSplit(
      $scope.LoanStructureListGet[index],
      calculateRepayment,
    );
  };
};

export const inlineFormFunctions = ({
  $scope,
  broadcastDataService,
  contactService,
  loanProfilerService,
}) => {
  onProductSelected({ $scope });
  onItemSelect({ $scope });
  onSelectAll({ $scope });
  rateTypeSelected({ $scope });
  setLoanSplit({
    $scope,
    contactService,
    loanProfilerService,
  });
  copyToActualRepayment({ $scope });
  calculateRepayment({ $scope, broadcastDataService });
  sendUpdatedRate({ $scope, contactService });
  bindSplitFunctions({ $scope });
  updateScopeImportantDate({ $scope });
};

export const bindSplitFunctions = ({ $scope }) => {
  const {
    onProductSelected,
    onItemSelect,
    onSelectAll,
    rateTypeSelected,
    calculateRepayment,
    setLoanSplit,
    copyToActualRepayment,
  } = $scope;
  $scope.componentSplitFn = {
    onProductSelected,
    onItemSelect,
    onSelectAll,
    rateTypeSelected,
    calculateRepayment,
    setLoanSplit,
    copyToActualRepayment,
  };
};

export const copyToActualRepayment = ({ $scope }) => {
  $scope.copyToActualRepayment = (loanSplit) => {
    loanSplit.RepaymentAmount = loanSplit.MinimumRepaymentAmount;
    return $scope.setLoanSplit(loanSplit);
  };
};

export const sendUpdatedRate = ({ $scope, contactService }) => {
  $scope.sendUpdatedRate = (loanStructureId, loanId) => {
    contactService
      .loanStructureDetailsGet(loanId, loanStructureId)
      .then(({ data }) => {
        const interestRate = +get(data, 'LoanInterestRate', 0) * 100;
        const index = $scope.LoanStructureListGet.findIndex(
          (loanSplit) => loanSplit.LoanStructureId === loanStructureId,
        );
        set(
          $scope.LoanStructureListGet[index],
          'LoanInterestRate',
          interestRate,
        );
        return $scope.calculateRepayment($scope.LoanStructureListGet[index]);
      })
      .catch(catchError);
  };
};

export const onProductSelected = ({ $scope }) => {
  $scope.onProductSelected = (loanSplit) => {
    const selectedProductId = parseToInt10(get(loanSplit, 'ProductID', 0));
    const selectedProduct = $scope.params.LoanSplitsProductList.find(
      (product) => parseToInt10(product.ProductID) === selectedProductId,
    );
    if (!selectedProduct) {
      return loanSplit;
    }
    const {
      InterestOnly,
      LoanType,
      LoanTerm,
      InterestRate,
      RateType,
      ProductName,
    } = selectedProduct;
    const {
      LoanTerm: splitLoanTerm,
      RepaymentFrequency,
      LoanStructureType,
      LoanInterestRate,
      InterestRateType,
    } = loanSplit;
    loanSplit.LoanStructureType = selectedProductId
      ? LoanType
      : LoanStructureType;
    loanSplit.LoanTerm = splitLoanTerm || LoanTerm;
    loanSplit.RepaymentFrequency =
      RepaymentFrequency || REPAYMENT_FREQUENCY.MONTHLY;
    loanSplit.LoanInterestRate = selectedProductId
      ? InterestRate
      : LoanInterestRate;
    loanSplit.InterestRateType = selectedProductId
      ? RateType
      : InterestRateType;
    loanSplit.validProductId = !!selectedProductId;
    loanSplit.isInterestOnly = InterestOnly;
    loanSplit.isFixed = isFixed(RateType);
    loanSplit.Product = ProductName;
    loanSplit.isProductUpdated = true;
    return $scope.calculateRepayment(loanSplit);
  };
};

export const onItemSelect = ({ $scope }) => {
  $scope.onItemSelect = (data, loanSplit) => {
    const { BorrowerID } = data;
    const { borrowingEntity, borrowingEntityListSelected } = loanSplit;
    const selectedBorrowersLength = get(
      borrowingEntityListSelected,
      'length',
      0,
    );
    const validIds = borrowingEntityListSelected.every(
      (borrower) => borrower.BorrowerID,
    );
    const selectAll =
      !selectedBorrowersLength || (selectedBorrowersLength > 1 && validIds);
    if (selectAll) {
      return $scope.onSelectAll(loanSplit);
    }
    loanSplit.borrowingEntity = borrowingEntity.map((borrower) => {
      borrower.ticked = borrower.BorrowerID === BorrowerID;
      return borrower;
    });
    loanSplit.borrowingEntityListSelected = loanSplit.borrowingEntity.filter(
      (borrower) => borrower.ticked,
    );
    return $scope.setLoanSplit(loanSplit);
  };
};

export const onSelectAll = ({ $scope }) => {
  $scope.onSelectAll = (loanSplit) => {
    const { borrowingEntity } = loanSplit;
    loanSplit.borrowingEntity = borrowingEntity.map((borrower, index) => {
      borrower.ticked = !index;
      return borrower;
    });
    loanSplit.borrowingEntityListSelected = loanSplit.borrowingEntity.filter(
      (borrower) => borrower.ticked,
    );
    return $scope.setLoanSplit(loanSplit);
  };
};

export const rateTypeSelected = ({ $scope }) => {
  return $scope;
};

export const calculateRepayment = ({ $scope, broadcastDataService }) => {
  $scope.calculateRepayment = (loanSplit) => {
    const {
      Value,
      LoanInterestRate,
      LoanTerm,
      RepaymentFrequency,
      LoanStructureType,
    } = loanSplit;
    const isInvalid = !Value || !LoanInterestRate || !LoanTerm;
    if (isInvalid) {
      return loanSplit;
    }
    broadcastDataService.calRep
      .calculateRepayment(
        getTotalLoanAmount(loanSplit),
        LoanInterestRate,
        LoanTerm,
        RepaymentFrequency,
        LoanStructureType,
      )
      .then((response) => {
        loanSplit.MinimumRepaymentAmount = (+response || 0).toFixed(2);
        return $scope.setLoanSplit(loanSplit);
      })
      .catch((error) => {
        error && catchError(error);
        return loanSplit;
      });
  };
};

export const mapBorrowers = ({
  borrowingEntityListSelected,
  borrowingEntity,
}) => {
  if (!get(borrowingEntityListSelected, 'length', 0)) {
    return [];
  }
  const firstBorrowerId = get(borrowingEntityListSelected, '[0].BorrowerID', 0);
  return firstBorrowerId
    ? [borrowingEntityListSelected[0]]
    : borrowingEntity.filter((borrower) => borrower.BorrowerID);
};

export const setLoanSplit = ({
  $scope,
  contactService,
  loanProfilerService,
}) => {
  $scope.setLoanSplit = (loanSplit, calculateRepayment) => {
    const {
      LoanStructureType,
      InterestRateType,
      borrowingEntityListSelected,
      borrowingEntity,
      isProductUpdated,
      productLoanStructureId,
      LoanInterestRate,
      LoanId,
      LoanStructureId,
    } = loanSplit;
    loanSplit.isInterestOnly = isInterestOnly(LoanStructureType);
    loanSplit.isFixed = isFixed(InterestRateType);
    if (!get($scope, 'setLoanDetails.Lender.LenderId')) {
      displayWarning(
        'Select lender first at Loan Details tab.',
        'No Lender',
        false,
      );
      return loanSplit;
    }
    const borrowers = mapBorrowers({
      borrowingEntityListSelected,
      borrowingEntity,
    });

    const params = loanSplitSetBuilderForMyCRM({
      ...loanSplit,
      Borrowers: borrowers,
      LoanInterestRate: LoanInterestRate / 100,
      ImportantDates: mapImportantDates(loanSplit),
    });

    contactService
      .loanStructureSet(params)
      .then(() => {
        if (!isProductUpdated) {
          return calculateRepayment
            ? $scope.sendUpdatedRate(LoanStructureId, LoanId)
            : loanSplit;
        }
        loanSplit.isProductUpdated = false;
        return productLoanStructureId
          ? setLoanModalFavouriteProduct({
              loanSplit,
              loanProfilerService,
            })
          : getProductLoanScenarioId({
              loanSplit,
              loanProfilerService,
            });
      })
      .catch((error) => {
        catchError(error);
        return loanSplit;
      });
  };
};

export const getProductLoanScenarioId = ({
  loanSplit,
  loanProfilerService,
}) => {
  const { BrokerEventId } = loanSplit;
  loanProfilerService
    .getSelectedProductDetailsGetByLoanStructure(BrokerEventId)
    .then(({ data }) => {
      loanSplit.productLoanStructureId = get(data, '[0].LoanStructureID', 0);
      return setLoanModalFavouriteProduct({
        loanSplit,
        loanProfilerService,
      });
    })
    .catch((error) => {
      catchError(error);
      return loanSplit;
    });
};

export const setLoanModalFavouriteProduct = ({
  loanSplit,
  loanProfilerService,
}) => {
  const {
    LoanId,
    productLoanStructureId,
    ProductID,
    BrokerEventId,
  } = loanSplit;
  loanProfilerService
    .setLoanModalFavouriteProduct({
      loanId: LoanId,
      loanstructureId: productLoanStructureId,
      productId: ProductID,
      brokerEventId: BrokerEventId,
    })
    .then(() => {
      return loanSplit;
    })
    .catch((error) => {
      catchError(error);
      return loanSplit;
    });
};

export const getRateTypeByCountry = (isNZ) => {
  return isNZ
    ? [
        RATE_TYPE_TEXT.FLOATING,
        RATE_TYPE_TEXT.FIXED,
        RATE_TYPE_TEXT.REVOLVING_CREDIT,
      ]
    : [RATE_TYPE_TEXT.VARIABLE, RATE_TYPE_TEXT.FIXED];
};
