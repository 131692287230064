/* eslint-disable angular/window-service */
import { getConfigFromEnv } from 'Common/utilities/config';

import * as Sentry from '@sentry/browser';
import { getLogger } from '@loanmarket/logger-core';
import {
  OKTA_OAUTH_ERRORS,
  OKTA_ERROR_NAMES,
  OKTA_ERROR_CODES,
} from 'Common/constants/oktaErrorTypes';

const config = getConfigFromEnv();
const loggerName = 'Okta Session';

export const LOGOUT_CAUSES = {
  timeSettings: 'time settings',
  cookieSettings: 'cookie settings',
  sessionExpired: 'session expired',
  userLogout: 'user logout',
};

const auth = window.auth;

export const logout = async (cause = LOGOUT_CAUSES.userLogout) => {
  const logger = getLogger(loggerName);
  logger.info('Logging user out');
  if (window.technology === 'react') {
    auth
      .revokeAccessToken()
      .then(() => {
        logger.info('revoked');
        auth.signOut({
          revokeAccessToken: false,
        });
      })
      .catch((error) => logger.error('revoke', error));
  } else {
    return auth
      .closeSession()
      .then(() => {
        logger.info('Revoking access token');
        return auth.revokeAccessToken();
      })
      .then(() => {
        const CLOUDWATCH_KEY = 'BowserCloudWatch:key';
        const logStreamItem = window.localStorage.getItem(CLOUDWATCH_KEY);
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.localStorage.setItem(CLOUDWATCH_KEY, logStreamItem);

        switch (cause) {
          case LOGOUT_CAUSES.sessionExpired: {
            const path = encodeURIComponent(window.location.href);
            const query = `?path=${path}&sessionExpired=true`;
            logger.info(
              `Session has expired. Redirecting to log in page with query: ${query}`,
            );
            window.location.assign(`${config.oktaLogin}${query}`);
            break;
          }
          case LOGOUT_CAUSES.timeSettings:
            logger.info('User has incorrect time settings');
            window.location.assign(`${config.authUrl}/app/time-instructions`);
            break;
          case LOGOUT_CAUSES.cookieSettings:
            logger.info('User has incorrect cookie settings');
            window.location.assign(
              `${config.authUrl}/app/cookies-instructions`,
            );
            break;
          default:
            logger.info('User logged out. Redirecting to log in page');
            window.location.assign(config.oktaLogin);
        }
      });
  }
};

export const setListeners = (onRenew) => {
  const logger = getLogger(loggerName);
  auth.tokenManager.on('renewed', (_, newToken) => {
    logger.info(`Renewing session - Timestamp: ${Date.now()}`);
    onRenew(newToken);
  });

  auth.tokenManager.on('error', (error) => {
    logger.error(
      `Token Error - Timestamp: ${Date.now()} ERROR: ${JSON.stringify(error)}`,
    );
    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({ event: 'error:session_expiry' });
    }

    logout(LOGOUT_CAUSES.sessionExpired);
  });
};

export const fetchToken = () => {
  const logger = getLogger(loggerName);
  logger.info(`Fetching Token - Timestamp: ${Date.now()}`);
  return auth.token.getWithoutPrompt({
    scopes: ['openid', 'profile', 'email'],
  });
};

export const setTokens = (data) => {
  const { tokens } = data;

  if (tokens && tokens.idToken) {
    auth.tokenManager.add('idToken', tokens.idToken);
  }
  if (tokens && tokens.accessToken) {
    auth.tokenManager.add('accessToken', tokens.accessToken);
  }
};

export const logOktaErrorToSentry = (error, accessToken) => {
  if (!isOAuthLoginRequiredError(error)) {
    Sentry.captureException(error, {
      tags: {
        jwt: accessToken,
        cookieSupported: window.cookie3PCSupport,
      },
    });
  }
};

export const getAccessToken = () => {
  return auth.tokenManager
    .get('accessToken')
    .then((token) => {
      if (token) {
        return token.value;
      }
      return null;
    })
    .catch((error) => {
      logOktaErrorToSentry(error);
    });
};

export const isTimeError = (message) => {
  return (
    message === OKTA_OAUTH_ERRORS.jwt_expired ||
    message === OKTA_OAUTH_ERRORS.jwt_future
  );
};

export const isOAuthLoginRequiredError = (error) => {
  if (!error) {
    return false;
  }

  const { name, errorCode } = error;
  return (
    name === OKTA_ERROR_NAMES.OAUTH &&
    errorCode === OKTA_ERROR_CODES.LOGIN_REQUIRED
  );
};
