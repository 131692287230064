export function convertToLowerCase(str) {
  return str ? str.toLowerCase() : '';
}

export function isAlphaNumeric(str, minlength, maxlength) {
  if (!str) {
    return false;
  }

  let checker;
  if (minlength && maxlength) {
    checker = new RegExp(`^[A-Za-z0-9]{${minlength},${maxlength}}$`);
  } else if (minlength && !maxlength) {
    checker = new RegExp(`^[A-Za-z0-9]{${minlength},}$`);
  } else if (maxlength && !minlength) {
    checker = new RegExp(`^[A-Za-z0-9]{0,${maxlength}}$`);
  } else {
    checker = new RegExp(/^[\dA-Za-z]*$/);
  }
  return checker.test(str);
}

export function grammaticallySeparateByComma(strings, conjunction = '&') {
  if (!strings || !strings.length) {
    return '';
  }

  const arrayLength = strings.length;
  if (arrayLength === 1) {
    return strings[0].trim();
  }

  const lastIndex = arrayLength - 1;
  return strings.reduce((accum, currentStr, index) => {
    if (index === 0) {
      return currentStr.trim();
    } else if (index < lastIndex) {
      return `${accum}, ${currentStr.trim()}`;
    }
    return `${accum} ${conjunction} ${currentStr.trim()}`;
  }, '');
}

export function formatRawNewLine(str) {
  return (str && str.replace(/\\n/g, '\n')) || '';
}

export function isInIPHealthDataList(dataList) {
  return dataList && dataList.length;
}

export function removeSpacesOnString(str) {
  return (str && str.replace(/\s+/g, '')) || '';
}

export function lowercaseFirstLetter(string) {
  return string && string[0].toLowerCase() + string.slice(1);
}

export function removeMultipleWhiteSpace(str) {
  return (str && str.replace(/  +/g, ' ').trim()) || '';
}

export function capitalizeFirstLetter(string) {
  if (!string) {
    return '';
  }
  return string && string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export const capitalizeFirstLetterOnly = (string) => {
  if (!string) {
    return '';
  }
  return string && string[0].toUpperCase() + string.slice(1);
};
export function capitalizeText(string, delimiter = ' ') {
  if (!string) {
    return '';
  }

  const capString = string.split(delimiter).reduce((accum, currentText) => {
    return `${accum}${capitalizeFirstLetter(currentText)} `;
  }, '');
  return capString.trim();
}

export function camelize(str) {
  if (!str) {
    return '';
  }
  return str.replace(/^\w|[A-Z]|\b\w|\s+/g, (match, index) => {
    if (+match === 0) {
      return '';
    } // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function getFirstLetter(str) {
  return (str && str.replace(/\W/g, '')[0]) || '';
}

export function isValidEmailAddress(str) {
  if (!str) {
    return false;
  }

  const checker = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/,
  );
  return checker.test(str);
}

export function splitByCapitalLetters(str, separator = ' ', toLowerCase) {
  if (typeof str !== 'string') {
    return '';
  }

  const splitStr = str.split(/(?=[A-Z])/).join(separator);
  return toLowerCase ? splitStr.toLowerCase() : splitStr;
}

export function pluralize(count, noun, suffix = 's') {
  if (!noun) {
    return '';
  }
  return `${count} ${noun}${count !== 1 ? suffix : ''}`;
}

export function splitString(commaSeparatedString) {
  if (typeof commaSeparatedString !== 'string' || !commaSeparatedString) {
    return [];
  }

  return commaSeparatedString.split(',').map((str) => str.trim());
}

export function textLimit(text, charLimit, strictLimit) {
  if (!text) {
    return '';
  }
  if (text.length <= charLimit) {
    return text;
  }

  const ellipsis = `...`;
  return strictLimit
    ? `${text.slice(0, charLimit - ellipsis.length)}${ellipsis}`
    : `${text.slice(0, charLimit)}${ellipsis}`;
}

export function hasReadMore(text, charLimit) {
  const textTrimmed = textLimit(text, charLimit);
  return textTrimmed
    ? textTrimmed.endsWith('...') && text.length > charLimit
    : false;
}

export function convertTextToKey(text) {
  if (!text) {
    return '';
  }
  return text.replace(/([,-;]|\s+)/g, '');
}

export function getLastStringCharacters(word, charLength) {
  // eslint-disable-next-line unicorn/prefer-string-slice
  return word.substr(word.length - charLength, word.length);
}
export const getTrustedHtml = ({ scope, sce }) => {
  if (!scope || !scope.emailDetail || !sce || !sce.trustAsHtml) {
    return;
  }
  const { IframeContent: iframeContent } = scope.emailDetail;
  scope.emailDetail.iframeContent = sce.trustAsHtml(iframeContent);
};

export function stringFormatter(value, strFormat = '', formatChar = '9') {
  if (!value) {
    return '';
  }
  if (!strFormat) {
    return value;
  }
  const format = strFormat.replace(/\?/g, '');
  const stringValue = value.toString();
  const valueArr = [...stringValue];
  const formatArr = [...format];
  let currentValueIndex = 0;
  const formattedValueArr = formatArr.reduce((accum, currentValue) => {
    if (currentValue !== formatChar) {
      return [...accum, currentValue];
    }
    const posValue = valueArr[currentValueIndex] || '';
    currentValueIndex++;
    if (!posValue) {
      return accum;
    }
    return [...accum, posValue];
  }, []);
  return (
    (formattedValueArr &&
      formattedValueArr.length &&
      formattedValueArr.join('')) ||
    ''
  );
}

export function concatenateValidStrings(stringArr, separator = ' - ') {
  if (!stringArr || !stringArr.length) {
    return '';
  }
  return stringArr
    .filter((current) => (current || '').toString().trim())
    .join(separator);
}

export function stripHtmlSpaces($window, html, separator = '') {
  const tmp = $window.document.createElement('DIV');
  tmp.innerHTML = html;
  const strippedHtml = tmp.textContent || '';
  return strippedHtml
    .replace(/\s+/g, separator)
    .replace(/(\r\n|\n|\r)/gm, separator);
}

export function stripHtmlTags(text) {
  return text.replace(/(<([^>]+)>)/gi, '');
}

export function characterCount(text) {
  return text.trim().length;
}

export function getWordCountFromHtml(text) {
  const newString = stripHtmlTags(text);
  return characterCount(newString);
}

export const capitalizeTextFromCamelCase = (text) =>
  capitalizeText(splitByCapitalLetters(text));

export const formatNewlineToHtmlBreak = (text) => {
  if (!text) {
    return;
  }
  const removedTagNewLine = text.replace(/>(?:\\|[\n\r]+)+/g, '>');
  return removedTagNewLine.replace(/(\r\n|\n|\r)/g, '<br/>');
};

export const formatHtmlBreakToNewline = (text) => {
  if (!text) {
    return;
  }
  var regex = /<br\s*\/?>/gi;
  return text.replace(regex, '\n');
};

export const getPlainValueFromHtml = (value = '') => {
  return value.replace(/<[^>]+>/gm, '');
};
