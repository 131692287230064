import angular from 'angular';
import SVGInjector from 'svg-injector';
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import { getLogger } from '@loanmarket/logger-core';
import axios from 'axios';
import { BROKER_STATUS } from 'Common/constants/brokerStatus';
import { setToken, getToken, removeToken } from 'Common/utilities/token';
import {
  fetchToken,
  getAccessToken,
  setTokens,
  logout,
  isTimeError,
  LOGOUT_CAUSES,
  logOktaErrorToSentry,
} from 'Common/utilities/oktaAuth';
import {
  getFeaturesData,
  getPilotFeatureKeyList,
  setFeaturesData,
} from 'Common/utilities/features';
import liveChat from 'Common/config/liveChat';
import { mappedRolloutProperties } from 'Common/utilities/rollout';
import { constructConfigRequest } from 'Common/utilities/request';
import {
  setConfigData,
  getConfigData,
  getConfigFromEnv,
} from 'Common/utilities/config';
import {
  getCountryCode,
  getUserInfoData,
  setUserInfoData,
} from 'Common/utilities/user';
import { getApiResourceBasedOnLocation } from 'Common/utilities/windowLocation';
import { userInfoWithAddedDetailsBuilderForUI } from 'Common/mappers/contact';
import {
  getActiveSubscriptionsKeyList,
  setSubscriptionData,
} from 'Common/utilities/subscription';
import { getTimestamp } from 'Common/utilities/date';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

/* eslint-disable angular/window-service, angular/document-service */
const redirectToBaseUrl = () => {
  window.location.assign('/');
};

export function bootstrapRun() {
  angular.bootstrap('#bootstrapApp', ['app']);
}

export function loanMarketValidation() {
  return window.location.href.search(/loanmarket.+(com.au|co.id)/) !== -1;
}

export function runDebug(data) {
  window.mydebug = {};
  // debug
  if (data.debug) {
    window.mydebug.log = (...args) => {
      window.console.log([...args]);
    };
  } else {
    window.mydebug.log = () => {};
    window.console.log = () => {};
    window.addEventListener('error', () => {
      return true;
    });
  }
}

export function setUpSentry() {
  const data = getConfigData();
  // Sentry Plugin
  // Note: needs to run before bootstrapping application
  const sentryEnvs = ['sit', 'uat', 'prod'];
  const validSentryEnvironment =
    data.sentry_dsn && data.sentry_env && sentryEnvs.includes(data.sentry_env);
  // eslint-disable-next-line no-console
  console.info(`MyCRM version: ${process.env.NPM_PACKAGE_VERSION}`);

  if (validSentryEnvironment) {
    Sentry.init({
      dsn: data.sentry_dsn,
      environment: data.sentry_env,
      maxBreadcrumbs: 50,
      debug: true,
      release: `mycrm-adviser-ui@${process.env.NPM_PACKAGE_VERSION}`,
    });
  }
}

export function setUpSentryUser() {
  const user = getUserInfoData();
  const data = getConfigData();
  // Sentry Plugin
  // Note: needs to run before bootstrapping application
  const sentryEnvs = ['sit', 'uat', 'prod'];
  const validSentryEnvironment =
    data.sentry_dsn && data.sentry_env && sentryEnvs.includes(data.sentry_env);
  // eslint-disable-next-line no-console
  console.info(`MyCRM version: ${process.env.NPM_PACKAGE_VERSION}`);
  if (validSentryEnvironment) {
    Sentry.setUser({
      id: user.FamilyId,
      username: user.PreferredFullName,
      email: user.Email,
    });
  }
}

export function setUpSVGInjector() {
  // SVG Injection
  const svgElement = window.document.querySelectorAll('img.mycrm-svg');
  SVGInjector(svgElement);
}

export function setUpFeatureFlag() {
  const config = getConfigData();
  const feature = getFeaturesData();
  const activeSubscription = getActiveSubscriptionsKeyList();
  Object.assign(config, { feature, activeSubscription });
  setConfigData(config);
}

export function useApiResourceBasedOnLocation() {
  const config = getConfigData();
  const resource = getApiResourceBasedOnLocation() || config.resource;
  Object.assign(config, { resource });
  setConfigData(config);
}

const axiosGet = (url, token) => {
  const config = { method: 'GET', url, headers: { Authorization: token } };
  return axios(constructConfigRequest(config));
};

export function getUserInfo(token) {
  const data = getConfigData();

  const getUserInfo = axiosGet(`${data.resource}/GetUserInfo`, token);
  const getAdditionalInfo = axiosGet(
    `${data.resource}/user/gtm/on-login/general-attributes`,
    token,
  );

  return Promise.all([getUserInfo, getAdditionalInfo]).then((responses) => {
    const userInfo = responses.reduce((acc, cur) => {
      const data = get(cur, 'data', {});
      return { ...acc, ...data };
    }, {});
    return Promise.resolve(userInfo);
  });
}

export function getUserOrgInfo(token) {
  const data = getConfigData();
  const user = getUserInfoData();
  const requestConfig = {
    method: 'GET',
    url: `${data.resource}/corporate/AdviserOrganizationGet?adviserOrgComplianceId=0&adviserOrganizationId=${user.AdviserOrgID}`,
    headers: { Authorization: token },
  };
  return axios(constructConfigRequest(requestConfig));
}

export function getUserRoles(token) {
  const data = getConfigData();
  const user = getUserInfoData();
  const { ADMIN_ASSISTANT, ASSISTANT } = ACCESS_TYPE;
  const isAssistant = [ADMIN_ASSISTANT, ASSISTANT].includes(user.AccessType);
  const params = isAssistant ? { includeOrgMembers: true } : {};
  const requestConfig = {
    method: 'GET',
    url: `${data.resource}/user-roles`,
    params,
    headers: { Authorization: token },
  };
  return axios(constructConfigRequest(requestConfig));
}

export function getFeatures(token) {
  const data = getConfigData();
  const requestConfig = {
    method: 'GET',
    url: `${data.resource}/features-and-subscriptions`,
    headers: { Authorization: token },
  };
  return axios(constructConfigRequest(requestConfig));
}

const appendStyleToRemoveLiveChat = () => {
  const head = window.document.querySelectorAll('head')[0];
  const style = window.document.createElement('style');
  style.type = 'text/css';
  style.innerHTML =
    '.disable-live-chat div[data-test-id="ChatWidgetButton"]{display:none!important}';
  head.append(style);
};

export const liveChatConfig = () => {
  const user = getUserInfoData();
  const countryCode = getCountryCode(user.CountryId);
  if (!countryCode) {
    return;
  }
  if (!liveChat[countryCode]) {
    window.document.body.classList.add('disable-live-chat');
    appendStyleToRemoveLiveChat();
  } else {
    window.document.body.classList.remove('disable-live-chat');
  }
};

const saveTokenFromJWT = (query) => {
  const jwt = query.replace(/(jwt=|\?|#)/gi, '');
  const auth = decodeURIComponent(jwt);
  window.localStorage.setItem('myCRM_jwt', auth);
};

const saveTokenFromUserId = (query) => {
  const config = getConfigData();
  const userId = query.replace(/(userid=|\?|#)/gi, '');
  // check if the token is still valid
  const requestConfig = {
    method: 'GET',
    url: `${config.resource}/${userId}/token`,
  };
  axios(constructConfigRequest(requestConfig))
    .then((response) => {
      if (!response || !response.data || !response.data.Data) {
        return;
      }
      const token = `Bearer ${response.data.Data}`;
      window.localStorage.setItem('myCRM_jwt', token);
    })
    .catch((error) => console.error(error))
    .then(redirectToBaseUrl);
};

export function tokenPassByQuery() {
  const queryStrings = window.location.search.split('&');
  const isUserIdPassed =
    queryStrings.filter((query) => query.includes('userId')).length > 0;
  for (const element of queryStrings) {
    if (element.search('jwt=') !== -1) {
      saveTokenFromJWT(element);
    }

    if (element.search('userId=') !== -1) {
      saveTokenFromUserId(element);
    }
  }
  !isUserIdPassed && redirectToBaseUrl();
}

export function runTheme() {
  if (loanMarketValidation()) {
    angular.element('.app-body').addClass('app-lm');
  } else {
    angular.element('.app-body').addClass('app-default');
  }
}

export function gaBootstrap(gaAccount) {
  window.ga('create', gaAccount, 'auto');
}

export function gtmDataLayer() {
  const START_DATE_HOUR = 8;
  // Check if data layer is undefined
  if (typeof dataLayer === 'undefined') {
    return;
  }
  const userInfoData = getUserInfoData();
  const featureList = getPilotFeatureKeyList();
  const subscriptionList = getActiveSubscriptionsKeyList();
  const user =
    (userInfoData && userInfoWithAddedDetailsBuilderForUI(userInfoData)) || {};

  const data = {
    event: 'user_data',
    user_family_id: user.familyId || 0,
    user_country: user.countryCode || '',
    user_corporate_team: user.corporateTeam || '',
    user_adviser_org_id: user.userAdviserOrgId,
    user_is_assistant: user.isAdminAssistant || user.isAssistant,
    user_is_loan_writer: user.isLoanWriter,
    user_is_risk_writer: user.isRiskWriter,
    user_is_principal: user.isPrincipalAdviser,
    user_is_corporate: user.isCorporate,
    user_branding_category: user.brandingCategory || '',
    user_contract_entity_id: user.entityOrgId || '',
    user_contract_entity_name: user.entityName,
    broker_status_id: user.brokerStatusId || '',
    broker_status: BROKER_STATUS[user.brokerStatusId] || '',
    feature_flags: [...featureList],
    subscriptions: [...subscriptionList],
    mycrm_active_timestamp: user.myCRMActiveDate,
    rollout_custom_properties: mappedRolloutProperties(),
    business_unit_id: user.businessUnitId,
    business_unit_name: user.businessUnitName,
    reporting_state_name: user.reportingStateName,
    preferred_name: user.preferredName,
    advisor_org_name: user.advisorOrgName,
    r_r_chairman: user.hasRewardsRecognitionChairman,
    r_r_individual: user.familyAchievement,
    account_owner: user.accountOwner,
    reporting_region: user.isAU
      ? user.reportingStateName
      : user.reportingRegion,
    start_date: getTimestamp(user.startDate, START_DATE_HOUR),
    head_broker: user.headBroker,
  };
  // eslint-disable-next-line no-undef
  dataLayer.push(data);
}

export function setUpAppAndDependencies() {
  const config = getConfigData();
  angular.module('app').config([
    'configServiceProvider',
    function (configServiceProvider) {
      configServiceProvider.setConfig(config);
    },
  ]);
  liveChatConfig();
  setUpSVGInjector();
  setUpSentryUser();
  gtmDataLayer();
  bootstrapRun();
}

export function validateToken() {
  const token = getToken();
  const config = getConfigData();
  const requestConfig = {
    method: 'GET',
    url: `${config.resource}/CheckJWT?jwttype=mycrm`,
    headers: { Authorization: token },
  };
  axios(constructConfigRequest(requestConfig))
    .then(() => {
      setUpFeatureFlag();
      setUpAppAndDependencies();
    })
    .catch(() => {
      removeToken();
      window.localStorage.setItem('nextLocation', window.location.hash);
      window.location.assign(config.login);
    });
}

export async function bootstrapOktaAuthentication() {
  const data = getConfigFromEnv();
  const logger = getLogger('On Load');
  setConfigData(data);
  setUpSentry();
  let accessToken;
  try {
    accessToken = await getAccessToken();
    if (!accessToken) {
      const tokens = await fetchToken();
      setTokens(tokens);
      accessToken = await getAccessToken();
    }
    const token = `Bearer ${accessToken}`;
    setToken(token);
    Object.assign(data, {
      isLoanMarket: loanMarketValidation(),
      token,
    });

    setConfigData(data);
    const features = getFeatures(token);
    runDebug(data);
    const user = await getUserInfo(token);
    if (!user || !user.FamilyId) {
      logger.error('Status: FAILED, Cause: No Family Id');
      throw new Error('User does not have Family ID');
    }

    logger.info(
      `FamilyId: ${user.FamilyId}, Email: ${user.Email}, Status: SUCCESS`,
    );
    setUserInfoData(user);

    if (user.AdviserOrgID) {
      const {
        data: [orgData],
      } = await getUserOrgInfo(token);
      if (orgData) {
        const userData = {
          ...user,
          AdviserOrgName: orgData.AdviserOrganizationName,
        };
        setUserInfoData(userData);
      }
    }
    const roles = (await getUserRoles(token)).data;
    setUserInfoData(roles);

    const { Data } = (await features).data;
    setFeaturesData(Data.Features);
    setSubscriptionData(Data.Subscriptions);
    setUpFeatureFlag();
    setUpAppAndDependencies();
  } catch (error) {
    logger.error(`Status: FAILED, Cause: ${error}`);
    logOktaErrorToSentry(error, accessToken);

    if (isTimeError(error.message)) {
      logout(LOGOUT_CAUSES.timeSettings);
    } else if (!window.cookie3PCSupport) {
      logout(LOGOUT_CAUSES.cookieSettings);
    } else {
      const path =
        window.location.hash === '' || window.location.hash === '#/'
          ? ''
          : `?path=${encodeURIComponent(window.location.href)}`;
      window.location.assign(`${data.oktaLogin}${path}`);
    }
  }
}
