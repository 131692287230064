import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import dotProp from 'dot-prop-immutable';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { REFERRAL_TYPE } from 'Common/constants/referralType';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { adviserAllocateSwal } from 'Common/utilities/adviserAllocateSwal';
import {
  appendSupplierFixedData,
  formatAssetSupplierForDisplay,
} from 'Common/utilities/assetSupplier';
import { displayField } from 'Common/utilities/displayField';
import { toastError, toastWarning, toastSuccess } from 'Common/utilities/alert';
import { INVOLVED_PARTIES_TYPE } from 'Common/constants/partyTypes';
import {
  REFERRAL_ITEM,
  CLIENT_REFERRAL,
  ADDITIONAL_REFERRAL,
  REFERRED_BY_ACCESS,
} from 'Common/constants/enquirySource';
import { MAIN_STATUS_LENGTH } from 'Common/constants/mainLoanStatusLength';
import { EXTRA_STATUS } from 'Common/constants/extraLoanStatus';
import { typelessEqual } from 'Common/utilities/math';
import { LOAN_APP_STATUS_TYPES } from 'Common/constants/loanAppStatusTypes';
import { PIPELINE_TYPES_ID } from 'Common/constants/pipelineType';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import {
  LENDER_ID,
  LOAN_LENDING_CATEGORY,
} from 'Common/constants/lenderConstants';
import { getOptionName } from 'Common/utilities/options';
import {
  mapEnquiryTitleField,
  setReferralItemId,
  formatReferralItemId,
} from 'Common/utilities/referral';
import { setLoanAdviserNPSEnabled } from 'Common/utilities/adviser';
import {
  setDatesValue,
  updateNotProceedingDate,
  isImportedLoan,
  onChangeDate,
  onInit,
  setParamsLendingCategory,
  callSettlementAddressModal,
  sendNPSSurvey,
  getAssistantsList,
  formatSelectedAdviser,
  directAssistantUpdate,
  allocateAssistant,
  setLoanInfoLockUtil,
  updateRefNoOnlyLoanInfoUtil,
  showOriginalLoanWriter,
  getNPSSurveyToSave,
  formatImportantLoanDates,
} from './util/loanInformation';

import {
  alterListOFLoanTools,
  loanStatusList,
} from './util/loanInformationAddExistingCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanInformationCtrl', function LoanInformationCtrl(
      $q,
      $scope,
      contactService,
      imageDataURI,
      SweetAlert,
      $window,
      $timeout,
      $uibModalInstance,
      $uibModal,
      toaster,
      utilitiesService,
      colorService,
      loanScenarioService,
      $state,
      $rootScope,
      $stateParams,
      optionsService,
      $localStorage,
      contactSharedData,
      corporateService,
      apiRefactorService,
      loanInformationService,
      commonFnService,
      loanAppSharedData,
      enquirySourceService,
      referencedReferralService,
      modalRenderService,
      loanService,
      pipelineService,
      corporateModelService,
      currentUserService,
      contactModelService,
      loanScenarioModelService,
      configService,
      params,
      loanType,
      timeoutService,
      importantDatesService,
      mycrmLookupService,
      loanProfilerService,
      uiService,
    ) {
      $scope.isLoadingDetails = true;
      const {
        additionalLoanImportantDates,
        productDiscountRate,
        assetFinance,
        lendersByAccreditation,
      } = configService.feature;
      $scope.additionalLoanImportantDatesFlag = additionalLoanImportantDates;
      $scope.productDiscountRate = productDiscountRate;
      $scope.lenderOtherID = LENDER_ID.OTHERS;
      $scope.loanModalTab = 'loan-details';
      $scope.loanInformationService = loanInformationService;
      $scope.allocatedAdviserId = 0;
      $scope.isCommissionAccessible = true;
      $scope.isUserNZ = currentUserService.isNZ;
      $scope.isCorp = currentUserService.isCorporate;
      $scope.loanAllocation = {};
      $scope.Settings = {
        showEntities: false,
      };
      $scope.LOAN_LENDING_CATEGORY = LOAN_LENDING_CATEGORY;
      $scope.loanDetailsLoaded = false;
      $scope.onChangeDate = onChangeDate({ $scope, loanService });
      $scope.getAssistantsList = getAssistantsList({ $scope, optionsService });
      $scope.directAssistantUpdate = directAssistantUpdate({
        $scope,
        loanScenarioService,
      });
      $scope.allocatedAssistant = allocateAssistant({ $scope });
      $scope.updateRefNoOnlyLoanInfo = updateRefNoOnlyLoanInfoUtil(
        contactModelService,
      );
      $scope.isIncludeVaried = uiService.showTopUpLoanVariation;
      loanInformationService.isLoanInfoLock = false;
      const setLoanInfoLock = setLoanInfoLockUtil({
        loanScenarioService,
        currentUserService,
      });
      const isSkipUpdateDate = true;

      $scope.params = params;
      onInit({ $scope, optionsService });

      $scope.loanType = loanType;
      $scope.isImportedLoan = isImportedLoan;
      $scope.$watch(
        'entityList',
        (nv) => {
          $scope.Settings.showEntities = false;
          if (nv && nv.length > 0) {
            $scope.Settings.showEntities = true;
          }
        },
        true,
      );

      alterListOFLoanTools.forRiskWriters({
        $scope,
        expectedInScopeList: loanStatusList(),
        hasPropOf: 'LoanStatusID',
      });

      const enquirySourceParams = {
        familyId: $scope.params.familyId,
        loanId: $scope.params.loanId,
      };

      $scope.loanActionModal =
        $scope.params.loanInformationAction !== 'view'
          ? `${
              $scope.params.loanInformationAction.charAt(0).toUpperCase() +
              $scope.params.loanInformationAction.slice(1)
            } `
          : '';
      if ($scope.params.loanInformationAction === 'view') {
        $scope.isSetFieldValue = false;
      } else {
        $scope.isSetFieldValue = true;
        $scope.setLoanDetails = {
          LoanID: 0,
          IsEstimated: true,
          FamilyList: [
            {
              ReferrerAgreementTypeId: null,
              ReferrerID: null,
              ReferrerOrgID: null,
              ReferralCategoryId: null,
              ReferralItemId: null,
            },
          ],
          LoanStatus: {
            LoanStatusID: '1',
            LoanStatusCategory: 'Existing',
            LoanStatusName: 'Settled',
          },
          Lender: {},
          LoanAmount: '',
          SettlementDate: '',
          FinanceDate: '',
          SubmittedDate: '',
          ApprovalDate: '',
          ApprovalExpiry: '',
          PreApprovalExpiry: '',
          VariedDate: '',
          ReferenceNumber: '',
          Borrower: [],
          isReferral: true,
        };

        $scope.entityList = [];
        $scope.borrowerList = [];
        $scope.guarantorList = [];

        $scope.datePopUp = {
          variedDate: false,
          settlementDate: false,
          submittedDate: false,
          financeDate: false,
          approvalDate: false,
          approvalExpiry: false,
          preApprovalExpiry: false,
          signingDate: false,
        };
      }

      $scope.setLoanDetails.Borrower = $scope.entityList.concat(
        $scope.borrowerList,
      );
      $scope.selectedTab = '';
      $scope.setSelectedTab = function (tabName) {
        $scope.selectedTab = tabName;
      };

      $scope.setSelectedTab('loans');

      /**
       * For Feeling Stuck Popup
       * */

      $scope.hideInfoReferenceNumber = $localStorage.hideInfoReferenceNumber;

      $scope.closeInfoReferenceNumber = function () {
        $scope.hideInfoReferenceNumber = true;
        $localStorage.hideInfoReferenceNumber = true;
      };

      /**
       * Borrowers
       * */
      $scope.borrowerDefault = function () {
        contactService
          .familyInfoGet($scope.params.familyId)
          .then((response) => {
            const applicant = [];
            const entity = [];
            let clientHeaderName = '';
            _.each(response.data, (valueParam) => {
              let value = valueParam;
              if (value.Role === 'Adult' || value.Role === '0') {
                value = commonFnService.processBorrowerNames(value);
                value.BorrowerID = value.PersonId;
                value.IsInclude = true;
                value.IsEntity = value.isEntity === true;
                if (value.isEntity) {
                  entity.push(value);
                  clientHeaderName += `${value.FullName}`;
                } else {
                  applicant.push(value);
                  if (!$scope.clientHeaderName) {
                    if (!clientHeaderName) {
                      clientHeaderName += `${value.FirstName} ${value.LastName}`;
                    } else {
                      clientHeaderName += ` & ${value.FirstName} ${value.LastName}`;
                    }
                  }
                }
              }
            });
            !$scope.clientHeaderName &&
              ($scope.clientHeaderName = clientHeaderName);
            $scope.borrowerList = applicant;
            $scope.entityList = entity;
            $scope.borrowersLoaded = true;
            $scope.loanDetailsLoaded = true;
          });
      };

      $scope.getBorrowersList = function () {
        const tempIsIncludeGuarantor = true;
        $scope.borrowersLoaded = false;
        contactService
          .borrowersDetailsGet(
            $scope.params.familyId,
            $scope.params.loanId,
            tempIsIncludeGuarantor,
          )
          .then((respond) => {
            const entityList = [];
            const borrowerList = [];
            const guarantorList = [];
            const borrowerIDList = [];
            $scope.listBorrowerValues = [];
            _.each(respond.data, (value) => {
              if (!borrowerIDList.includes(value.BorrowerID)) {
                borrowerIDList.push(value.BorrowerID);
                $scope.listBorrowerValues.push(value);
              }
            });
            _.each($scope.listBorrowerValues, (valueParam) => {
              let value = valueParam;
              value = commonFnService.processBorrowerNames(value);
              const includeEntityThatAreNotGuarantor =
                value.IsEntity && !value.IncludeGuarantor;
              if (includeEntityThatAreNotGuarantor) {
                entityList.push(value);
              } else if (value.IncludeGuarantor) {
                guarantorList.push(value);
              } else {
                borrowerList.push(value);
              }
            });
            $scope.entityList = entityList;
            $scope.borrowerList = borrowerList;
            loanAppSharedData.borrowerList = $scope.borrowerList;
            $scope.guarantorList = guarantorList;
            $scope.borrowersLoaded = true;
          });
      };

      $scope.getAssetSuppliersList = () => {
        loanScenarioService
          .scenarioApplicantListGet($scope.params.loanScenarioID)
          .then(({ data }) => {
            if (!data) {
              return;
            }

            $scope.assetSuppliersList = data.RelatedParty.filter(
              (obj) => obj.IsAssetSupplier,
            ).map(commonFnService.processBorrowerNames);
          });
      };

      if ($scope.params.loanInformationAction !== 'create') {
        $scope.getBorrowersList();
      } else {
        $scope.borrowerDefault();
      }

      /**
       * Involved Parties - Add Applicant
       * */
      $scope.searchApplicant = false;
      $scope.addApplicant = function () {
        $scope.searchApplicant = !$scope.searchApplicant;
        $scope.searchGuarantor = false;
        $scope.searchEntity = false;
        $scope.searchSupplier = false;
      };

      $scope.searchGuarantor = false;
      $scope.addGuarantor = function () {
        $scope.searchGuarantor = !$scope.searchGuarantor;
        $scope.searchApplicant = false;
        $scope.searchEntity = false;
        $scope.searchSupplier = false;
      };

      $scope.searchEntity = false;
      $scope.addEntity = function () {
        $scope.searchEntity = !$scope.searchEntity;
        $scope.searchApplicant = false;
        $scope.searchGuarantor = false;
        $scope.searchSupplier = false;
      };

      $scope.searchSupplier = false;
      $scope.addAssetSupplier = function () {
        $scope.searchSupplier = !$scope.searchSupplier;
        $scope.searchApplicant = false;
        $scope.searchGuarantor = false;
        $scope.searchEntity = false;
      };

      const getClient = (searchString, searchType) => {
        $timeout.cancel($scope.searchTimeOut);
        $scope.searchTimeOut = $timeout(() => {
          loanScenarioService
            .scenarioSearchClientGet(
              searchString,
              searchType,
              null,
              $scope.params.loanId,
            )
            .then((response) => {
              const { data } = response;
              $scope.getClientList = data.filter((client) => {
                if (client.FirstName) {
                  client.FullName = client.LastName
                    ? `${client.FirstName} ${client.LastName}`
                    : client.FirstName;
                }
                return client;
              });
            });
        }, 1000);
      };

      $scope.searchClient = function (searchString, partyType) {
        if (searchString && searchString.length > 2) {
          $scope.getClientList = null;
          let type = 0;
          switch (partyType) {
            case 'guarantor':
              type = INVOLVED_PARTIES_TYPE.CLIENT_ENTITY;
              break;
            case 'applicant':
            case 'applicants':
            case 'assetSupplier':
              type = INVOLVED_PARTIES_TYPE.CLIENT;
              break;
            case 'entity':
              type = INVOLVED_PARTIES_TYPE.ENTITY;
              break;
            default:
              break;
          }
          getClient(searchString, type);
        }
      };

      const setAdditionalBorrowerInfo = (borrower) => {
        const { LastName: lastName, FirstName: firstName } = borrower;
        const initialValue = lastName ? [firstName, lastName] : [firstName];
        const initials = utilitiesService.filterInitial(...initialValue);
        const displayName = initialValue.join(' ');
        const background = colorService.getRandomColor();
        return { ...borrower, initials, DisplayName: displayName, background };
      };

      $scope.selectClient = function (clientInfo, partyType) {
        $scope.selectedClient = clientInfo;
        // eslint-disable-next-line sonarjs/prefer-object-literal
        const newBorrrower = {};
        newBorrrower.BorrowerID = parseInt(clientInfo.ClientEntityId, 10);
        newBorrrower.FirstName = clientInfo.FirstName;
        newBorrrower.LastName = clientInfo.LastName;
        newBorrrower.IsInclude = true;
        newBorrrower.IsEntity = partyType !== 1;

        const setBorrower = setAdditionalBorrowerInfo(newBorrrower);
        if (partyType === 1) {
          $scope.borrowerList.push(setBorrower);
        } else if (partyType === 2) {
          $scope.entityList.push(setBorrower);
        }
        loanAppSharedData.borrowerList = $scope.borrowerList;

        if (parseInt($scope.params.loanId, 10) !== 0) {
          $scope.saveLoanData();
        } else {
          toaster.pop(
            'warning',
            'Warning!',
            'You need to select Lender at Loan Details section',
          );
        }
      };

      $scope.clearSearch = () => {
        $scope.getClientList = [];
        $scope.selectedClientName = '';
        $scope.showApplicantList = false;
      };

      $scope.selectAssetSupplier = (client) => {
        $scope.searchSupplier = false;
        $scope.clearSearch();

        const supplier = commonFnService.processBorrowerNames(client);
        const supplierSet = appendSupplierFixedData(
          $scope.params.loanScenarioID,
          supplier,
        );

        loanScenarioService.scenarioApplicantSet(supplierSet).then(
          () => {
            $scope.assetSuppliersList = $scope.assetSuppliersList.concat(
              formatAssetSupplierForDisplay(supplierSet),
            );
          },
          () => {
            toastError('Supplier was not added, please try again.');
          },
        );
      };

      $scope.removeAssetSupplier = (supplier) => {
        if (supplier.FamilyId) {
          loanScenarioService
            .scenarioClientDelete(
              $scope.params.loanScenarioID,
              supplier.PersonId,
              true,
            )
            .then(() => {
              $scope.assetSuppliersList = $scope.assetSuppliersList.filter(
                (element) => element.FamilyId !== supplier.FamilyId,
              );
            });
        } else {
          loanScenarioModelService
            .deleteAssetSupplier(
              $scope.params.loanScenarioID,
              supplier.AssetSupplierId,
            )
            .then(() => {
              $scope.assetSuppliersList = $scope.assetSuppliersList.filter(
                (element) =>
                  element.AssetSupplierId !== supplier.AssetSupplierId,
              );
            });
        }
      };

      $scope.selectGuarantor = function (clientInfo) {
        clientInfo.IsGuarantor = true;
        clientInfo.IsAddKid = false;
        clientInfo.IsApplicant = false;
        clientInfo.IsSolicitor = false;
        clientInfo.IsAccountant = false;

        // eslint-disable-next-line sonarjs/prefer-object-literal
        const newBorrrower = {};
        newBorrrower.BorrowerID = clientInfo.ClientEntityId;
        newBorrrower.FirstName = clientInfo.FirstName;
        newBorrrower.LastName = clientInfo.LastName;
        newBorrrower.IsInclude = true;

        newBorrrower.IncludeGuarantor = 1;
        $scope.guarantorList.push(setAdditionalBorrowerInfo(newBorrrower));

        loanScenarioService
          .contactLoanClientSet(
            $scope.params.loanId,
            clientInfo.ClientEntityId,
            clientInfo.IsClient,
            clientInfo.IsGuarantor,
            clientInfo.IsAddKid,
            clientInfo.IsApplicant,
            clientInfo.IsSolicitor,
            clientInfo.IsAccountant,
          )
          .then(() => {
            toaster.pop('success', 'Success!', 'Guarantor successfully added.');
          });
      };

      $scope.cancelSearch = function () {
        $scope.searchApplicant = !$scope.searchApplicant;
      };

      $scope.removeBorrowerApplicant = (borrower, valueIndex, partyType) => {
        const applicants = {
          guarantors: $scope.guarantorList,
          entities: $scope.entityList,
          borrowers: $scope.borrowerList,
        };
        loanService
          .removeBorrowerApplicant(applicants, valueIndex, partyType)
          .then(() => {
            $scope.saveLoanData();
          })
          .catch(() => {
            toaster.pop('warning', 'Error', 'You need at least 1 applicant');
          });
      };

      $scope.contactInfoSetValue = {};

      $scope.getEnquirySourceList = () => {
        enquirySourceService
          .getEnquirySourceList({}, enquirySourceParams)
          .then((enquirySourceList) => {
            $scope.EnquirySourceList = enquirySourceList;
            if ($scope.params.loanInformationAction !== 'create') {
              $scope.loanDataField();
            }
            if ($scope.params.loanId) {
              return;
            }
            $scope.getFamEnquirySource($scope.EnquirySourceList).then((res) => {
              $scope.processEnquirySource(res.ReferralCategoryId);
              $scope.contactInfoSetValue.EnquirySource = res;
            });
          });
      };

      $scope.$on('$destroy', () => {
        timeoutService.cancelTimeouts('LoanInformationCtrl');
      });

      $scope.getEnquirySourceList();
      $scope.referredByHelper = {
        showClientList: false,
        loopCount: 0,
        getReferralOrgList() {
          if (
            $scope.referredByHelper &&
            $scope.referredByHelper.loopCount < 1
          ) {
            contactService
              .contactFamilyInfoGet($scope.params.familyId)
              .then((response) => {
                if (response.data) {
                  const brokerId = response.data.BrokerID || 0;
                  if (brokerId > 0) {
                    referencedReferralService.getReferencedReferralList(
                      brokerId,
                      $scope,
                    );
                  }
                }
              });
          }
        },
        searchClient() {
          $scope.clientNotFound = false;
          $scope.referredByHelper.showClientList = true;
          $scope.referredByHelper.getReferralOrgList();
        },
        selectClient(client) {
          const referrer = {
            ReferrerAgreementTypeId: client.AgreementTypeId,
            ReferrerOrgName: client.ReferralOrganizationName,
            ReferrerOrgId: client.ReferralOrganizationId,
            ReferrerOrgID: client.ReferralOrganizationId,
            ReferrerId: client.ReferralMemberId,
            ReferrerID: client.ReferralMemberId,
            ReferrerName: client.ReferralMemberName,
            ReferrerNameLabel: `${client.ReferralMemberName} - ${client.ReferralOrganizationName}`,
          };
          $scope.contactInfoSetValue = {
            ...$scope.contactInfoSetValue,
            ...referrer,
          };
          $scope.contactInfoSetValue.EnquirySource = {
            ...$scope.contactInfoSetValue.EnquirySource,
            ...referrer,
          };
          $scope.setLoanDetails = { ...$scope.setLoanDetails, ...referrer };
          $scope.setLoanDetails.FamilyList = [{ ...referrer }];
          $scope.referredByHelper.showClientList = false;
          $scope.saveLoanData();
        },
      };

      $scope.enquiryChanged = (isEnquiryChange) => {
        const enquirySource = $scope.contactInfoSetValue.EnquirySource;

        if (enquirySource) {
          const isReferral = $scope.displayField(
            enquirySource.ReferralItemId,
            $scope.referredByAccessList,
          );
          if (
            isEnquiryChange ||
            (!isReferral &&
              enquirySource.ReferralCategoryId !== REFERRAL_ITEM.REFERRAL)
          ) {
            enquirySource.ReferrerAgreementTypeId = 0;
            enquirySource.ReferrerID = 0;
            enquirySource.ReferrerName = '';
            enquirySource.ReferrerNameLabel = '';
            enquirySource.ReferrerOrgID = 0;
            enquirySource.ReferrerOrgName = '';
          }
          $scope.contactInfoSetValue.ReferralCategory =
            enquirySource.ReferralCategoryName;
          $scope.contactInfoSetValue.ReferralCategoryId =
            enquirySource.ReferralCategoryId;
          $scope.contactInfoSetValue.ReferralItem =
            enquirySource.ReferralItemName;

          $scope.contactInfoSetValue.ReferralItemId =
            enquirySource.ReferralItemId;
          $scope.contactInfoSetValue.ReferrerNameLabel =
            enquirySource.ReferrerNameLabel;
          $scope.setLoanDetails = {
            ...$scope.setLoanDetails,
            ...enquirySource,
            ReferralItemId: setReferralItemId(enquirySource),
          };
          $scope.setLoanDetails.FamilyList[0] = {
            ...$scope.setLoanDetails.FamilyList[0],
            ...enquirySource,
            ReferralItemId: setReferralItemId(enquirySource),
          };
        }
      };

      $scope.openDate = function (dateField) {
        $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
      };
      $scope.format = 'dd MMM yyyy';
      $scope.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
      $scope.dateOptions = {
        formatYear: 'yy',
        showWeeks: false,
      };

      const assignEnquirySource = (referralCategoryId) => {
        corporateModelService
          .isFirstFamilyLoan($scope.params.familyId, $scope.params.loanId)
          .then((response) => {
            if (!response || !response.data) {
              return;
            }
            return $scope.getFamEnquirySource();
          })
          .then((res) => {
            if (!res) {
              return;
            }
            $scope.processEnquirySource(res.ReferralCategoryId);
            $scope.contactInfoSetValue.EnquirySource = res;
            $scope.enquirySourceInfoRaw = { ...res };
            const isReferral = $scope.displayField(
              res.ReferralItemId,
              $scope.referredByAccessList,
            );
            if (
              $scope.params.loanInformationAction === 'create' &&
              isReferral
            ) {
              const referrerName = res.ReferrerName && res.ReferrerName.trim();
              const referrerOrgName =
                res.ReferrerOrgName && res.ReferrerOrgName.trim();
              if (referrerName && referrerOrgName) {
                res.ReferrerNameLabel = `${referrerName} - ${referrerOrgName}`;
              } else {
                $scope.isReferrerRequired = true;
              }
              $scope.contactInfoSetValue = {
                ...$scope.contactInfoSetValue,
                ...res,
              };
            }
          })
          .catch(() => {
            if (!referralCategoryId) {
              return;
            }
            $scope.processEnquirySource(referralCategoryId);
          });
      };

      $scope.loanDataField = function () {
        $scope.loanDetailsLoaded = false;
        contactService.loanDetailsGet($scope.params.loanId).then((respond) => {
          const loanDetails = respond.data;
          $scope.loanDetails = loanDetails;
          $scope.isLoadingDetails = false;
          if (
            loanDetails &&
            loanDetails.loan &&
            loanDetails.loan.OwnedByAdviserID
          ) {
            $scope.allocatedAdviserId = loanDetails.loan.OwnedByAdviserID;
            $scope.loanAllocation.adviserId = loanDetails.loan.OwnedByAdviserID;
            loanInformationService.settlementDate =
              loanDetails.loan.SettlementDate;
            $scope.getAssistantsList($scope.allocatedAdviserId);
            setLoanAdviserNPSEnabled({
              $scope,
              corporateService,
              adviserId: $scope.allocatedAdviserId,
              currentUserService,
            });
          }
          if ($scope.params.loanInformationAction === 'view') {
            imageDataURI(
              `/assets/images/company/${loanDetails.loan.Lender.LenderName.toLowerCase().replace(
                / /g,
                '_',
              )}.png`,
              (dataUri) => {
                loanDetails.loan.Lender.Logo = dataUri;
              },
            );
          } else if ($scope.params.loanInformationAction === 'edit') {
            $scope.params.loanInformation = angular.copy(loanDetails.loan);
            $scope.loanLender = $scope.params.loanInformation.Lender;

            const lendingCategory =
              $scope.params.loanInformation &&
              $scope.params.loanInformation.LendingCategoryId;
            $scope.isAssetFinance =
              assetFinance &&
              lendingCategory === LENDING_CATEGORY.ASSET_FINANCE;
            if ($scope.isAssetFinance) {
              $scope.getAssetSuppliersList();
            }

            loanInformationService.loanInformation = loanDetails.loan;
            const loanStatusId = parseInt(
              isValidObjectValue(
                () =>
                  loanInformationService.loanInformation.LoanStatus
                    .LoanStatusID,
              ) || 0,
              10,
            );
            const modifyLoanInformationService = setLoanInfoLock({
              familyId: $scope.params.familyId,
              loanStatusId,
              loanId: $scope.params.loanId,
            });
            modifyLoanInformationService(loanInformationService);
            if ($scope.crmCountry === 'Australia') {
              $scope.params.loanInformation.CountryCode = 'AU';
            } else if ($scope.isUserNZ) {
              $scope.params.loanInformation.CountryCode = 'NZ';
            } else if ($scope.crmCountry === 'Indonesia') {
              $scope.params.loanInformation.CountryCode = 'ID';
            } else {
              $scope.params.loanInformation.CountryCode = '';
            }

            /**
             * Null and TRUE  = Estimated Date
             * FALSE = Actual Date
             * */
            if (
              _.isUndefined(loanDetails.loan.IsEstimated) ||
              loanDetails.loan.IsEstimated
            ) {
              $scope.setLoanDetails.IsEstimated = true;
            } else {
              $scope.setLoanDetails.IsEstimated = false;
            }

            const DEFAULT_SELECTED_CATEGORY_ID = 1;

            $scope.setLoanDetails.LoanID = $scope.params.loanId;
            $scope.setLoanDetails.LoanAmount = loanDetails.TotalLoanAmount;
            $scope.setLoanDetails.LoanStatus = loanDetails.loan.LoanStatus;
            $scope.setLoanDetails.Lender = loanDetails.loan.Lender;
            $scope.setLoanDetails.IsNPSSurvey = loanDetails.loan.IsNPSSurvey;
            $scope.setLoanDetails.OtherLenderName =
              loanDetails.loan.Lender.OtherLenderName;
            $scope.setLoanDetails.Lender.LenderId = parseInt(
              loanDetails.loan.Lender.LenderId,
              10,
            );
            $scope.setLoanDetails.AdviserName =
              loanDetails.loan.OwnedByAdviserName;
            $scope.setLoanDetails.LendingCategoryId =
              loanDetails.loan.LendingCategoryId ||
              DEFAULT_SELECTED_CATEGORY_ID;
            $scope.setLoanDetails.IsLoanFromOtherAggregator =
              loanDetails.loan.IsLoanFromOtherAggregator;
            const asstId =
              loanDetails && dotProp.get(loanDetails, 'loan.AssistantFamilyID');
            $scope.setLoanDetails.AssistantFamilyID =
              asstId && asstId.toString();
            $scope.setLoanDetails.AssistantName = getOptionName(
              $scope.assistantsList,
              loanDetails.loan.AssistantFamilyID,
            );
            lenderListGet();
            if (!_.isUndefined(loanDetails.loan.SigningDate)) {
              $scope.setLoanDetails.SigningDate = new Date(
                loanDetails.loan.SigningDate,
              );
            }

            if (!_.isUndefined(loanDetails.loan.SettlementDate)) {
              $scope.setLoanDetails.SettlementDate = new Date(
                loanDetails.loan.SettlementDate,
              );
            }

            if (!_.isUndefined(loanDetails.loan.FinanceDate)) {
              $scope.setLoanDetails.FinanceDate = new Date(
                loanDetails.loan.FinanceDate,
              );
            }

            if (!_.isUndefined(loanDetails.loan.SubmittedDate)) {
              $scope.setLoanDetails.SubmittedDate = new Date(
                loanDetails.loan.SubmittedDate,
              );
            }

            if (!_.isUndefined(loanDetails.loan.ApprovalDate)) {
              $scope.setLoanDetails.ApprovalDate = new Date(
                loanDetails.loan.ApprovalDate,
              );
            }

            if (!_.isUndefined(loanDetails.loan.ApprovalExpiry)) {
              $scope.setLoanDetails.ApprovalExpiry = new Date(
                loanDetails.loan.ApprovalExpiry,
              );
            }

            if (!_.isUndefined(loanDetails.loan.PreApprovalExpiry)) {
              $scope.setLoanDetails.PreApprovalExpiry = new Date(
                loanDetails.loan.PreApprovalExpiry,
              );
            }
            setDatesValue({ $scope, loanDetails });

            if (
              loanDetails.loan.ReferrerId &&
              $scope.setLoanDetails.FamilyList &&
              $scope.setLoanDetails.FamilyList.length
            ) {
              $scope.setLoanDetails.FamilyList[0].ReferrerID =
                loanDetails.loan.ReferrerId;
            }

            if (
              loanDetails.loan.ReferrerOrgId &&
              $scope.setLoanDetails.FamilyList &&
              $scope.setLoanDetails.FamilyList.length
            ) {
              $scope.setLoanDetails.FamilyList[0].ReferrerOrgID =
                loanDetails.loan.ReferrerOrgId;
            }

            if (
              loanDetails.loan.ReferralCategoryId &&
              $scope.setLoanDetails.FamilyList &&
              $scope.setLoanDetails.FamilyList.length
            ) {
              $scope.setLoanDetails.FamilyList[0].ReferralCategoryId =
                loanDetails.loan.ReferralCategoryId;
            }

            if (!_.isUndefined(loanDetails.loan.OwnedByAdviserID)) {
              $scope.setLoanDetails.OwnedByAdviserID =
                loanDetails.loan.OwnedByAdviserID;
            }

            if (!_.isUndefined(loanDetails.loan.OwnedByAdviserName)) {
              $scope.setLoanDetails.OwnedByAdviserName =
                loanDetails.loan.OwnedByAdviserName;
            }

            if (
              loanDetails.loan.ReferralItemId ||
              loanDetails.loan.ReferralItemId === 0
            ) {
              $scope.contactInfoSetValue.ReferralItemId =
                loanDetails.loan.ReferralItemId;

              optionsService
                .getReferencedReferralList(loanDetails.loan.OwnedByAdviserID)
                .then((response) => {
                  const clientList = response.data;
                  $scope.getClientList = [];
                  _.each(clientList, (val) => {
                    if (
                      val.ReferralOrganizationName !== '' &&
                      val.ReferralMemberName !== '' &&
                      val.ReferralMemberId !== 0
                    ) {
                      $scope.getClientList.push(val);
                    }
                  });

                  $scope.ReferralOrgList = $scope.getClientList;
                  const {
                    ReferrerAgreementTypeId,
                    ReferrerOrgName,
                    ReferrerOrgId: ReferrerOrgID,
                    ReferrerId: ReferrerID,
                    ReferrerName,
                  } = loanDetails.loan;
                  const referralDetails = {
                    ReferrerAgreementTypeId,
                    ReferrerOrgName,
                    ReferrerOrgID,
                    ReferrerID,
                    ReferrerName,
                    ReferrerNameLabel:
                      ReferrerName && ReferrerOrgName
                        ? `${ReferrerName} - ${ReferrerOrgName}`
                        : ``,
                  };
                  $scope.contactInfoSetValue.EnquirySource = {
                    ...$scope.contactInfoSetValue.EnquirySource,
                    ...referralDetails,
                  };
                  $scope.contactInfoSetValue = {
                    ...$scope.contactInfoSetValue,
                    ...referralDetails,
                  };
                  $scope.isReferrerRequired =
                    $scope.displayField(
                      $scope.contactInfoSetValue.EnquirySource.ReferralItemId,
                      $scope.referredByAccessList,
                    ) && !$scope.contactInfoSetValue.ReferrerNameLabel;
                  $scope.enquirySourceInfoRaw = {
                    ...$scope.contactInfoSetValue.EnquirySource,
                  };
                });
              enquirySourceService
                .getEnquirySourceList($scope, enquirySourceParams)
                .then(() => {
                  const loanReferralItemId = formatReferralItemId(
                    loanDetails.loan,
                  );
                  $scope.contactInfoSetValue.EnquirySource = _.find(
                    $scope.EnquirySourceList,
                    (obj) => obj.ReferralItemId === loanReferralItemId,
                  );
                });
            } else {
              assignEnquirySource(loanDetails.loan.ReferralCategoryId);
            }

            $scope.setLoanDetails.ReferenceNumber =
              loanDetails.loan.ReferenceNumber;
            $scope.setLoanDetails.Lender = loanDetails.loan.Lender;
            $scope.setLoanDetails.Lender.LenderId = parseInt(
              loanDetails.loan.Lender.LenderId,
              10,
            );

            $scope.getProductlist($scope.setLoanDetails.Lender);
          }
          $scope.loanDetails = loanDetails;
          $scope.loanDetailsLoaded = true;
          const loan = loanDetails.loan;
          if (!loan) {
            return;
          }
          $scope.contactInfoSetValue.OtherContactReferrerName =
            loan.OtherContactReferrerName || '';
          if (!loan.ClientReferrerFamilyId || !loan.ClientReferrerName) {
            return;
          }
          const FirstName = loan.ReferrerFirstName || '';
          const LastName = loan.ReferrerLastName || '';
          const PreferedName = `${FirstName} ${LastName}`;
          $scope.contactInfoSetValue.initialClient = {
            FamilyID: loan.ClientReferrerFamilyId,
            FamilyFullName: loan.ClientReferrerName,
            PreferedName,
            ClientEntityId: loan.ReferrerClientID,
          };
          $scope.referrerClientId = loan.ReferrerClientID;
          $scope.clientReferrerFamilyId = loan.ClientReferrerFamilyId;
        });
      };

      $scope.lenderList = [];
      $scope.listViewAdviserInput = true;
      const lenderPlace = 2;
      $scope.setLoanDetails.Lender = $scope.params.Lender;
      if ($scope.params.Lender && $scope.params.Lender.LenderId) {
        $scope.setLoanDetails.Lender.Lender = parseInt(
          $scope.params.Lender.LenderId,
          10,
        );
      }
      const lenderListGet = () => {
        contactService
          .lenderListGet(
            true,
            lenderPlace,
            lendersByAccreditation,
            $scope.allocatedAdviserId,
          )
          .then((respond) => {
            _.each(respond.data, (value) => {
              $scope.lenderList.push({
                LenderId: parseInt(value.ProviderId, 10),
                LenderName: value.ProviderName,
                Category: value.Category,
              });
            });
            if (lendersByAccreditation) {
              const selectedLenderId = _.get(
                $scope,
                'setLoanDetails.Lender.LenderId',
                0,
              );
              const isSelectedLenderExist = $scope.lenderList.some(
                (lender) => lender.LenderId === selectedLenderId,
              );
              !isSelectedLenderExist &&
                ($scope.lenderList = [
                  $scope.setLoanDetails.Lender,
                  ...$scope.lenderList,
                ]);
            }
            $scope.loanInformationService.lenderList = _.clone(
              $scope.lenderList,
            );
          });
      };

      const getLendingCategories = () => {
        optionsService.getLendingCategories().then(
          (response) => {
            $scope.lendingCategories = response;
          },
          () => {
            $scope.lendingCategories = [];
          },
        );
      };
      getLendingCategories();

      $scope.cancel = function () {
        const loanStatusId =
          ($scope.setLoanDetails &&
            $scope.setLoanDetails.LoanStatus &&
            $scope.setLoanDetails.LoanStatus.LoanStatusID) ||
          0;
        $uibModalInstance.close({
          loanStatusId,
          originalLoanStatusId: $scope.originalLoanStatusId,
        });
        $stateParams.activeTab = '';
        loanInformationService.commissionEnquiryIsOpen = false;
      };

      /**
       * Loan Structure
       * */
      $scope.loanStructureModal = function (action, structureId) {
        $scope.params.structureId = structureId;
        $scope.params.InterestRateTerm =
          $scope.loanDetails.loan.LoanStructureList[0].InterestRateTerm;
        $scope.params.InterestRateType =
          $scope.loanDetails.loan.LoanStructureList[0].InterestRateType;
        $uibModal.open({
          templateUrl:
            '/assets/views/contacts/partials/loan-modal/loan_structure_modal.html',
          controller: 'LoanStructureCtrl',
          scope: $scope,
          size: 'wide',
        });
      };

      /**
       * Security Structure
       * */
      $scope.securityStructureLoader = true;

      $scope.securityDataField = function () {
        $scope.securityStructureLoader = true;

        contactService
          .securityListGet($scope.params.loanId)
          .then((response) => {
            $scope.securityList = response.data;

            $scope.securityStructureLoader = false;

            if ($scope.securityList.length > 0) {
              $rootScope.$emit('cancelAddSecurity', false);
            } else {
              $rootScope.$emit('cancelAddSecurity', true);
            }
          });
      };

      $scope.addSecurityFunct = () => {
        $scope.addSecurity = true;
      };

      $rootScope.$on('cancelAddSecurity', (event, value) => {
        $scope.addSecurity = value;
        $scope.isEditSecurity = value;
        $scope.clearSecurityStructure();
      });

      $scope.clearSecurityStructure = () => {
        contactSharedData.lendingSharedData.secCtrlEditSecurityStructure = {};
      };

      $scope.editSecurityStructure = function (value) {
        contactSharedData.lendingSharedData.secCtrlEditSecurityStructure = value;
        contactSharedData.isEditSecurity = true;
        contactSharedData.securityZoningId = value.PropertyDetails.ZoningId;
        $scope.isEditSecurity = contactSharedData.isEditSecurity;
      };

      $scope.deleteSecurityStructure = function (value) {
        loanScenarioService
          .contactLoanClientDelete(value.SecurityInfoId)
          .then((response) => {
            if (response.status) {
              toaster.pop('success', 'Security structure successfully deleted');
            }
            $scope.securityDataField();
            /**
             *  reload the page that contains security list
             *  */
          });
      };

      /**
       *  call this part when you were outside of security main page's scope
       *  */
      $rootScope.$on('reloadSecutiyListGrid', () => {
        $scope.securityDataField();
      });
      const getCommissionAccess = () => {
        contactModelService
          .getBrokerCommissionAccess()
          .then((accessResponse) => {
            if (!accessResponse || !accessResponse.data) {
              return;
            }
            const { data } = accessResponse;
            const hasCommissionAccess = !!data.find(
              (item) => item.FamilyID === currentUserService.familyId,
            );
            $scope.isCommissionAccessible = data.length && hasCommissionAccess;
          });
      };
      $scope.getLoanStatusDefault = () => {
        if ($scope.params.loanId > 0) {
          contactService
            .loanDetailsGet($scope.params.loanId)
            .then((respond) => {
              if (!respond || !respond.data) {
                return;
              }
              const { accessType } = currentUserService;
              const { loan } = respond.data;
              $scope.setLoanDetails.LoanStatus = loan.LoanStatus;
              $scope.originalLoanStatusId = _.get(
                loan,
                'LoanStatus.LoanStatusID',
              );
              $scope.LoanStatusDefault = loan.LoanStatus;
              $scope.StatusCategoryName = loan.StatusCategoryName;
              showOriginalLoanWriter({
                configService,
                $scope,
                loan,
              });
              sendNPSSurvey({ $scope, currentUserService });
              if (
                ACCESS_TYPE.ADMIN_ASSISTANT === accessType ||
                ACCESS_TYPE.ASSISTANT === accessType
              ) {
                // eslint-disable-next-line sonarjs/no-extra-arguments
                getCommissionAccess(loan.OwnedByAdviserID);
              }
            });
        } else {
          if ($scope.loanType && $scope.loanType === 'inProgress') {
            $scope.setLoanDetails.LoanStatus = {
              LoanStatusID: LOAN_STATUS.IN_PROGRESS.NEW_APPLICATION.toString(),
              LoanStatusName: 'New Application',
            };
          } else {
            $scope.setLoanDetails.LoanStatus = {
              LoanStatusID: LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH.toString(),
              LoanStatusName: 'Not Proceeded With',
            };
          }
          sendNPSSurvey({ $scope, currentUserService });
          $scope.LoanStatusDefault = $scope.setLoanDetails.LoanStatus;
        }
      };

      const setLoanOrSetStatus = (isChangedLoanStatus = false) => {
        if (!isChangedLoanStatus) {
          const payload = formatImportantLoanDates($scope.setLoanDetails);
          return contactService.loanSet(payload).then(({ data }) => {
            $scope.setLoanDetails.LoanID = data;
            $scope.params.loanId = data;
            setParamsLendingCategory({ $scope });
            $scope.loanLender = $scope.lenderList.find(
              (elem) => elem.LenderId === $scope.setLoanDetails.Lender.LenderId,
            );

            $scope.isAssetFinance =
              assetFinance &&
              $scope.setLoanDetails.LendingCategoryId ===
                LENDING_CATEGORY.ASSET_FINANCE;

            const successText =
              $scope.params.loanInformationAction === 'edit'
                ? 'updated'
                : 'created';
            toastSuccess(`Loan has been successfully ${successText}.`);
            $scope.$parent.getLoanListGet &&
              typeof $scope.$parent.getLoanListGet === 'function' &&
              $scope.$parent.getLoanListGet();
          });
        }

        return contactService
          .LoanStatusSet(
            $scope.setLoanDetails.LoanID,
            $scope.setLoanDetails.LoanStatus.LoanStatusID,
            false,
            '',
            isSkipUpdateDate,
          )
          .then(() => {
            if ($scope.params.loanInformationAction === 'edit') {
              toastSuccess(`Loan has been successfully updated.`);
              typeof $scope.$parent.getLoanListGet === 'function' &&
                $scope.$parent.getLoanListGet();
            }
          });
      };
      $scope.securityDataField();
      $scope.getLoanStatusDefault();
      $scope.updateImportantDates = (isEnquiry, isChangedLoanStatus) => {
        $scope.changeStatusDelay = $timeout(() => {
          if ($scope.setLoanDetails.isInvalidForm && isChangedLoanStatus) {
            toastError(
              'Updated Loan Status will not save until mandatory important dates are entered',
            );
            return;
          }
          if ($scope.setLoanDetails.isInvalidForm) {
            return;
          }
          $scope.saveLoanData(isEnquiry, isChangedLoanStatus);
        }, 500);
      };
      $scope.saveLoanStatus = (isEnquiry, isChangedLoanStatus = true) => {
        $scope.changeLoanStatus = isChangedLoanStatus;
        if (!$scope.additionalLoanImportantDatesFlag) {
          $scope.saveLoanData(isEnquiry, isChangedLoanStatus);
          return;
        }
        $scope.updateImportantDates(isEnquiry, isChangedLoanStatus);
        callSettlementAddressModal({ $scope, $state, importantDatesService });
      };
      $scope.saveLoanData = (isEnquiry, isChangedLoanStatus = false) => {
        if ($scope.setLoanDetails.isInvalidForm) {
          toastError(
            'Updated Loan Status will not save until mandatory important dates are entered',
          );
          return;
        }
        $scope.enquiryChanged(isEnquiry);
        const { LoanStatus } = $scope.setLoanDetails;
        const isValidLoanStatusID =
          LoanStatus &&
          LoanStatus.LoanStatusID &&
          $scope.changeLoanStatus &&
          parseInt(LoanStatus.LoanStatusID, 10) === 1;
        const isReferral =
          $scope.contactInfoSetValue.EnquirySource &&
          $scope.displayField(
            $scope.contactInfoSetValue.EnquirySource.ReferralItemId,
            $scope.referredByAccessList,
          );
        const referrer =
          $scope.setLoanDetails.ReferrerID &&
          $scope.setLoanDetails.ReferrerName;
        const referrerOrg =
          $scope.setLoanDetails.ReferrerOrgID &&
          $scope.setLoanDetails.ReferrerOrgName;
        const isEmptyReferrer =
          isReferral &&
          !(
            referrer &&
            referrerOrg &&
            $scope.contactInfoSetValue.ReferrerNameLabel
          );
        $scope.isReferrerRequired = isReferral && isEmptyReferrer;
        if ($scope.isReferrerRequired && !$scope.isUserNZ) {
          toaster.pop(
            'error',
            'Enquiry Source Error',
            'This changes will not save. The current selection is Referral and Referred by is Required.',
          );
          return;
        }
        if (!isReferral && isEmptyReferrer) {
          $scope.contactInfoSetValue.EnquirySource = {
            ...$scope.enquirySourceInfoRaw,
          };
          const isValidReferredBy =
            $scope.contactInfoSetValue.EnquirySource.ReferralItemId ===
              REFERRAL_ITEM.REFERRAL &&
            $scope.contactInfoSetValue.ReferrerName &&
            $scope.contactInfoSetValue.ReferrerOrgName;
          if (isValidReferredBy) {
            $scope.contactInfoSetValue.ReferrerNameLabel = `${$scope.contactInfoSetValue.ReferrerName} - ${$scope.contactInfoSetValue.ReferrerOrgName}`;
          }
        }
        $scope.setLoanDetails.IsNPSSurvey = getNPSSurveyToSave({ $scope });
        if (isValidLoanStatusID && !$scope.additionalLoanImportantDatesFlag) {
          modalRenderService
            .openMovedToSettledModal({
              size: 'modalLinkLoanToPipeline',
              familyID: $state.params.familyId,
              loanId: $scope.params.loanId,
              loanScenarioId: $scope.params.loanScenarioID,
            })
            .then(
              (settledDate) => {
                $scope.setLoanDetails.Borrower = $scope.entityList.concat(
                  $scope.borrowerList,
                );
                $scope.setLoanDetails.SettlementDate =
                  settledDate && new Date(settledDate);
                $scope.setLoanDetails.IsEstimated = false;
                const { Lender } = $scope.setLoanDetails;
                if (Lender && Lender.LenderId) {
                  $scope.setLoanDetails.ClientFamilyID = parseInt(
                    $state.params.familyId,
                    10,
                  );
                  setLoanOrSetStatus(isChangedLoanStatus).then(() =>
                    $scope.loanDataField(),
                  );
                } else {
                  toastWarning('Please select lender first', 'Warning!');
                }
              },
              () => {
                $scope.setLoanDetails.LoanStatus.LoanStatusID =
                  $scope.LoanStatusDefault.LoanStatusID;
              },
            );
        } else {
          const openModalPipelineStatusMove = () => {
            const loanSelected = $scope.setLoanDetails;
            modalRenderService
              .renderPipelineStatusMoveConfirmationModal({
                reasonList: $scope.reasonListView,
                isNotProceeded: typelessEqual(
                  loanSelected.LoanStatus.LoanStatusID,
                  LOAN_APP_STATUS_TYPES.NOT_PROCEEDED,
                ),
              })
              .result.then((result) => {
                if (!result.isOk) {
                  return;
                }
                updateNotProceedingDate({
                  $scope,
                  loanScenarioModelService,
                  loanSelected,
                  result,
                });
                return contactService.LoanStatusSet(
                  loanSelected.LoanID,
                  loanSelected.LoanStatus.LoanStatusID,
                  false,
                  result.selectedReasonId,
                  isSkipUpdateDate,
                );
              })
              .then((result) => {
                result &&
                  toaster.pop('success', 'Loan Status successfully updated!');
              });
          };

          /**
           * merge all borrowers
           * */
          $scope.setLoanDetails.Borrower = $scope.entityList.concat(
            $scope.borrowerList,
          );
          const { Lender } = $scope.setLoanDetails;
          if (
            $scope.displayField(
              $scope.setLoanDetails.ReferralItemId,
              $scope.clientEnquiryList,
            )
          ) {
            $scope.contactInfoSetValue.ClientReferrerFamilyId =
              $scope.clientReferrerFamilyId || 0;
            $scope.setLoanDetails.FamilyList[0].ClientReferrerFamilyId =
              $scope.clientReferrerFamilyId || 0;
            $scope.setLoanDetails.ClientReferrerFamilyId =
              $scope.clientReferrerFamilyId || 0;
            $scope.setLoanDetails.ReferrerClientId =
              $scope.referrerClientId || 0;
          } else if (
            $scope.displayField(
              $scope.setLoanDetails.ReferralItemId,
              $scope.additionalEnquiryList,
            )
          ) {
            $scope.setLoanDetails.FamilyList[0].OtherContactReferrerName =
              $scope.contactInfoSetValue.OtherContactReferrerName;
            $scope.setLoanDetails.OtherContactReferrerName =
              $scope.contactInfoSetValue.OtherContactReferrerName;
          }
          if (Lender && Lender.LenderId) {
            const loanSelected = $scope.setLoanDetails;
            loanSelected.ClientFamilyID = parseInt($state.params.familyId, 10);
            if (
              typelessEqual(
                loanSelected.LoanStatus.LoanStatusID,
                LOAN_APP_STATUS_TYPES.NOT_PROCEEDED,
              )
            ) {
              setLoanOrSetStatus(isChangedLoanStatus).then(
                openModalPipelineStatusMove,
              );
            } else {
              const isUnconditionalApproval =
                $scope.setLoanDetails &&
                $scope.setLoanDetails.LoanStatus &&
                parseInt($scope.setLoanDetails.LoanStatus.LoanStatusID, 10) ===
                  LOAN_STATUS.IN_PROGRESS.UNCONDITIONAL_APPROVAL;
              const isSetApprovedDate =
                !$scope.additionalLoanImportantDatesFlag &&
                isUnconditionalApproval &&
                $scope.importantDateSave;
              if (isSetApprovedDate) {
                $scope.setLoanDetails.ApprovalDate = new $window.Date();
                $scope.importantDateSave(
                  $scope.setLoanDetails,
                  IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVAL,
                  $scope.setLoanDetails.ApprovalDate,
                );
              }
              setLoanOrSetStatus(isChangedLoanStatus);
            }
          } else {
            toastWarning('Please select lender first', 'Warning!');
          }
        }
        $scope.changeLoanStatus = false;
      };
      $scope.getProductlist = function (value) {
        if (value.LenderId) {
          $scope.params.loanInformation = {
            ...$scope.params.loanInformation,
            Lender: {
              LenderId: value.LenderId.toString(),
            },
          };
          contactService.loanProductListGet(value.LenderId).then((response) => {
            $scope.params.LoanSplitsProductList = response.data;
          });
        }
      };

      /**
       * Involved Parties Section
       * */

      /**
       * Loan Detail Section ~ allocated adviser
       * */
      const args = {
        clientFamilyId: $scope.params.familyId,
        loanId: params.loanId,
      };
      const adviserGet = $scope.isUserNZ
        ? 'getAdviserListByLoanId'
        : 'getAdvisers';

      mycrmLookupService[adviserGet](args)
        .then((data) => {
          $scope.allocatedAdviser = data || [];

          if ($scope.isUserNZ && !$scope.allocatedAdviser.length) {
            return mycrmLookupService.getAdvisers(args);
          } else {
            return $scope.allocatedAdviser;
          }
        })
        .then((response) => {
          $scope.allocatedAdviser = response || [];
        });

      $scope.loadingBroker = false;
      $scope.allocation = {};
      $scope.loadModalMap = function (size, postingCode) {
        $uibModal.open({
          templateUrl:
            '/assets/views/contacts/partials/adviser/search_nearby_modal.html',
          controller: 'LoadModalMapModalCtrl',
          scope: $scope,
          size,
          backdrop: 'static',
          keyboard: false,
          resolve: {
            postingCode() {
              return postingCode;
            },
          },
        });
      };
      $scope.allocateAdviser = function (familyId, modal, name) {
        SweetAlert.swal(
          {
            title: 'Confirm Change of Adviser',
            text: `By clicking proceed, the allocated adviser of this loan will be changed to ${name}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Proceed',
            closeOnConfirm: true,
          },
          () => {
            if (familyId) {
              $scope.setLoanDetails.OwnedByAdviserName = name;
              $scope.setLoanDetails.OwnedByAdviserID = familyId;
              $scope.mapModel.map.isCancelledTriggered = true;
              $scope.saveLoanData();
            }
          },
        );
      };

      $scope.allocatedAdviserSelected = function (data) {
        if (data) {
          adviserAllocateSwal(data.title, (confirmed) => {
            if (!confirmed) {
              $scope.loanAllocation.adviserId = $scope.allocatedAdviserId;
              return;
            }

            $scope.setLoanDetails.OwnedByAdviserName =
              data.originalObject.fullName;
            $scope.setLoanDetails.OwnedByAdviserID =
              data.originalObject.familyId;
            $scope.directAssistantUpdate(0, false);
            $scope.getAssistantsList(data.originalObject.familyId);
            $scope.$broadcast('angucomplete-alt:clearInput', 'ac-assistant');
            commonFnService.tranferLoanApp(
              data.originalObject.familyId,
              $scope.params.familyId,
              data.originalObject.fullName,
              $scope.clientHeaderName,
              $scope.params.loanId,
            );
            $scope.allocatedAdviserId = $scope.setLoanDetails.OwnedByAdviserID;
            lenderListGet();
            setLoanAdviserNPSEnabled({
              $scope,
              corporateService,
              adviserId: $scope.allocatedAdviserId,
              currentUserService,
            });
            showOriginalLoanWriter({
              configService,
              $scope,
              loan: $scope.setLoanDetails,
            });
          });
        }
      };

      $scope.formatSelectedAdviser = (adviserId) => {
        const data = formatSelectedAdviser(adviserId, $scope.allocatedAdviser);
        $scope.allocatedAdviserSelected(data);
      };

      const getAdvisersAssistants = (advId) => {
        corporateService.adviserAssistantsGet(advId).then((response) => {
          if (response.data && response.data.length) {
            $scope.assistantAdviserList = response.data;
            _.forEach($scope.assistantAdviserList, (value) => {
              value.FullName = `${value.FirstName} ${value.LastName}`;
            });
          }
        });
      };
      const getAssignedAssistant = (advId, familyId) => {
        corporateService
          .assignAssistantToClientGet(advId, parseInt(familyId, 10))
          .then((response) => {
            if (response.data && response.data.length) {
              $scope.contactAdvisers.AssistantAdvID =
                response.data[0].AssistantFamilyID;
            }
          });
      };
      $scope.getClientByFamilyIdSummary = function () {
        contactService
          .clientInformGet($scope.params.familyId)
          .then((response) => {
            $scope.clientsDataObject = response.data;

            /**
             * Summary Data;
             * */
            $scope.getSummaryData($scope.clientsDataObject);
          })
          .then(() => {
            apiRefactorService
              .contactFamilyInfoGet($scope.params.familyId)
              .then((response) => {
                $scope.currentAdviser = response.data;

                /**
                 * getAssistantList
                 * */
                if ($scope.currentAdviser) {
                  getAdvisersAssistants($scope.currentAdviser.BrokerID);
                }

                /**
                 * getContactFamilyInfo.AssistantAdvID
                 * */
                getAssignedAssistant(
                  $scope.currentAdviser.BrokerID,
                  $scope.params.familyId,
                );
              });

            corporateService
              .getAdvisersDropdown($scope.params.familyId, 0, 1)
              .then((adviser) => {
                $scope.allocatedAdviser = adviser.data;
              });

            corporateService.brokerStatusListGet().then((response) => {
              _.each(response.data, (value) => {
                $scope.brokerStatusList.push(value);
              });
            });
          });
      };
      $scope.showCommissions = () => {
        $scope.loanModalTab = 'commission';
        $scope.commissionTab = 'one-off';
        loanInformationService.loanId = $scope.params.loanId;
        loanInformationService.familyId = $scope.params.familyId;
      };
      $scope.showLoanDetail = () => {
        $scope.loanModalTab = 'loan-details';
        if ($scope.params.loanInformationAction !== 'create') {
          $scope.loanDataField();
        }
      };

      $scope.showAssetDetails = () => {
        $scope.loanModalTab = 'asset-details';
      };

      $scope.showLoanStructure = () => {
        $scope.loanModalTab = 'loan-structure';
      };

      $scope.showAssetInsurance = () => {
        $scope.loanModalTab = 'asset-insurance';
      };

      $scope.referredByAccessList = REFERRED_BY_ACCESS;
      $scope.clientEnquiryList = CLIENT_REFERRAL;
      $scope.additionalEnquiryList = ADDITIONAL_REFERRAL;
      $scope.hideSpacer = (itemId) => {
        const itemList = [
          ...$scope.referredByAccessList,
          ...$scope.clientEnquiryList,
          ...$scope.additionalEnquiryList,
        ];
        return itemList.includes(itemId);
      };
      $scope.displayField = displayField;
      $scope.clientSearchApi = (inputString) => {
        const defer = $q.defer();
        defer.resolve([]);
        if (!inputString || !$scope.allocatedAdviserId) {
          return defer.promise;
        }
        return loanProfilerService
          .getSearchClient(inputString, INVOLVED_PARTIES_TYPE.CLIENT)
          .then(mapEnquiryTitleField);
      };
      $scope.selectedClientReferral = (client) => {
        if (!client || !client.originalObject || !client.description) {
          return;
        }
        $scope.clientReferrerFamilyId = client.originalObject.FamilyID || 0;
        $scope.referrerClientId =
          isValidObjectValue(() => client.originalObject.ClientEntityId) || 0;
        $scope.saveLoanData();
      };
      $scope.clientInputChange = (userInput) => {
        $scope.userInput = userInput;
      };
      $scope.clientEmptySave = () => {
        if ($scope.userInput) {
          return;
        }
        $scope.clientReferrerFamilyId = 0;
        $scope.saveLoanData();
      };

      $scope.assetFinanceFlag = assetFinance;
      $scope.getReasonList = () => {
        pipelineService
          .getNotProceedReasons(PIPELINE_TYPES_ID.APPLICATION)
          .then(({ reasonList }) => {
            $scope.reasonListView = reasonList || null;
          });
      };
      $scope.getReasonList();
      const getCreateEnquirySource = () => {
        if ($scope.params.loanInformationAction !== 'create') {
          return;
        }
        assignEnquirySource();
      };
      getCreateEnquirySource();

      if (!$scope.getFamEnquirySource) {
        $scope.getFamEnquirySource = (list) => {
          return contactService
            .contactFamilyInfoGet($scope.params.familyId)
            .then(({ data }) => {
              const referralItemId = formatReferralItemId(data);
              return (
                (list &&
                  list.find((obj) => {
                    return obj.ReferralItemId === referralItemId;
                  })) ||
                {}
              );
            });
        };
      }

      if (!$scope.processEnquirySource) {
        $scope.processEnquirySource = (ReferralCategoryId) => {
          contactService.getUserInfo().then((userlevel) => {
            /**
             * Override condition if Corporate User
             */
            if (
              parseInt(userlevel.data.AccessType, 10) !==
                ACCESS_TYPE.CORPORATE &&
              (ReferralCategoryId === REFERRAL_TYPE.WEB_LOANMARKET ||
                ReferralCategoryId === REFERRAL_TYPE.CONTACT_US)
            ) {
              $('.loan-details')
                .find('#EnquirySourceDropdown')
                .attr('disabled', 'disabled'); // disable the dropdown
            }
          });
        };

        if (!$scope.SettlementDateType) {
          $scope.SettlementDateType = [
            { Type: 'Estimated Date', Value: true },
            { Type: 'Actual Date', Value: false },
          ];
        }

        const includedStatus = `${EXTRA_STATUS.CANCELLED},${
          EXTRA_STATUS.LOAN_REPAID
        }${$scope.isIncludeVaried ? `,${EXTRA_STATUS.VARIED}` : ''}`;

        !$scope.LoanStatusList &&
          optionsService.getLoanStatus(includedStatus).then(({ data }) => {
            if (!data) {
              return;
            }
            $scope.LoanStatusList = [];
            $scope.LoanStatusMainGroup = [];
            $scope.LoanStatusMiscGroup = [];
            data.forEach((status, index) => {
              const statusValue = {
                LoanStatusID: status.Value,
                LoanStatusName: status.Name,
                LoanStatusGroupName: status.GroupName,
              };

              if (index < MAIN_STATUS_LENGTH.STATUS_INDEX) {
                $scope.LoanStatusList.push(statusValue);
              }
              if (index < MAIN_STATUS_LENGTH.STATUS_DIVIDER) {
                $scope.LoanStatusMainGroup.push(statusValue);
              } else {
                $scope.LoanStatusMiscGroup.push(statusValue);
              }
            });
          });
      }
    });
