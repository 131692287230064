export const INCOME_FREQUENCY = {
  ANNUALLY: 1,
  MONTHLY: 12,
  FORTNIGHTLY: 26,
  WEEKLY: 52,
  DAILY: 365,
  'HOURLY/WEEK': 0,
  'HOURLY/DAY': 0,
};

export const INCOME_FREQUENCY_TEXT = {
  ANNUALLY: 'ANNUALLY',
  MONTHLY: 'MONTHLY',
  FORTNIGHTLY: 'FORTNIGHTLY',
  WEEKLY: 'WEEKLY',
  DAILY: 'DAILY',
  'HOURLY/WEEK': 'HOURLY/WEEK',
  'HOURLY/DAY': 'HOURLY/DAY',
};

// TODO : transfer to api
export const MIN_MAX_INCOME_TAX = {
  2020: {
    LOW_INCOME_OFFSET: {
      minThreshold: 37500,
      maxThreshold: 66667,
    },
  },
};
export const INCOME_TAX = {
  2020: {
    medicareDeductionRate: 2,
    lowIncomeTaxOffset: {
      useBracket: true,
      ...MIN_MAX_INCOME_TAX[2020].LOW_INCOME_OFFSET,
      brackets: [
        {
          from: 0,
          to: 37500,
          reductionRate: 0,
          baseAmount: 700,
          ...MIN_MAX_INCOME_TAX[2020].LOW_INCOME_OFFSET,
        },
        {
          from: 37501,
          to: 45000,
          reductionRate: 5,
          baseAmount: 700,
          ...MIN_MAX_INCOME_TAX[2020].LOW_INCOME_OFFSET,
        },
        {
          from: 45001,
          to: 66667,
          reductionRate: 1.5,
          baseAmount: 325,
          ...MIN_MAX_INCOME_TAX[2020].LOW_INCOME_OFFSET,
        },
      ],
    },
    lowAndMiddleIncomeTaxOffset: {
      minThreshold: 37000,
      maxThreshold: 126000,
      minYearEffective: 2018,
      maxYearEffective: 2022,
      brackets: [
        { from: 0, to: 37000, multiplier: 0, baseAmount: 255 },
        { from: 37001, to: 48000, multiplier: +7.5, baseAmount: 255 },
        { from: 48001, to: 90000, multiplier: 0, baseAmount: 1080 },
        { from: 90001, to: 126000, multiplier: -3, baseAmount: 1080 },
      ],
    },
    taxBrackets: [
      { from: 0, to: 18200, multiplier: 0, baseTax: 0 },
      { from: 18201, to: 45000, multiplier: 19, baseTax: 0 },
      { from: 45001, to: 120000, multiplier: +32.5, baseTax: 5092 },
      { from: 120001, to: 180000, multiplier: +37, baseTax: 29467 },
      { from: 180001, to: 100000000000, multiplier: 45, baseTax: 51667 },
    ],
  },
  2019: {
    medicareDeductionRate: 2,
    lowIncomeTaxOffset: {
      reductionRate: 1.5,
      baseAmount: 445,
      minThreshold: 37000,
      maxThreshold: 66667,
    },
    lowAndMiddleIncomeTaxOffset: {
      reductionRate: 1.5,
      baseAmount: 530,
      minThreshold: 37000,
      maxThreshold: 66667,
      minYearEffective: 2018,
      maxYearEffective: 2022,
      brackets: [
        { from: 0, to: 37000, multiplier: 0, baseAmount: 200 },
        { from: 37001, to: 47999, multiplier: 3, baseAmount: 200 },
        { from: 48000, to: 90000, multiplier: 0, baseAmount: 530 },
        { from: 90001, to: 125333, multiplier: -1.5, baseAmount: 530 },
      ],
    },
    taxBrackets: [
      { from: 0, to: 18200, multiplier: 0, baseTax: 0 },
      { from: 18201, to: 37000, multiplier: 19, baseTax: 0 },
      { from: 37001, to: 90000, multiplier: 32.5, baseTax: 3572 },
      { from: 90001, to: 180000, multiplier: 37, baseTax: 20797 },
      { from: 180001, to: 100000000000, multiplier: 45, baseTax: 54097 },
    ],
  },
  2018: {
    medicareDeductionRate: 2,
    lowIncomeTaxOffset: {
      reductionRate: 1.5,
      baseAmount: 445,
      minThreshold: 37000,
      maxThreshold: 66667,
    },
    taxBrackets: [
      { from: 0, to: 18200, multiplier: 0, baseTax: 0 },
      { from: 18201, to: 37000, multiplier: 19, baseTax: 0 },
      { from: 37001, to: 87000, multiplier: 32.5, baseTax: 3572 },
      { from: 87001, to: 180000, multiplier: 37, baseTax: 19822 },
      { from: 180001, to: 100000000000, multiplier: 45, baseTax: 54232 },
    ],
  },
};

export const YEAR_COUPLE = {
  TWENTY_ONE: '2020/2021',
  TWENTY: '2019/2020',
  NINETEEN: '2018/2019',
};

export const YEAR_TEXT = {
  [YEAR_COUPLE.TWENTY_ONE]: '2020',
  [YEAR_COUPLE.TWENTY]: '2019',
  [YEAR_COUPLE.NINETEEN]: '2018',
};
