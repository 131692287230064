import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';
import {
  subscriptionBuilderForMyCRM,
  subIncomeTypeBuilderForUI,
  summarySectionBuilderForUI,
  commissionContactsListBuilderForUI,
  commissionAccessBuilderForUI,
  userInfoBuilderForUI,
  contactAdviserThemeBuilderForUI,
  contactSplitBuilderForMyCRM,
  hasAutomationMarketingBuilderForUI,
  subscriptionPopUpBuilderForUI,
  socialMediaLinksForUI,
  socialMediaLinksForMyCRM,
  futureFinancialGoalBuilderForUI,
  futureFinancialGoalBuilderForMyCRM,
  clientContactNumberBuilderForMyCRM,
  clientContactNumberBuilderForUI,
  entityContactNumberBuilderForMyCRM,
  entityContactNumberBuilderForUI,
  financialsMinBuilderForUI,
  abrSearchForUI,
} from 'Common/mappers/contact';
import {
  reviewFactFindBuilderForUI,
  factFindPricingBuilderForUI,
} from 'Common/mappers/factFind';
import {
  subscriptionInfoBuilderForUI,
  subscriptionBillingBuilderForMyCRM,
  subscriptionBillingEntityBuilderForUI,
  subscriptionBillingPutBuilderForMyCRM,
} from 'Common/mappers/subscription';
import {
  expenseTypeBuilderForUI,
  expenseTypeBuilderForMyCRM,
  documentZipBuilderForUI,
} from 'Common/mappers/loanScenario';
import { advisersLookupBuilderForUI } from 'Common/mappers/general';
import { triggerGA } from 'Common/utilities/googleAnalytics';
import { constructConfigRequest } from 'Common/utilities/request';

class ContactModelService {
  constructor(httpClient, httpCacheService, $q) {
    'ngInject';

    this.httpClient = httpClient;
    this.httpCacheService = httpCacheService;
    this.$q = $q;
    this.apiBaseUrl = 'contacts';
  }

  getGroupedLoadMore(paramObject) {
    return this.httpClient
      .get(`grouped-documents/list-under-group`, paramObject)
      .then(({ data }) => data);
  }

  postCopyAllGroudDocuments(payload) {
    return this.httpClient.post(`grouped-documents/copy-all`, payload);
  }

  postClientGroupedDocProperty(payload) {
    return this.httpClient.post(`grouped-documents/document-property`, payload);
  }

  postDocumentProperty(payload) {
    return this.httpClient.post(
      `grouped-documents/document-property-insert`,
      payload,
    );
  }

  postLoanGroupedDocProperty(payload) {
    return this.httpClient.post(
      `grouped-documents/loan-document-property`,
      payload,
    );
  }

  downloadAllAccordionDocuments(paramObject) {
    return this.httpClient
      .get(`grouped-documents/list/download`, paramObject)
      .then(({ data }) => documentZipBuilderForUI(data));
  }

  getIsFactFindSent({ familyId, loanApplicationId }) {
    return this.httpClient
      .get(
        `pipeline-mergedlead/is-factfind-sent/${familyId}/${loanApplicationId}`,
      )
      .then(({ data }) => data);
  }

  getGroupedDocuments(paramObject) {
    return this.httpClient
      .get(`grouped-documents/list`, paramObject)
      .then(({ data }) => data);
  }

  putLoanReferenceNo(loanId, referenceNo) {
    return this.httpClient
      .put(`${this.apiBaseUrl}/loan/${loanId}/reference-no/${referenceNo}`)
      .then((res) => res);
  }

  setGmailSignature(html) {
    return this.httpClient.post(`google/gmail-signature`, html);
  }

  gmailSyncDeactivate(params) {
    return this.httpClient.post(`google/gmail-sync-deactivate`, {}, params);
  }

  gmailSyncUnlink() {
    return this.httpClient.post(
      '/google/gmail/unlink',
      constructConfigRequest(),
      {},
    );
  }

  getLoanTimeline(id) {
    const defer = this.$q.defer();
    this.httpCacheService.get(
      `${this.apiBaseUrl}/${id}/loan-timeline`,
      (response) =>
        defer.resolve(
          response.data && collectionCamelizeBuilderForUI(response.data),
        ),
      (error) => defer.reject(error),
    );
    return defer.promise;
  }

  getLoanTimelineCard(familyId, loanId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/loan-timeline/${loanId}`)
      .then((res) => res && collectionCamelizeBuilderForUI(res.data));
  }

  getAdviserSocialLinks(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/social-media-links`)
      .then((res) => res && socialMediaLinksForUI(res.data));
  }

  postAdviserSocialLinks(familyId, params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${familyId}/social-media-links`,
      socialMediaLinksForMyCRM(params),
    );
  }

  getSubscriptionPopUp(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/subscription-popup/`, params)
      .then((res) => res && subscriptionPopUpBuilderForUI(res.data));
  }

  postSubscriptionPopUp(tickedItemId) {
    return this.httpClient.post(`${this.apiBaseUrl}/subscription-popup/`, {
      tickedItemId,
    });
  }

  getSoleTraderList(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/sole-trader-list/`, params);
  }

  subscription(data) {
    return this.httpClient
      .post(
        `${this.apiBaseUrl}/Subscriptions`,
        subscriptionBuilderForMyCRM(data),
      )
      .then((res) => {
        const gaEvent = {
          event: 'subscription',
          subscription: data.title,
        };
        triggerGA(gaEvent);
        return subscriptionInfoBuilderForUI(res.data);
      });
  }

  updateSubscription(data) {
    return this.httpClient
      .put(
        `${this.apiBaseUrl}/Subscriptions`,
        subscriptionBuilderForMyCRM(data),
      )
      .then((res) => {
        const gaEvent = {
          event: 'update subscription',
          subscription: data.title,
        };
        triggerGA(gaEvent);
        return subscriptionInfoBuilderForUI(res.data);
      });
  }

  getLoans(id) {
    return this.httpClient.get(`${this.apiBaseUrl}/${id}/loans`);
  }

  getSubIncomeType() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/sub-income-type`)
      .then(
        (response) =>
          (response.data && response.data.map(subIncomeTypeBuilderForUI)) || [],
      );
  }

  getSubIncomeTypeCache() {
    const defer = this.$q.defer();
    this.httpCacheService.get(
      `${this.apiBaseUrl}/sub-income-type`,
      (response) =>
        defer.resolve(
          response.data && response.data.map(subIncomeTypeBuilderForUI),
        ),
      (error) => defer.reject(error),
    );

    return defer.promise;
  }

  taskAdviserListGet(
    loanWritersOnly = null,
    taskId = null,
    isFromTaskList = false,
    includeInactive = true,
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/TaskAdviserListGet`, {
      loanWritersOnly,
      taskId,
      isFromTaskList,
      includeInactive,
    });
  }

  postMergeFamilyProfile(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/MergeFamilyProfile`,
      {},
      params,
    );
  }

  getBrokerCommissionAccess() {
    return this.httpClient.get(`${this.apiBaseUrl}/BrokerCommissionAccess`);
  }

  checkFactFind(clientIds, loanAppId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/fact-find-check/${loanAppId}`, {
        clientIds,
      })
      .then(
        (response) =>
          response.data && reviewFactFindBuilderForUI(response.data),
      );
  }

  getFactFindPricing() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/online-fact-find-pricing`)
      .then(
        (response) =>
          response.data && response.data.map(factFindPricingBuilderForUI),
      );
  }

  getSummarySection() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/section`)
      .then(
        (response) =>
          response.data && response.data.map(summarySectionBuilderForUI),
      );
  }

  updateSummarySection(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/section`, {}, params);
  }

  mailTemplateSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/MailTemplate`, {}, params);
  }

  contactSplitSet(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/contact-split`,
      contactSplitBuilderForMyCRM(params),
    );
  }

  familyListGet(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/FamilyListGet`, params);
  }

  getExpenseTypes(param) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/expense-types`, param)
      .then(({ data }) => (data && expenseTypeBuilderForUI(data)) || {});
  }

  setExpenseType(expenseType) {
    return this.httpClient
      .post(
        `${this.apiBaseUrl}/expense-types`,
        expenseTypeBuilderForMyCRM(expenseType),
      )
      .then(({ data }) => data);
  }

  setExpenseGrid(expenseTypes) {
    return this.httpClient
      .post(
        `${this.apiBaseUrl}/expense-type-grid`,
        expenseTypes.map(expenseTypeBuilderForMyCRM) || [],
      )
      .then(({ data }) => data);
  }

  commissionContactsList(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/commission-contacts-list`, params)
      .then(
        (res) => res.data && res.data.map(commissionContactsListBuilderForUI),
      );
  }

  commissionAccess(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/commissionAccess`, { familyId })
      .then((res) => res.data && res.data.map(commissionAccessBuilderForUI));
  }

  getFamilyAdviserInfo(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/adviser-info`)
      .then(({ data }) => data && userInfoBuilderForUI(data));
  }

  getFamilyAdviserTheme(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/adviser-theme`)
      .then(({ data }) => data && contactAdviserThemeBuilderForUI(data));
  }

  getSubscriptionBillingEntity(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/subscriptions/billing-entity`, params)
      .then(
        (response) =>
          response.data &&
          response.data.map(subscriptionBillingEntityBuilderForUI),
      );
  }

  postSubscriptionBillingEntity(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/subscriptions/billing-entity`,
      subscriptionBillingBuilderForMyCRM(params),
    );
  }

  putSubscriptionBillingEntity(params) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/subscriptions/billing-entity`,
      subscriptionBillingPutBuilderForMyCRM(params),
    );
  }

  getHasAutomationMarketing(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/has-automation-marketing`)
      .then((res) => res.data && hasAutomationMarketingBuilderForUI(res.data));
  }

  confirmContactAssets(assetIds) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/confirm-assets?assetIds=${assetIds}`,
    );
  }

  confirmContactLiabilities(liabilityIds) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/confirm-liabilities?liabilityIds=${liabilityIds}`,
    );
  }

  confirmContactIncome(incomeIds) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/confirm-income?incomeIds=${incomeIds}`,
    );
  }

  confirmContactExpenses(expenseIds) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/confirm-expenses?expenseIds=${expenseIds}`,
    );
  }

  searchFamily(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/FamilyListGet`, params);
  }

  saveFutureFinancialGoalCategory(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${data.familyId}/goal`,
      futureFinancialGoalBuilderForMyCRM(data),
    );
  }

  getFutureFinancialGoalCategory(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/goal`)
      .then(
        (response) =>
          response.data && response.data.map(futureFinancialGoalBuilderForUI),
      );
  }

  setClientContactNumber(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/family-contact-numbers`,
      clientContactNumberBuilderForMyCRM(params),
    );
  }

  getClientContactNumber(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/family-contact-numbers`, params)
      .then(({ data }) => data && data.map(clientContactNumberBuilderForUI));
  }

  setEntityContactNumber(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/entity-contact-numbers`,
      entityContactNumberBuilderForMyCRM(params),
    );
  }

  getEntityContactNumber(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/entity-contact-numbers`, params)
      .then(({ data }) => entityContactNumberBuilderForUI(data));
  }

  dismissFinancialNotifications(familyId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/dismiss-financial-notifications?familyId=${familyId}`,
      {},
    );
  }

  getNewFinancials(familyId, loanApplicationId) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/${familyId}/new-financials/${loanApplicationId}`,
        {},
      )
      .then(({ data }) => data && financialsMinBuilderForUI(data));
  }

  getDocumentsZip(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/clients-documents-zip`, params)
      .then(({ data }) => data && documentZipBuilderForUI(data));
  }

  getABNDetails(abn) {
    return this.httpClient
      .get(`abr-search/abn`, { abn }, false, true, false)
      .then(({ data }) => abrSearchForUI(data));
  }

  downloadGmailAttachment(params) {
    return this.httpClient
      .post(`/google/email-attachment`, params)
      .then(({ data }) => data && documentZipBuilderForUI(data));
  }

  setLoanDocumentProperty(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/loan-document-property`,
      data,
    );
  }

  getAdviserListByLoanId(loanId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/assigned-advisers`, { loanId })
      .then(({ data }) => data && data.map(advisersLookupBuilderForUI));
  }

  getDealContactCalculations(familyId = 0) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/deal-contact-calculations`)
      .then(({ data }) => data);
  }
}

export default ContactModelService;
