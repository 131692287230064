import get from 'lodash/get';
import {
  PACK_MODE,
  DOCUMENT_REQUEST_METHOD_ID,
  SERVICE_OPTION,
  MODAL_SERVICE,
} from 'Common/constants/helloBook';
import { AWS_S3_ROOT } from 'Common/constants/document';
import { performanceTimingData } from 'Common/utilities/dataLayer';
import {
  HELLO_PACK_SEND_INSURANCE,
  HELLO_PACK_SEND_LENDING,
} from 'Common/constants/performance';

const VIEWS = {
  EDITOR: 0,
  DEAL: 1,
  VIEWER: 2,
};
const MODAL_TITLE = {
  HELLO_PACK: 'Send Hello Pack',
  FACT_FIND: 'Online Fact Find',
  INSURANCE_PROFILER: 'Send Insurance Profile',
  INVITE_IOFF: 'Invite to Insurance Online Fact Find',
};
const MODAL_LABEL = {
  DEFAULT: 'Save as default',
  BACK: 'Back',
  NEXT: 'Next',
  REVIEW_SEND: 'Review & Send',
  SEND: 'Send',
};

export default class HelloPackModalCtrl {
  constructor(
    $timeout,
    uiService,
    helloPackService,
    contactModelService,
    loanScenarioService,
    contactSharedDataService,
    configService,
    timeoutService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.uiService = uiService;
    this.helloPackService = helloPackService;
    this.contactModelService = contactModelService;
    this.loanScenarioService = loanScenarioService;
    this.contactSharedDataService = contactSharedDataService;
    this.configService = configService;
    this.timeoutService = timeoutService;
  }

  $onInit() {
    this.oneTouchPrivacy = this.configService.feature.oneTouchPrivacy;
    this.PACK_MODE = PACK_MODE;
    this.helloPackService.resetHelloPackForm();
    this.fileUploaderInit();
    this.slickConfig = { method: {} };
    this.secondaryButtonText = MODAL_LABEL.DEFAULT;
    this.currentView = VIEWS.EDITOR;

    if (this.insuranceProfilerId) {
      this.insuranceProfilerInit();
    } else if (this.inviteInsuranceOnlineFactFind) {
      this.inviteIOFFInit();
    } else {
      this.defaultInit();
    }
  }

  fileUploaderInit() {
    if (!this.inviteInsuranceOnlineFactFind) {
      this.helloPackService.initFileUploader(
        this.familyId,
        !!this.insuranceProfilerId,
        this.insuranceProfilerId
          ? AWS_S3_ROOT.INSURANCE_APP
          : AWS_S3_ROOT.HELLOBOOK,
      );
    }
  }

  insuranceProfilerInit() {
    this.helloPack = { insuranceProfilerId: this.insuranceProfilerId || 0 };
    this.views = [
      {
        id: VIEWS.EDITOR,
        name: 'editor',
        component: `<hello-pack-editor
                      family-id="vm.familyId"
                      model="vm.helloPack"
                      adviser-info="vm.helloPack.adviserInfo"
                      adviser-theme="vm.helloPack.adviserTheme"
                      pack-mode="vm.PACK_MODE.INSURANCE_PROFILER"
                    ></hello-pack-editor>`,
      },
      {
        id: VIEWS.VIEWER,
        name: 'viewer',
        component: `<hello-pack-viewer
                      family-id="vm.familyId"
                      active-pack-mode="vm.activePackMode"
                      model="vm.helloPack"
                      adviser-info="vm.helloPack.adviserInfo"
                      on-load-theme="vm.onLoadTheme(theme)"
                    ></hello-pack-viewer>`,
      },
    ];

    this.modalTitle = MODAL_TITLE.INSURANCE_PROFILER;
    this.activePackMode = PACK_MODE.INSURANCE_PROFILER;
    this.showSecondaryButton = true;
    this.getFamilyAdviserInfo();
    this.changeModalLabels();
  }

  inviteIOFFInit() {
    this.helloPack = {};
    this.views = [
      {
        id: VIEWS.EDITOR,
        name: 'editor',
        component: `<hello-pack-editor
                      family-id="vm.familyId"
                      model="vm.helloPack"
                      adviser-info="vm.helloPack.adviserInfo"
                      adviser-theme="vm.helloPack.adviserTheme"
                      pack-mode="vm.PACK_MODE.INVITE_IOFF"
                      only-fact-find="vm.onlyFactFind"
                    ></hello-pack-editor>`,
      },
      {
        id: VIEWS.VIEWER,
        name: 'viewer',
        component: `<hello-pack-viewer
                      family-id="vm.familyId"
                      active-pack-mode="vm.activePackMode"
                      model="vm.helloPack"
                      adviser-info="vm.helloPack.adviserInfo"
                      on-load-theme="vm.onLoadTheme(theme)"
                    ></hello-pack-viewer>`,
      },
    ];

    this.modalTitle = MODAL_TITLE.INVITE_IOFF;
    this.activePackMode = PACK_MODE.INVITE_IOFF;
    this.showSecondaryButton = false;
    this.getFamilyAdviserInfo();
    this.changeModalLabels();
  }

  getLoanWriterValue() {
    const { INSURANCE } = SERVICE_OPTION;
    const defaultValue = this.selectedService === INSURANCE ? false : null;
    return this.isForLoanWriters || defaultValue;
  }

  defaultInit() {
    this.helloPackService.handler.setHelloPackLoanId = (loanApplicationId) => {
      this.setHelloPackLoanId(loanApplicationId);
    };
    this.helloPack = {
      isForLoanWriters: this.getLoanWriterValue(),
      selectedService: this.selectedService,
      loanApplicationId: this.loanApplicationId || 0,
      isFactFindValid: true,
    };
    this.isWithDealView = !this.loanApplicationId;
    const dealView = {
      id: VIEWS.DEAL,
      name: 'deal',
      component: `<hello-pack-deal
                    family-id="vm.familyId"
                    model="vm.helloPack"
                  ></hello-pack-deal>`,
    };
    const helloPackDealView =
      this.isWithDealView && this.selectedService !== MODAL_SERVICE.insurance
        ? dealView
        : {};
    this.views = [
      {
        id: VIEWS.EDITOR,
        name: 'editor',
        component: `<hello-pack-editor
                      family-id="vm.familyId"
                      model="vm.helloPack"
                      selected-service="vm.selectedService"
                      adviser-info="vm.helloPack.adviserInfo"
                      adviser-theme="vm.helloPack.adviserTheme"
                      only-fact-find="vm.onlyFactFind"
                      one-touch-privacy="vm.oneTouchPrivacy"
                      hide-deal-option="vm.hideDealOption"
                    ></hello-pack-editor>`,
      },
      { ...helloPackDealView },
      {
        id: VIEWS.VIEWER,
        name: 'viewer',
        component: `<hello-pack-viewer
                      family-id="vm.familyId"
                      active-pack-mode="vm.activePackMode"
                      model="vm.helloPack"
                      adviser-info="vm.helloPack.adviserInfo"
                      on-load-theme="vm.onLoadTheme(theme)"
                    ></hello-pack-viewer>`,
      },
    ];
    this.modalTitle = this.onlyFactFind
      ? MODAL_TITLE.FACT_FIND
      : MODAL_TITLE.HELLO_PACK;
    this.activePackMode = PACK_MODE.HELLO_PACK;
    this.showSecondaryButton = true;
    this.getFamilyAdviserInfo();
    if (this.loanApplicationId) {
      this.setHelloPackLoanId(this.loanApplicationId);
    }
    this.changeModalLabels();
    this.helloPackService.handler.skipDealView = (skipDealView) => {
      this.onSkipDealView(skipDealView);
    };
  }

  changeModalLabels() {
    if (this.insuranceProfilerId) {
      this.primaryButtonText =
        this.currentView === VIEWS.EDITOR
          ? MODAL_LABEL.REVIEW_SEND
          : MODAL_LABEL.SEND;
    } else if (this.inviteInsuranceOnlineFactFind) {
      this.primaryButtonText =
        this.currentView === VIEWS.EDITOR ? MODAL_LABEL.NEXT : MODAL_LABEL.SEND;
    } else {
      const reviewSendText = this.onlyFactFind
        ? MODAL_LABEL.SEND
        : MODAL_LABEL.REVIEW_SEND;
      this.primaryButtonText =
        this.isWithDealView && !this.oneTouchPrivacy
          ? MODAL_LABEL.NEXT
          : reviewSendText;
    }
  }

  onSkipDealView(skipDealView = true) {
    this.isWithDealView = !this.loanApplicationId && !skipDealView;
    this.changeModalLabels();
  }

  getFamilyAdviserInfo() {
    return this.contactModelService
      .getFamilyAdviserInfo(this.familyId)
      .then((data) => {
        this.helloPack.adviserInfo = data;
        this.helloPack.adviserId = get(
          this,
          'helloPack.adviserInfo.familyId',
          0,
        );
      });
  }

  sendHelloPack() {
    this.isSendingHelloPack = true;
    this.helloPackService.send(
      this.familyId,
      this.helloPack,
      (closeModal = true) => {
        this.isSendingHelloPack = false;
        if (closeModal) {
          this.modalInstance.close({});
        }
      },
    );
    performanceTimingData({
      event: `hello-book-send`,
      action: this.isForLoanWriters
        ? HELLO_PACK_SEND_LENDING
        : HELLO_PACK_SEND_INSURANCE,
    });
  }

  sendInsuranceProfile() {
    this.isSendingHelloPack = true;
    this.helloPackService.sendInsuranceProfile(
      this.familyId,
      this.helloPack,
      () => {
        this.isSendingHelloPack = false;
        this.modalInstance.close({});
      },
    );
  }

  sendInviteIOFF() {
    this.isSendingHelloPack = true;
    this.helloPackService.sendInviteIOFF(this.familyId, this.helloPack, () => {
      this.isSendingHelloPack = false;
      this.modalInstance.close({});
    });
  }

  secondaryButtonAction() {
    switch (this.currentView) {
      case VIEWS.EDITOR:
        this.isSavingDefault = true;
        this.$timeout(() => {
          this.helloPackService.saveDefault(this.helloPack, () => {
            this.isSavingDefault = false;
          });
        }, 300);
        break;
      case VIEWS.DEAL:
        this.currentView = VIEWS.EDITOR;
        this.slickConfig.method.slickGoTo(VIEWS.EDITOR);
        this.secondaryButtonText = MODAL_LABEL.DEFAULT;
        this.primaryButtonText = MODAL_LABEL.NEXT;
        break;
      case VIEWS.VIEWER:
        this.currentView =
          this.isWithDealView && !this.oneTouchPrivacy
            ? VIEWS.DEAL
            : VIEWS.EDITOR;
        this.slickConfig.method.slickGoTo(this.currentView);
        this.secondaryButtonText =
          this.currentView === VIEWS.EDITOR
            ? MODAL_LABEL.DEFAULT
            : MODAL_LABEL.BACK;
        this.changeModalLabels();
        if (this.inviteInsuranceOnlineFactFind) {
          this.showSecondaryButton = false;
        }
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  primaryButtonAction() {
    if (!this.onSubmitValidation()) {
      return;
    }
    switch (this.currentView) {
      case VIEWS.EDITOR:
        if (this.onlyFactFind && !this.inviteInsuranceOnlineFactFind) {
          this.sendHelloPack();
        } else {
          this.currentView =
            this.isWithDealView &&
            !this.helloPack.skipDealView &&
            !this.oneTouchPrivacy
              ? VIEWS.DEAL
              : VIEWS.VIEWER;
          this.secondaryButtonText = MODAL_LABEL.BACK;
          this.primaryButtonText =
            this.currentView === VIEWS.DEAL
              ? MODAL_LABEL.NEXT
              : MODAL_LABEL.SEND;
          this.slickConfig.method.slickGoTo(this.currentView);

          if (this.inviteInsuranceOnlineFactFind) {
            this.showSecondaryButton = true;
          }
        }
        break;
      case VIEWS.DEAL:
        this.currentView = VIEWS.VIEWER;
        this.slickConfig.method.slickGoTo(VIEWS.VIEWER);
        this.secondaryButtonText = MODAL_LABEL.BACK;
        this.primaryButtonText = MODAL_LABEL.SEND;
        break;
      case VIEWS.VIEWER:
        if (this.insuranceProfilerId) {
          this.sendInsuranceProfile();
        } else if (this.inviteInsuranceOnlineFactFind) {
          this.sendInviteIOFF();
        } else {
          this.sendHelloPack();
        }
        break;
      default:
        break;
    }
  }

  onSubmitValidation() {
    return this.helloPackService.onCheckClientDuplicateEmail(
      this.helloPack.clients,
    );
  }

  onCancel() {
    this.modalInstance.dismiss();
  }

  onLoadTheme(theme) {
    this.helloPack.adviserTheme = theme;
  }

  checkDefaultValidation() {
    const isFactFindValid = get(this, 'helloPack.isFactFindValid', false);
    const isInvolvedPartiesValid = get(
      this,
      'helloPack.isInvolvedPartiesValid',
      false,
    );
    const isFormValid = get(
      this,
      'helloPackService.form.editorItems.$valid',
      false,
    );
    const documentRequestValue = get(
      this,
      'helloPack.options.documentRequest.value',
      false,
    );
    const documentRequestFileInvite =
      get(this, 'helloPack.documentRequestMethod', null) ===
      DOCUMENT_REQUEST_METHOD_ID.FILE_INVITE;

    const documentRequestSelected =
      documentRequestFileInvite && !documentRequestValue;

    const isSendFileInviteValid =
      this.uiService.fileInviteEnabled &&
      this.helloPack.documentRequestMethod ===
        DOCUMENT_REQUEST_METHOD_ID.FILE_INVITE;
    const isTemplateValid =
      !isSendFileInviteValid ||
      (isSendFileInviteValid &&
        get(this, 'helloPack.fileInvite.templates', []).length);

    const formErrors = get(
      this,
      'helloPackService.form.$error.required',
      [],
    ).map((error) => error.$name);
    const fileInviteValidation = ['due-date', 'reminder', 'overdue'];
    const hasValidFileInviteError =
      formErrors.filter(
        (fieldName) => !fileInviteValidation.includes(fieldName),
      ).length < 1;
    const validTemplate =
      !documentRequestValue || (documentRequestValue && isTemplateValid);

    return (
      isInvolvedPartiesValid &&
      isFactFindValid &&
      ((isFormValid && validTemplate) ||
        (!isFormValid && documentRequestSelected && hasValidFileInviteError))
    );
  }

  checkInsuranceValidation() {
    const isFormValid = get(
      this,
      'helloPackService.form.editorItems.$valid',
      false,
    );
    const isInvolvedPartiesValid = get(
      this,
      'helloPack.isInvolvedPartiesValid',
      false,
    );

    return isInvolvedPartiesValid && isFormValid;
  }

  onCheckValidation() {
    if (this.insuranceProfilerId || this.inviteInsuranceOnlineFactFind) {
      return this.checkInsuranceValidation();
    } else {
      return this.checkDefaultValidation();
    }
  }

  onDisablePrimaryButton() {
    const isDealViewDisabled =
      (this.currentView === VIEWS.DEAL || !this.hideDealOption) &&
      this.isWithDealView &&
      get(this, 'helloPack.dealViewDisabled');
    return !this.onCheckValidation() || isDealViewDisabled;
  }

  setHelloPackLoanId(loanApplicationId) {
    this.loanScenarioService
      .scenarioDetailsGet(loanApplicationId)
      .then(({ data }) => {
        this.helloPack.loanId = data.LoanId || 0;
      });
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('HelloPackModalCtrl');
  }
}
