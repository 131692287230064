import { get } from 'lodash';
import {
  getCountryName,
  getCountryCode,
  getLanguageRegion,
} from 'Common/utilities/user';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { ADVISER_STATUS } from 'Common/constants/adviserStatus';
import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';
import { FINANCIAL_TYPE } from 'Common/constants/financialFrequency';
import { ROLE_TYPE } from 'Common/constants/roleType';
import { TYPE_ID } from 'Common/constants/futureFinancialGoals';
import {
  isLMBranded,
  isIMBranded,
  isOwnBrand,
  isWMBranded,
} from 'Common/utilities/brandingCategory';
import { aggregatorBuilderForUI } from 'Common/mappers/aggregator';

export function convertContentHtmlToRtfBuilderForUI(parameter) {
  return (
    parameter && {
      contentType: parameter.ContentType,
      documentContent: parameter.DocumentContent,
      name: parameter.Name,
    }
  );
}

export function brokerRegionalizationBuilderForUI(brokerRegionalization) {
  return {
    code: brokerRegionalization.Code,
    countryID: brokerRegionalization.CountryID,
    currencySign: brokerRegionalization.CurrencySign,
    name: brokerRegionalization.Name,
  };
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function userInfoBuilderForUI(user) {
  return {
    aLIQuoteEnabled: user.ALIQuoteEnabled,
    accessType: user.AccessType,
    brandingCategory: user.BrandingCategory,
    brandingCategoryID: user.BrandingCategoryID,
    brokerStatusId: user.BrokerStatusID,
    campaignBreezeEnabled: user.CampaignBreezeEnabled,
    clientId: user.ClientId,
    corpAccessTypeID: user.CorpAccessTypeID,
    corporateTeam: user.CorporateTeam,
    corporateTeamId: user.CorporateTeamId,
    countryId: user.CountryId,
    displayTitle: user.DisplayTitle,
    familyId: user.FamilyId,
    firstName: user.FirstName,
    lastName: user.LastName,
    fullName: user.FullName,
    email: user.Email,
    mobile: user.MobileNumber,
    gotItTicked: user.GotItTicked,
    isAllowCustomizedTheme: user.IsAllowCustomizedTheme,
    myCRMActive: user.MyCRMActive,
    nPSEnabled: user.NPSEnabled,
    preferredFullName: user.PreferredFullName,
    preferredName: user.PreferredName,
    sMSEnabled: user.SMSEnabled,
    sMSEnabledDate: user.SMSEnabledDate,
    roleIds: user.RoleIds,
    isRiskWriter:
      user.RoleIds &&
      user.RoleIds.length &&
      user.RoleIds.some((item) => item === ROLE_TYPE.RISK_WRITER),
    isLoanWriter: !!user.IsLoanWriter,
    orgMemberHasLoanWriter: !!user.OrgMemberHasLoanWriter,
    orgMemberHasRiskWriter: !!user.OrgMemberHasRiskWriter,
    crmCountry: getCountryName(user.CountryId),
    isNZ: user.CountryId === COUNTRY_TYPE.NEW_ZEALAND,
    isAU: user.CountryId === COUNTRY_TYPE.AUSTRALIA,
    isID: user.CountryId === COUNTRY_TYPE.INDONESIA,
    reportingStateName: user.ReportingStateName,
    countryCode: getCountryCode(user.CountryId),
    isPrincipalAdviser: user.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER,
    isAdviser: user.AccessType === ACCESS_TYPE.ADVISER,
    isAdminAssistant: user.AccessType === ACCESS_TYPE.ADMIN_ASSISTANT,
    isAssistant: user.AccessType === ACCESS_TYPE.ASSISTANT,
    isAssistantType:
      user.AccessType === ACCESS_TYPE.ASSISTANT ||
      user.AccessType === ACCESS_TYPE.ADMIN_ASSISTANT,
    isCorporate: user.AccessType === ACCESS_TYPE.CORPORATE,
    isReferrer: user.AccessType === ACCESS_TYPE.REFERRER,
    isSuperAdmin: user.CorporateTeamId === CORPORATE_TEAM.SUPER_ADMIN,
    brokerRegionalization:
      user.BrokerRegionalization &&
      brokerRegionalizationBuilderForUI(user.BrokerRegionalization),
    isOnboarding: user.BrokerStatusID === ADVISER_STATUS.ONBOARDING,
    isOnboardingActive: user.OnlineOnboardingActive,
    isNzAssistant:
      user.CountryId === COUNTRY_TYPE.NEW_ZEALAND &&
      [ACCESS_TYPE.ADMIN_ASSISTANT, ACCESS_TYPE.ASSISTANT].includes(
        user.AccessType,
      ),
    isNzAdviser:
      user.CountryId === COUNTRY_TYPE.NEW_ZEALAND &&
      [ACCESS_TYPE.PRINCIPAL_ADVISER, ACCESS_TYPE.ADVISER].includes(
        user.AccessType,
      ),
    isNzCorp:
      user.CountryId === COUNTRY_TYPE.NEW_ZEALAND &&
      user.AccessType === ACCESS_TYPE.CORPORATE,
    tradingName: user.TradingName,
    asicId: user.ASICID,
    userAdviserOrgId: user.AdviserOrgID || '',
    userAdviserOrgName: user.AdviserOrgName || '',
    fullBodyPhotoUrl: user.FullBodyPhotoUrl,
    marketingDisplayTitle: user.MarketingDisplayTitle,
    profileDisplayTitle: user.DisplayTitle,
    website: user.Website,
    newReleasesCount: user.NewReleasesCount || 0,
    snoozeAnnouncement: user.SnoozeAnnouncement,
    isLM: isLMBranded(user.BrandingCategoryID),
    isInsuranceMarket: isIMBranded(user.BrandingCategoryID),
    isBYOB: isOwnBrand(user.BrandingCategoryID),
    isWM: isWMBranded(user.BrandingCategoryID),
    cannyToken: user.CannyToken,
    entityOrgId: user.EntityOrgId || 0,
    entityName: user.EntityName || '',
    consumerUrl: user.ConsumerUrl || '',
    myCRMActiveDate: user.MyCRMActiveDate,
    hasAdviserAccess:
      user.AccessType === ACCESS_TYPE.ADVISER ||
      user.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER ||
      false,
    hasAssistantAccess:
      user.AccessType === ACCESS_TYPE.ADMIN_ASSISTANT ||
      user.AccessType === ACCESS_TYPE.ASSISTANT ||
      false,
    isOnlineInsAppReady: user.IsOnlineInsAppReady,
    userTerm:
      user.CountryId === COUNTRY_TYPE.NEW_ZEALAND ? 'Adviser' : 'Broker',
    isEnableComplianceTools: user.IsEnableComplianceTools,
    businessUnitId: user.BusinessUnitID,
    familyAchievement: Array.isArray(user.FamilyAchievement)
      ? user.FamilyAchievement.join(',')
      : '',
    advisorOrgName: user.AdvisorOrgName || '',
    businessUnitName: user.BusinessUnitName || '',
    languageRegion: getLanguageRegion(user.CountryId),
    aggregator: aggregatorBuilderForUI(user.AggregatorDetails),
  };
}

export function userInfoWithAddedDetailsBuilderForUI(user) {
  const userData = userInfoBuilderForUI(user);
  return {
    ...userData,
    accountOwner: user.AccountOwner,
    reportingRegion: user.ReportingRegion,
    startDate: user.StartDate,
    headBroker: user.HeadBroker,
    hasRewardsRecognitionChairman: user.HasRewardsRecognitionChairman,
  };
}

export function subscriptionBuilderForMyCRM(subscription) {
  return {
    BrokerID: subscription.brokerId,
    Description: subscription.description,
    FranchiseBillingDate: subscription.franchiseBillingDate,
    IsActive: subscription.isActive,
    IsCancelled: subscription.isCancelled,
    IsTrial: subscription.isTrial,
    MonthlyRecurringFee: subscription.monthlyRecurringFee,
    NextRenewDate: subscription.nextRenewDate,
    OrderedBy: subscription.orderedBy,
    OrderedById: subscription.orderedById,
    SubscriptionId: subscription.subscriptionId,
    SubscriptionProductId: subscription.subscriptionProductId,
    SubscriptionStartDate: subscription.subscriptionStartDate,
    Title: subscription.title,
    TermsAndConditionsLink: subscription.termsAndConditionsLink,
    SubDetailIDs: subscription.subDetailIDs,
    SubscriptionEndDate: subscription.subscriptionEndDate,
    IsImmediately: subscription.isImmediately,
    SubscriptionHasTrial: subscription.hasTrial,
    IsPromoted: subscription.isPromoted,
    DefaultPrice: subscription.defaultPrice,
    AdditionalCostDescription: subscription.additionalCostDescription,
  };
}

export function mergeFamilyProfileBuilderForUI(data, familyId) {
  return {
    succeeded: data,
    data: { familyId },
  };
}

export function subscriptionPopUpBuilderForUI(data) {
  return {
    isSubscribed: data.IsSubscribed,
    shouldPopup: data.ShouldPopup,
    showPopUp: !!(data.ShouldPopup && !data.IsSubscribed),
  };
}

export const socialMediaLinksForUI = (data) => {
  return {
    avatarDocumentId: data.AvatarDocumentId,
    facebook: data.Facebook,
    googlePlus: data.GooglePlus,
    linkedIn: data.LinkedIn,
    skype: data.Skype,
    twitter: data.Twitter,
    youTubeChannel: data.YouTubeChannel,
    youtubeFeatured: data.YoutubeFeatured,
    instagram: data.Instagram,
    googleMyBusiness: data.GoogleMyBusiness,
  };
};

export const socialMediaLinksForMyCRM = (data) => {
  return {
    AvatarDocumentId: data.avatarDocumentId,
    Facebook: data.facebook,
    GooglePlus: data.googlePlus,
    LinkedIn: data.linkedIn,
    Skype: data.skype,
    Twitter: data.twitter,
    YouTubeChannel: data.youTubeChannel,
    YoutubeFeatured: data.youtubeFeatured,
    Instagram: data.instagram,
    GoogleMyBusiness: data.googleMyBusiness,
  };
};

export function subIncomeTypeBuilderForUI(subIncomeTypeList) {
  return {
    rentalTypeId: subIncomeTypeList.RentalTypeId,
    rentalIncomeName: subIncomeTypeList.RentalIncomeName,
  };
}

export function summarySectionBuilderForUI(data) {
  return {
    sectionID: data.SectionID,
    sectionName: data.SectionName,
    isSelected: data.IsSelected,
    orderBy: data.OrderBy,
  };
}

export function commissionContactsListBuilderForUI(contact) {
  return {
    familyId: contact.FamilyId,
    familyFullname: contact.FamilyFullname,
  };
}

export function commissionAccessBuilderForUI(data) {
  return (
    data && {
      access: data.Access,
      brokerFullName: data.BrokerFullName,
      familyId: data.FamilyID,
      hasCommissionAccess: data.HasCommissionAccess,
    }
  );
}
export function contactEmailBuilderForUI(email) {
  return (
    email && {
      type: email.Type,
      email: email.EmailAddress,
    }
  );
}

export function contactEmailBuilderForMyCRM(email) {
  return (
    email && {
      Type: email.type,
      EmailAddress: email.email,
    }
  );
}

export function contactPhoneBuilderForUI(phone) {
  return (
    phone && {
      type: phone.Type,
      number: phone.Number,
      countryCode: phone.CountryCode || '',
    }
  );
}

export function contactPhoneBuilderForMyCRM(phone) {
  return (
    phone && {
      Type: phone.type,
      Number: phone.number,
      CountryCode: phone.countryCode || '',
    }
  );
}

export function contactAdviserThemeBuilderForUI(theme) {
  return (
    theme && {
      bgColor: theme.BGColour,
      logoDocId: theme.CRM_LogoDocId,
      sidebarBgColor: theme.SideBarBGColour,
      sidebarFontColor: theme.SideBarFontColour,
      fontColour: theme.ThemeFontColour,
      tradingName: theme.TradingName,
      brandingCategory: theme.BrandingCategory,
      brandingCategoryID: theme.BrandingCategoryId,
      isBYOB: isOwnBrand(theme.BrandingCategoryId),
    }
  );
}

export function contactSplitBuilderForMyCRM(data) {
  return {
    DuplicateCommunication: data.duplicateCommunication,
    DuplicateTask: data.duplicateTask,
    FamilyId: data.familyId,
    PrimaryClientId: data.primaryClientId,
  };
}

export function hasAutomationMarketingBuilderForUI(data) {
  return {
    hasAutomationMarketing: data,
  };
}

export function futureFinancialGoalBuilderForUI(data) {
  const requiredGoals = {
    [TYPE_ID.SUMMARY]: true,
  };
  return {
    isRequired: requiredGoals[data.FutureFinancialGoalTypeId] || false,
    details: data.Details,
    description: data.FutureFinancialGoalTypeDescription,
    familyId: data.FamilyId,
    id: data.FutureFinancialGoalId,
    type: data.FutureFinancialGoalType,
    typeId: data.FutureFinancialGoalTypeId,
    isEdit: false,
  };
}

export function futureFinancialGoalBuilderForMyCRM(data) {
  return {
    FutureFinancialGoalId: data.id,
    FutureFinancialGoalTypeId: data.typeId,
    FamilyId: data.familyId,
    FutureFinancialGoalType: data.type,
    Details: data.details,
  };
}

export function noteTypeBuilderForUI(noteType) {
  return (
    noteType && {
      id: noteType.NoteTypeID,
      key: noteType.NoteTypeID,
      type: noteType.NoteTypeDesc,
    }
  );
}

export function noteTypeBuilderForMyCRM(noteType) {
  return (
    noteType && {
      noteTypeId: noteType.id,
      noteTypeDesc: noteType.type,
    }
  );
}

export function clientContactNumberBuilderForMyCRM(data) {
  return (
    data && {
      FamilyId: data.familyId,
      ClientId: data.clientId,
      MobilePhone: get(data, 'mobile.number', undefined),
      MobilePhoneCountryCode: get(data, 'mobile.countryCode', undefined),
      HomePhone: get(data, 'home.number', undefined),
      HomePhoneCountryCode: get(data, 'home.countryCode', undefined),
      WorkPhone: get(data, 'work.number', undefined),
      WorkPhoneCountryCode: get(data, 'work.countryCode', undefined),
      Fax: get(data, 'fax.number', undefined),
      FaxCountryCode: get(data, 'fax.countryCode', undefined),
    }
  );
}

export function clientContactNumberBuilderForUI(data) {
  return (
    data && {
      familyId: data.FamilyId,
      clientId: data.ClientId,
      mobile: {
        number: data.MobilePhone,
        countryCode: data.MobilePhoneCountryCode,
      },
      home: {
        number: data.HomePhone,
        countryCode: data.HomePhoneCountryCode,
      },
      work: {
        number: data.WorkPhone,
        countryCode: data.WorkPhoneCountryCode,
      },
      fax: {
        number: data.Fax,
        countryCode: data.FaxCountryCode,
      },
    }
  );
}

export function entityContactNumberBuilderForUI(data) {
  return (
    data && {
      familyId: data.FamilyId,
      mobile: {
        number: data.MobilePhone,
        countryCode: data.MobilePhoneCountryCode,
      },
      afterHours: {
        number: data.AfterHoursPhone,
        countryCode: data.AfterHoursPhoneCountryCode,
      },
      work: {
        number: data.WorkPhone,
        countryCode: data.WorkPhoneCountryCode,
      },
      fax: {
        number: data.Fax,
        countryCode: data.FaxCountryCode,
      },
    }
  );
}

export function entityContactNumberBuilderForMyCRM(data) {
  return (
    data && {
      FamilyId: data.familyId,
      MobilePhone: get(data, 'mobile.number', undefined),
      MobilePhoneCountryCode: get(data, 'mobile.countryCode', undefined),
      AfterHoursPhone: get(data, 'afterHours.number', undefined),
      AfterHoursPhoneCountryCode: get(
        data,
        'afterHours.countryCode',
        undefined,
      ),
      WorkPhone: get(data, 'work.number', undefined),
      WorkPhoneCountryCode: get(data, 'work.countryCode', undefined),
      Fax: get(data, 'fax.number', undefined),
      FaxCountryCode: get(data, 'fax.countryCode', undefined),
    }
  );
}

export function assetMinBuilderForUI(data) {
  return (
    data && {
      value: data.Value,
      address: data.formatted_address,
    }
  );
}

export function liabilityMinBuilderForUI(data) {
  return (
    data && {
      repayments: data.Repayments,
      repaymentFrequency: data.RepaymentFrequencyName,
      shortRepaymentFrequency:
        (data.RepaymentFrequencyName &&
          data.RepaymentFrequencyName.charAt(0).toLowerCase()) ||
        '',
      lenderId: data.CreditorId,
      lenderName: data.CreditorName,
      countryCode: data.CountryCode,
      type: data.Type,
    }
  );
}

export function financialsMinBuilderForUI(data) {
  const assetType =
    (data &&
      data.find(
        (financial) => financial.FinancialType === FINANCIAL_TYPE.ASSET,
      )) ||
    {};
  const liabilityType =
    (data &&
      data.find(
        (financial) => financial.FinancialType === FINANCIAL_TYPE.LIABILITY,
      )) ||
    {};

  const assets =
    (assetType.FinancialInfo &&
      assetType.FinancialInfo.map(assetMinBuilderForUI)) ||
    [];
  const liabilities =
    (liabilityType.FinancialInfo &&
      liabilityType.FinancialInfo.map(liabilityMinBuilderForUI)) ||
    [];

  return {
    assets,
    liabilities,
  };
}

export function abrSearchForUI(data) {
  return (
    data && {
      organisationName: data.OrganisationName,
      entityName: data.EntityName,
      entityType: data.EntityType,
      abnEffectiveFrom: data.ABNEffectiveFrom,
      gstRegisteredFrom: data.GSTRegisteredFrom,
      abn: data.ABN,
      acn: data.ACN,
    }
  );
}

export function noteLinkToLoanBuilderForUI(data) {
  return (
    data && {
      notesLoanLinkId: data.NotesLoanLinkId || 0,
      loanId: data.LoanId,
      communicationId: data.CommunicationId || 0,
      notesTitle: data.NotesTitle || '',
      notesDetail: data.NotesDetail || '',
      noteCreatedByUserName: data.NoteCreatedByUserName || '',
      notesWrittenBy: data.NotesWrittenBy || '',
      isActive: data.IsActive || false,
      sharedToContactFamilyId: data.SharedToContactFamilyId || '',
      sharedToContactName: data.SharedToContactName || '',
      sharedToContactFamilyIdCsv: data.SharedToContactFamilyIdCsv || '',
    }
  );
}

export function contactToLoanBuilderForMyCRM(data) {
  return (
    data && {
      Title: data.Title || '',
      Tags: data.Tags || '',
      Description: data.Description || '',
      DocumentId: data.DoucmentID || 0,
      FamilyId: data.FamilyID || 0,
    }
  );
}
