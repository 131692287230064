import { getLogger } from '@loanmarket/logger-core';
import { LIST_OF_QUESTIONS } from 'Common/constants/customerObjectives';
import { isCustomerObjectivesPayloadValid } from 'Assets/js/controllers/loanApp/clientAssess/util/customerObjectives';

const CONFLICT_INTEREST_DECLARATION_QUESTIONS = [
  LIST_OF_QUESTIONS.TIERED_SERVICING,
  LIST_OF_QUESTIONS.HIGHER_COMMISSION_PRODUCT,
  LIST_OF_QUESTIONS.THIRD_PARTY_PAYMENT,
  LIST_OF_QUESTIONS.BUSINESS_OWNERSHIP_STRUCTURE,
  LIST_OF_QUESTIONS.CONFLICT_OF_INTEREST,
];

export default class ConflictInterestDeclarationsCtrl {
  constructor(optionsService, generalService, loanApplicationServices) {
    'ngInject';

    this.optionsService = optionsService;
    this.generalService = generalService;
    this.loanApplicationServices = loanApplicationServices;
  }

  $onInit() {
    this.logger = getLogger('ConflictInterestDeclarations');
    this.LIST_OF_QUESTIONS = LIST_OF_QUESTIONS;
    this.dynamicConflictUrl = '#';

    this.hideSubQuestionBackground = [
      LIST_OF_QUESTIONS.BUSINESS_OWNERSHIP_STRUCTURE,
      LIST_OF_QUESTIONS.CONFLICT_OF_INTEREST,
    ];
    this.getDynamicConflictOfInterestUrl();
    this.filterConflictInterestQuestions();
  }

  getDynamicConflictOfInterestUrl() {
    this.generalService
      .getDynamicUrl('CONFLICT_OF_INTEREST')
      .then(({ data }) => {
        this.dynamicConflictUrl = data || '#';
      });
  }

  filterConflictInterestQuestions() {
    this.sectionQuestionList = CONFLICT_INTEREST_DECLARATION_QUESTIONS.reduce(
      (accum, questionId) => {
        const foundItem = this.questionList.find(
          (question) => question.QuestionId === questionId,
        );
        if (foundItem) {
          if (foundItem.QuestionId === LIST_OF_QUESTIONS.CONFLICT_OF_INTEREST) {
            foundItem.QuestionContent = 'Any other conflicts?';
            foundItem.SubQuestion &&
              foundItem.SubQuestion.length &&
              (foundItem.SubQuestion[0].QuestionContent = '');
          }

          if (
            foundItem.QuestionId ===
            LIST_OF_QUESTIONS.BUSINESS_OWNERSHIP_STRUCTURE
          ) {
            foundItem.SubQuestion[0].QuestionContent = '';
          }
          accum.push(foundItem);
        }
        return accum;
      },
      [],
    );

    this.conflictInterest.isQuestionExist = !!this.sectionQuestionList.length;
  }

  saveLoanObjectivesAnswers(question) {
    const isValid = isCustomerObjectivesPayloadValid(question);
    if (!isValid) {
      return;
    }

    return this.loanApplicationServices
      .postLoanObjectives(question)
      .then((response) => {
        if (!response || !response.data) {
          this.logger.error(
            `saveLoanObjectivesAnswers: response empty ${JSON.stringify(
              question,
            )}`,
          );
        }
      });
  }
}
